import React, { useEffect, useState } from 'react';
import { SensorActionPopupProps } from './SensorManagementPopup.type';
import useModal from 'components/CustomHooks/ModalHook';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import UiButton from 'components/Ui/Components/UiButton';
import { SensorStateAtom } from '../SensorManagementRow/SensorManagementRow.atom';
import SensorsAPI from 'api/Sensors';
import { useMutation } from '@tanstack/react-query';
import { UnmountReason } from 'variables';
import { Alert } from '@mui/material';
import RemoveSensorView from './RemoveSensor.view';
import { FetchVehicleDetailsData } from 'components/Vehicle/VehicleDetailsWidget/VehicleDetailsWidget.atom';
import { VARIANT_BUTTON } from 'components/Ui/variables';

const sensorsAPI = new SensorsAPI();

const ReplaceSensor = ({ position }: SensorActionPopupProps): JSX.Element => {
    const { t: translate } = useTranslation();
    const { updateModalState } = useModal(1);
    const sensorState = useRecoilValue(SensorStateAtom(position));
    const [value, setValue] = useState<UnmountReason>(UnmountReason.REMOVED_TO_STOCK);
    const setFetchVehicleDetailsData = useSetRecoilState(FetchVehicleDetailsData);
    const [isSaving, setIsSaving] = useState<boolean>(false);

    const { mutate } = useMutation(
        ['PatchReplaceSensor'],
        () =>
            sensorsAPI.replaceSensor({
                newSensorId: sensorState.newSensor?.id || 0,
                newSensorTypeId: sensorState.typeId,
                oldSensorId: sensorState.originalSensor?.id || 0,
                unmountReason: value
            }),
        {
            onSettled: () => {
                setFetchVehicleDetailsData((prevState) => !prevState);
            }
        }
    );

    useEffect(() => {
        updateModalState({
            leftTitle: translate('t.sensor_replacement'),
            rightTitle: `${translate('t.sensor')}: ${sensorState.originalSensor?.serialNumberHex} > ${
                sensorState.newSensor?.serialNumberHex
            }`,
            buttons: (
                <UiButton
                    id='replace-sensor'
                    skin='success'
                    color='primary'
                    variant={VARIANT_BUTTON}
                    disabled={value === UnmountReason.REMOVING_FAULTY}
                    onClick={() => {
                        mutate();
                        setIsSaving(true);
                    }}
                    testid='VehicleStatus-ReplaceSensor-Button'
                    loading={isSaving}
                >
                    {translate('t.replace')}
                </UiButton>
            )
        });
    }, []);

    return (
        <div>
            <Alert severity='warning'>{translate('p.warning_already_sensor_reason_replacement_removal')}</Alert>
            <RemoveSensorView value={value} setValue={setValue} position={position} />
        </div>
    );
};

export default ReplaceSensor;

import React, { useEffect, useMemo } from 'react';
import { VehicleStatsPeriodContainer } from './VehicleStatsPeriod.style';
import { VehicleStatsPeriodProps } from './VehicleStatsPeriod.type';
import { Item } from 'components/Ui/Components/UiMenu/UiMenu.type';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { getFromLocalStorage, setToLocalStorage } from 'helpers';
import Menu from '../../Ui/Components/UiMenu';
import { ExpandMore } from '@mui/icons-material';
import { CustomButton } from '../VehicleStats/VehicleStats.style';
import { FilterPeriod } from '../VehicleStats/VehicleStats.atom';

const periodListHours = [24, 48, 168, 336, 720];

const getPeriod = (period: number, translate: any): string => {
    return period <= 48 ? `${period} ${translate('t.hrs')}` : `${period / 24} ${translate('t.days')}`;
};

const VehicleStatsPeriodContent: React.FC<VehicleStatsPeriodProps> = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const [period, setPeriod] = useRecoilState(FilterPeriod);

    const updatePeriod = (newPeriod) => {
        setPeriod(newPeriod);
        setToLocalStorage('VehicleStatsPeriod', newPeriod ?? 24);
    };

    const periodList: Item[] = useMemo(() => {
        return periodListHours.map((hours) => {
            const isDay = hours > 100;
            const number = isDay ? hours / 24 : hours;
            const title = isDay ? translate('t.days') : translate('t.hrs');
            return {
                title: `${number} ${title}`,
                value: hours,
                action: updatePeriod
            };
        });
    }, [periodListHours]);

    useEffect(() => {
        const period: number | null = getFromLocalStorage('VehicleStatsPeriod');
        setPeriod(period ?? 24);
    }, []);

    return (
        <VehicleStatsPeriodContainer data-testid='VehicleStatsPeriodContent'>
            <Menu
                rootEl={
                    <CustomButton color='secondary' testid={'VehicleStats-hours-button'} variant='text'>
                        {period && getPeriod(period, translate)} <ExpandMore />
                    </CustomButton>
                }
                items={periodList}
            />
        </VehicleStatsPeriodContainer>
    );
};

export default VehicleStatsPeriodContent;

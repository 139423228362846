export type RimSizeModel = {
    id: number;
    diameter: string;
};

export enum RimSizeQueryKeys {
    get = 'RimSize-get',
    getCodebook = 'RimSize-getCodebook',
    getFile = 'RimSize-getFile',
    getTable = 'RimSize-getTable',
    getById = 'RimSize-getById',
    getByCriteria = 'RimSize-getByCriteria',
    patchById = 'RimSize-patchById',
    putById = 'RimSize-putById'
}

import { useTheme } from '@mui/material';
import styled from 'styled-components';
import { ThemeMode } from '../../../../states/global/Theme';
import { DARK_STEEL_GREY, BLUE_BRIDGESTONE, LINKS_BLUE } from '../../colors';
import { CommonTypeTheme } from 'components/Ui/Theme';

export const UiAViewContent = styled.a<{ $color?: string; $fontWeight?: string; $padding?: string }>`
    text-decoration: none;
    padding: ${({ $padding }) => $padding || '0px 5px'};
    font-weight: ${({ $fontWeight }) => $fontWeight || 'bold'};
    display: inline-block;
    ${({ $color }) => {
        const common = useTheme().palette.common as CommonTypeTheme;
        const $colorTheme: string = useTheme().palette.mode === ThemeMode.dark ? BLUE_BRIDGESTONE : LINKS_BLUE;
        return `color: ${$color || $colorTheme}; font-size: ${common.textSize};`;
    }}

    &:hover {
        text-decoration: revert;
    }
`;

export const ContainerLink = styled.span<{ $color?: string; $fontWeight?: string; $padding?: string }>`
    & a {
        text-decoration: none;
        ${() => {
            const common = useTheme().palette.common as CommonTypeTheme;
            return `
                font-size: ${common.textSize};
            `;
        }}
        padding: ${({ $padding }) => $padding || '0px 5px'};
        font-weight: ${({ $fontWeight }) => $fontWeight || 'unset'};
        display: inline-block;
        ${({ $color }) => {
            const $colorTheme: string = useTheme().palette.mode === ThemeMode.dark ? BLUE_BRIDGESTONE : LINKS_BLUE;
            return `color: ${$color || $colorTheme};`;
        }}

        &:hover {
            text-decoration: revert;
        }

        & svg {
            color: ${({ $color }) => $color || DARK_STEEL_GREY};
        }
    }
`;

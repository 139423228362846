import React from 'react';
import { useRecoilState } from 'recoil';
import { TopBarAtom } from './TopBar/TopBar.atom';
import { useTranslation } from 'react-i18next';
import AreaAPI from 'api/Area';
import LazyloadingAutocomplete from '../LazyloadingAutocomplete/LazyloadingAutocomplete';
import { AreaQueryKeys } from 'models/Area.type';

const areaAPI = new AreaAPI();

export const AreaSelect: React.FC = (): JSX.Element => {
    const [topBarAtom, setTopBarAtom] = useRecoilState(TopBarAtom);
    const { t: translate } = useTranslation();

    /*const { data } = useQuery('GetAreaForSelect', () => areaAPI.getAreasForSelect(), {
        enabled: topBarAtom.vehicle.id > -1,
        retry: false
    });

    const createMenuOptions = (): JSX.Element[] => {
        let options: JSX.Element[] = [
            <MenuItem value={0} key={0}>
                {translate('t.all')}
            </MenuItem>
        ];

        if (data?.items && topBarAtom.vehicle.id > -1) {
            for (let i = 0, length = data.items.length; i < length; i++) {
                const area = data.items[i];
                options.push(
                    <MenuItem value={area.id} key={i + 1}>
                        {area.name}
                    </MenuItem>
                );
            }
        }

        return options;
    };*/

    return (
        <>
            <LazyloadingAutocomplete
                disabled={topBarAtom.vehicle.id <= -1}
                $testid='Area-List-Dropdown-Select'
                id={'area_List_Dropdown-top-bar'}
                size='small'
                label={translate('t.area')}
                extendUrl='list'
                query={areaAPI.getTable}
                getOptionLabel={(option) => ({
                    text: option.name,
                    html: undefined
                })}
                queryId={AreaQueryKeys.getLazyDropdown}
                querySortBy={[{ id: 'name', desc: false }]}
                optionKey='name'
                optionValue='id'
                onValueChange={(value) => {
                    setTopBarAtom({ ...topBarAtom, areaEntries: value });
                }}
                defaultOption={{
                    id: 0,
                    value: 0,
                    name: translate('t.all'),
                    icon: '',
                    active: false
                }}
            />
        </>
    );
};

export default AreaSelect;

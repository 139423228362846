import React from 'react';
import * as style from './TPMSAlertsListTabManager.style';
import { PopUpalert, typeFilter } from './TPMSAlertsListTabManager.type';
import * as styleAlertCustom from '../AlertCustom/AlertCustom.style';
import * as styleAlertList from '../AlertsList/AlertsList.style';
import { useTranslation } from 'react-i18next';
import { MoreVert } from '@mui/icons-material';
import UiLegend from '../../Ui/Components/UiLegend/UiLegend';
import { applyStyleByMode } from 'helpers';

const AlertsPopUpStateView: React.FC<PopUpalert> = (props): JSX.Element => {
    const { t: translate } = useTranslation();

    return (
        <div>
            <UiLegend
                minWidth={265}
                width={300}
                title={applyStyleByMode({ styleJade: translate('t.filters') })}
                content={
                    <style.PopUpFilterContainer>
                        <style.GroupOption>
                            <style.FilterCover>
                                <style.FilterLabel>
                                    {translate('t.level')} {translate('t.filters')}
                                </style.FilterLabel>
                                <style.FilterContent>
                                    <styleAlertCustom.ChipLevel
                                        size='small'
                                        data-testid='Alerts-filter-level1-button'
                                        level={1}
                                        label={`${translate('t.level')} 1`}
                                        clickable
                                        onClick={() => props.AddFilterAlertByLevel(1)}
                                    />

                                    <styleAlertCustom.ChipLevel
                                        size='small'
                                        data-testid='Alerts-filter-level2-button'
                                        level={2}
                                        label={`${translate('t.level')} 2`}
                                        clickable
                                        onClick={() => props.AddFilterAlertByLevel(2)}
                                    />

                                    <styleAlertCustom.ChipLevel
                                        size='small'
                                        data-testid='Alerts-filter-level3-button'
                                        level={3}
                                        label={`${translate('t.level')} 3`}
                                        clickable
                                        onClick={() => props.AddFilterAlertByLevel(3)}
                                    />
                                </style.FilterContent>
                            </style.FilterCover>
                        </style.GroupOption>

                        <style.GroupOption>
                            <style.FilterCover>
                                <style.FilterLabel>
                                    {translate('t.type')} {translate('t.filters')}
                                </style.FilterLabel>
                                <style.FilterContent>
                                    <styleAlertList.ChipActionsFilter
                                        size='small'
                                        data-testid='Alerts-filter-pressure-button'
                                        label={`${translate('t.pressure')}`}
                                        clickable
                                        onClick={() => props.setFilterByType(typeFilter.PRESSURE)}
                                    />

                                    <styleAlertList.ChipActionsFilter
                                        size='small'
                                        data-testid='Alerts-filter-hot-pressure-button'
                                        label={`${translate('t.hot_pressure')}`}
                                        clickable
                                        onClick={() => props.setFilterByType(typeFilter.PRESSURE_HOT)}
                                    />
                                </style.FilterContent>
                                <style.FilterContent>
                                    <styleAlertList.ChipActionsFilter
                                        size='small'
                                        data-testid='Alerts-filter-temperature-button'
                                        label={`${translate('t.temperature')}`}
                                        clickable
                                        onClick={() => props.setFilterByType(typeFilter.TEMPERATURE)}
                                    />
                                </style.FilterContent>
                            </style.FilterCover>
                        </style.GroupOption>
                    </style.PopUpFilterContainer>
                }
                root={
                    <style.CustomIconButton testid='Widget-TPMS-Alerts-filter-button' size='small'>
                        <MoreVert />
                    </style.CustomIconButton>
                }
            />
        </div>
    );
};

export default AlertsPopUpStateView;

import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import {
    AddGlobalAdminContainer,
    ContainerBtnLogo,
    ContentImge,
    CustomCheckBox,
    GridContainer,
    GridInput,
    ImageLogo,
    RefButton,
    UploadLogo
} from './AddGlobalAdmin.style';
import { AddGlobalAdminViewProps } from './AddGlobalAdmin.type';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Form, Formik, FormikProps } from 'formik';
import { CustomerForm } from 'models/Customer.type';
import { Button, FormControlLabel, FormLabel, Grid, TextField, Tooltip, Typography } from '@mui/material';
import { faPencil, faXmark } from '@fortawesome/pro-solid-svg-icons';
import Autocomplete from '@mui/material/Autocomplete';
import UiButton from 'components/Ui/Components/UiButton/UiButton';
import { SUCCESS } from 'components/Ui/colors';
import { UserInfo } from 'states/global/User';
import { checkRole } from 'helpers/authentication';
import { ROLES } from 'variables';
import SetupMapArea from 'components/SetupMapArea/SetupMapArea';
import ExternalSystemAPI from 'api/ExternalSystem';
import GeneralGetQuery from 'components/Mixs/GeneralGetQuery';
import UiLoadingPage from 'components/Ui/Components/UiLoadingPage/UiLoadingPage';
import LazyloadingAutocomplete from 'components/Mixs/LazyloadingAutocomplete';
import LocationApi from 'api/Location';
import { cacheTimeToMilliseconds } from 'helpers/cache';
import { ExternalSystemModel, ExternalSystemQueryKeys } from 'models/ExternalSystem.type';
import { LocationQueryKeys } from 'models/Location.type';
import UiDatePicker from 'components/Ui/Components/UiDatePicker/UiDatePicker';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { DateTime } from 'luxon';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';

const externalSystemAPI = new ExternalSystemAPI();
const Location = new LocationApi();

export const AddGlobalAdminContent: React.FC<AddGlobalAdminViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const userInfo = useRecoilValue(UserInfo);
    const isSuperAdmin = useMemo(() => checkRole(userInfo.user, [ROLES.ROLE_SUPER_ADMIN]), [userInfo.user]);
    const { fromTimezoneToUTC } = useConverter();

    const formSchema = Yup.object().shape({
        name: Yup.string().required(translate('p.this_field_is_required'))
    });

    return (
        <AddGlobalAdminContainer data-testid='AddGlobalAdminContent'>
            <Formik
                data-testid='AddGlobalAdminContent-Formik'
                initialValues={props.initValues}
                validationSchema={formSchema}
                enableReinitialize={true}
                onSubmit={(values: CustomerForm) => {
                    props.handleSubmit(values);
                }}
            >
                {(propsFormik: FormikProps<CustomerForm>) => {
                    const { errors, setFieldValue, handleChange, values, dirty, isValid, isSubmitting } = propsFormik;

                    props.selectedFile ? props.setPhoto(props.selectedFile as string) : '';

                    values.externalTemperatureEnabled
                        ? ((values.externalTemperatureEnabled as unknown) = true)
                        : ((values.externalTemperatureEnabled as unknown) = false);

                    values.hubsMaintenanceConnection == '0'
                        ? ((values.hubsMaintenanceConnection as unknown) = false)
                        : ((values.hubsMaintenanceConnection as unknown) = true);

                    return (
                        <Form id='administration-hub-form' data-testid='AddGlobalAdminContent-Form'>
                            <GridContainer
                                container
                                xs={12}
                                sm={12}
                                justifyContent='center'
                                alignItems='center'
                                data-testid='AddGlobalAdminContent-Grid-Container'
                            >
                                <Grid container xs={12} sm={12} spacing={2} data-testid='AddGlobalAdminContent-Grid-1'>
                                    <GridInput item xs={12} sm={12} data-testid='AddGlobalAdminContent-GridInput-1'>
                                        {props.fullEdit ? (
                                            <>
                                                <TextField
                                                    data-testid='AddGlobalAdminContent-Name-Texfield'
                                                    label={translate('t.name')}
                                                    variant='outlined'
                                                    name='name'
                                                    size='small'
                                                    value={values.name}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    helperText={errors.name && translate('p.this_field_is_required')}
                                                    onChange={handleChange}
                                                    error={!!errors.name}
                                                    fullWidth
                                                />
                                            </>
                                        ) : (
                                            <>
                                                <FormLabel data-testid='AddGlobalAdminContent-FormLabel-Name'>
                                                    {translate('t.name')}
                                                </FormLabel>
                                                <Typography
                                                    variant='h6'
                                                    data-testid='AddGlobalAdminContent-Typography-Name'
                                                >
                                                    {values.name ?? '-'}
                                                </Typography>
                                            </>
                                        )}
                                    </GridInput>
                                </Grid>
                                {isSuperAdmin && (
                                    <>
                                        <Grid
                                            container
                                            xs={12}
                                            sm={12}
                                            spacing={2}
                                            data-testid='AddGlobalAdminContent-Grid-Container-2'
                                        >
                                            <GridInput
                                                item
                                                xs={12}
                                                sm={12}
                                                spacing={2}
                                                data-testid='AddGlobalAdminContent-Grid-Input-2'
                                            >
                                                <TextField
                                                    data-testid='AddGlobalAdminContent-TextField-HostName'
                                                    label={translate('t.host_name')}
                                                    variant='outlined'
                                                    name='hostName'
                                                    size='small'
                                                    value={values.hostname ?? '-'}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    helperText={errors.name && translate('p.this_field_is_required')}
                                                    disabled={true}
                                                    onChange={handleChange}
                                                    error={!!errors.name}
                                                    fullWidth
                                                />
                                            </GridInput>
                                        </Grid>
                                        <Grid
                                            container
                                            xs={12}
                                            sm={12}
                                            spacing={2}
                                            data-testid='AddGlobalAdminContent-Grid-3'
                                        >
                                            <Grid item xs={5} sm={6} data-testid='AddGlobalAdminContent-GridInput-3'>
                                                <UiDatePicker
                                                    testId={`AddGlobalAdminContent-TextField-RegisteredAt`}
                                                    value={values.registeredAt}
                                                    label={translate('t.registered_at')}
                                                    disabled
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={5} sm={6} data-testid='AddGlobalAdminContent-GridInput-4'>
                                                <UiDatePicker
                                                    testId={`ActiveTerminatedAtDatepicker`}
                                                    value={
                                                        props.initValues.activeTerminatedAt
                                                            ? values.activeTerminatedAt
                                                            : null
                                                    }
                                                    label={translate('t.active_terminated_at')}
                                                    onDateChange={(newDate) => {
                                                        if (!DateTime.fromISO(newDate).invalid || newDate === null) {
                                                            setFieldValue(
                                                                'activeTerminatedAt',
                                                                !newDate ? null : fromTimezoneToUTC(newDate)
                                                            );
                                                        }
                                                    }}
                                                    fullWidth
                                                    allowEmpty
                                                    error={!!errors.activeTerminatedAt}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            container
                                            xs={12}
                                            sm={12}
                                            spacing={2}
                                            data-testid='AddGlobalAdminContent-Grid-4'
                                        >
                                            <GridInput
                                                item
                                                xs={5}
                                                sm={6}
                                                data-testid='AddGlobalAdminContent-GridInput-5'
                                            >
                                                <TextField
                                                    data-testid='AddGlobalAdminContent-TextField-DBAppRegion'
                                                    label={translate('t.db_app_region')}
                                                    variant='outlined'
                                                    name='dbAppRegion'
                                                    size='small'
                                                    value={values.dbAppRegion ?? '-'}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    helperText={
                                                        errors.dbAppRegion && translate('p.this_field_is_required')
                                                    }
                                                    disabled={true}
                                                    onChange={handleChange}
                                                    error={!!errors.dbAppRegion}
                                                    fullWidth
                                                />
                                            </GridInput>

                                            <GridInput
                                                item
                                                xs={5}
                                                sm={6}
                                                data-testid='AddGlobalAdminContent-GridInput-6'
                                            >
                                                <TextField
                                                    data-testid='AddGlobalAdminContent-TextField-RepAppRegion'
                                                    label={translate('t.rep_app_region')}
                                                    variant='outlined'
                                                    name='repAppRegion'
                                                    size='small'
                                                    value={values.repAppRegion ?? '-'}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    helperText={
                                                        errors.repAppRegion && translate('p.this_field_is_required')
                                                    }
                                                    disabled={true}
                                                    onChange={handleChange}
                                                    error={!!errors.repAppRegion}
                                                    fullWidth
                                                />
                                            </GridInput>
                                        </Grid>
                                        <Grid
                                            container
                                            xs={12}
                                            sm={12}
                                            spacing={2}
                                            data-testid='AddGlobalAdminContent-Grid-5'
                                        >
                                            <Grid item xs={12} sm={6}>
                                                {values.timezoneId && (
                                                    <>
                                                        {props.timeZoneLoading ? (
                                                            <UiLoadingPage
                                                                size='30px'
                                                                data-testid='AddGlobalAdminContent-CircularProgress-Timezone'
                                                            />
                                                        ) : (
                                                            <Autocomplete
                                                                data-testid='AddGlobalAdminContent-AutoComplete-TimeZoneID'
                                                                getOptionLabel={(timeZone) => `${timeZone.name}`}
                                                                options={props.timeZoneList}
                                                                onChange={(e, timeFormat) => {
                                                                    setFieldValue(
                                                                        'timezoneId',
                                                                        timeFormat?.id ?? values.timezoneId
                                                                    );
                                                                }}
                                                                defaultValue={props.timeZoneList.find(
                                                                    (value) => value.id === values.timezoneId || ''
                                                                )}
                                                                size='small'
                                                                disableClearable={true}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        data-testid='AddGlobalAdminContent-TextField-Timezone'
                                                                        label={translate('t.timezone')}
                                                                        variant='outlined'
                                                                        name='timezoneId'
                                                                        value={values.timezoneId}
                                                                        InputLabelProps={{
                                                                            shrink: true
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        )}
                                                    </>
                                                )}
                                            </Grid>

                                            <GridInput
                                                item
                                                xs={12}
                                                sm={6}
                                                data-testid='AddGlobalAdminContent-GridInput-7'
                                            >
                                                <>
                                                    {props.deviceSerialConnectionTypeLoading ? (
                                                        <UiLoadingPage size='25px' />
                                                    ) : (
                                                        <Autocomplete
                                                            data-testid='AddGlobalAdminContent-Autocomplete-DeviceSerialConnectionTypeId'
                                                            getOptionLabel={(deviceSerial) => `${deviceSerial.name}`}
                                                            options={props.deviceSerialList}
                                                            onChange={(e, deviceValue) => {
                                                                setFieldValue(
                                                                    'deviceSerialConnectionTypeId',
                                                                    deviceValue !== null ? deviceValue.id : deviceValue
                                                                );
                                                            }}
                                                            defaultValue={props.deviceSerialList.find(
                                                                (value) =>
                                                                    value.id === values.deviceSerialConnectionTypeId ||
                                                                    ''
                                                            )}
                                                            size='small'
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    data-testid='AddGlobalAdminContent-TextField-DeviceSerialConnectionTypeId'
                                                                    label={translate('t.hub_serial_connection_type')}
                                                                    variant='outlined'
                                                                    name='deviceSerialConnectionType'
                                                                    value={values.deviceSerialConnectionTypeId}
                                                                    InputLabelProps={{
                                                                        shrink: true
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    )}
                                                </>
                                            </GridInput>
                                        </Grid>
                                        <Grid container sm={12} spacing={2} data-testid='AddGlobalAdminContent-Grid-6'>
                                            <GridInput
                                                item
                                                sm={6}
                                                data-testid='AddGlobalAdminContent-Grid-jira-organisation'
                                            >
                                                <TextField
                                                    data-testid='AddGlobalAdminContent-TextField-Jira-Organisation'
                                                    label={translate('t.jira_organisation_id')}
                                                    variant='outlined'
                                                    name='jiraOrganisationId'
                                                    size='small'
                                                    value={values.jiraOrganisationId}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    onChange={handleChange}
                                                    fullWidth
                                                    type='number'
                                                    inputProps={{
                                                        form: {
                                                            autocomplete: 'off'
                                                        },
                                                        step: 1,
                                                        min: 0
                                                    }}
                                                />
                                            </GridInput>
                                            <GridInput
                                                item
                                                sm={6}
                                                data-testidRefButton='AddGlobalAdminContent-location'
                                            >
                                                {props.isLocationLoading ? (
                                                    <UiLoadingPage size='30px' />
                                                ) : (
                                                    <LazyloadingAutocomplete
                                                        id='delivery-location'
                                                        label={translate('t.primary_delivery_location')}
                                                        queryId={LocationQueryKeys.getLazyDropdown}
                                                        query={Location.getTable}
                                                        apiProject='stock'
                                                        disabled={!isSuperAdmin}
                                                        optionKey='name'
                                                        optionValue='id'
                                                        fullWidth
                                                        size='small'
                                                        style={{ margin: '8px 0 0 0', width: '100%' }}
                                                        onValueChange={(locationId) => {
                                                            setFieldValue(
                                                                'itrackStockPrimaryDeliveryLocationId',
                                                                locationId || null
                                                            );
                                                        }}
                                                        preselectedValue={{
                                                            value: props?.location?.id || 0,
                                                            label: props?.location?.name || '',
                                                            origItem: props?.location || {}
                                                        }}
                                                    />
                                                )}
                                            </GridInput>
                                        </Grid>
                                        <Grid
                                            container
                                            justifyContent='center'
                                            alignItems='center'
                                            alignSelf='center'
                                            spacing={2}
                                            data-testid='AddGlobalAdminContent-Grid-7'
                                        >
                                            <GridInput item xs={12} data-testid='AddGlobalAdminContent-GridInput-8'>
                                                <GeneralGetQuery
                                                    data-testid='AddGlobalAdminContent-GeneralGetQuery-GetAllExternalSystems'
                                                    query={externalSystemAPI.getAllExternalSystems}
                                                    queryName={ExternalSystemQueryKeys.getAllExternalSystems}
                                                    queryOptions={{
                                                        staleTime: cacheTimeToMilliseconds(2, 'hours'),
                                                        cacheTime: cacheTimeToMilliseconds(2, 'hours')
                                                    }}
                                                >
                                                    {({ data }) => (
                                                        <Autocomplete
                                                            data-testid='AddGlobalAdminContent-Autocomplete-ExternalSensors'
                                                            id='hasExternalSystem'
                                                            style={{ padding: '0 8px' }}
                                                            multiple
                                                            options={
                                                                data?.items.filter((d) => {
                                                                    return !values.hasExternalSystem?.find(
                                                                        (exSystem) => d.id === exSystem.id
                                                                    );
                                                                }) || []
                                                            }
                                                            value={
                                                                values.hasExternalSystem?.map((externalSystem) => {
                                                                    return externalSystem;
                                                                }) as ExternalSystemModel[]
                                                            }
                                                            getOptionLabel={(option) => {
                                                                return option?.name;
                                                            }}
                                                            onChange={(_event, value) => {
                                                                setFieldValue('hasExternalSystem', value);
                                                            }}
                                                            limitTags={4}
                                                            size='small'
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    data-testid='AddGlobalAdminContent-TexField-ExternalSensors'
                                                                    {...params}
                                                                    label={translate('t.external_system')}
                                                                    size='small'
                                                                    name='hasExternalSystem'
                                                                    fullWidth
                                                                />
                                                            )}
                                                        />
                                                    )}
                                                </GeneralGetQuery>
                                            </GridInput>
                                        </Grid>
                                        <Grid
                                            container
                                            xs={12}
                                            sm={12}
                                            spacing={2}
                                            data-testid='AddGlobalAdminContent-Grid-8'
                                        >
                                            <Grid
                                                item
                                                xs={12}
                                                sm={8}
                                                lg={6}
                                                data-testid='AddGlobalAdminContent-GridItem-1'
                                            >
                                                <FormControlLabel
                                                    data-testid='AddGlobalAdminContent-FormControlLabel-Enabled'
                                                    control={
                                                        <CustomCheckBox
                                                            data-testid='AddGlobalAdminContent-CustomCheckBox-Enabled'
                                                            id='enabled'
                                                            checked={values.enabled}
                                                            onChange={handleChange}
                                                        />
                                                    }
                                                    label={
                                                        <FormLabel data-testid='AddGlobalAdminContent-FormLabel-Enabled'>
                                                            {translate('t.enabled')}
                                                        </FormLabel>
                                                    }
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={8}
                                                lg={6}
                                                data-testid='AddGlobalAdminContent-GridItem-2'
                                            >
                                                <FormControlLabel
                                                    data-testid='AddGlobalAdminContent-FormControlLabel-ShowExternalSensorTemperature'
                                                    control={
                                                        <CustomCheckBox
                                                            data-testid='AddGlobalAdminContent-CustomCheckBox-ShowExternalSensorTemperature'
                                                            id='externalTemperatureEnabled'
                                                            checked={values.externalTemperatureEnabled}
                                                            onChange={handleChange}
                                                        />
                                                    }
                                                    label={
                                                        <FormLabel data-testid='AddGlobalAdminContent-FormLabel-ShowExternalSensorTemperature'>
                                                            {translate('t.show_external_sensor_temperature')}
                                                        </FormLabel>
                                                    }
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={8}
                                                lg={6}
                                                data-testid='AddGlobalAdminContent-Grid-Item-4'
                                            >
                                                <FormControlLabel
                                                    data-testid='AddGlobalAdminContent-FormControlLabel-InternalTestSite'
                                                    control={
                                                        <CustomCheckBox
                                                            id='internalTestSite'
                                                            data-testid='AddGlobalAdminContent-CustomCheckBox-InternalTestSite'
                                                            checked={values.internalTestSite}
                                                            onChange={handleChange}
                                                        />
                                                    }
                                                    label={
                                                        <FormLabel data-testid='AddGlobalAdminContent-FormLabel-InternalTestSite'>
                                                            {translate('t.internal_test_site')}
                                                        </FormLabel>
                                                    }
                                                />
                                            </Grid>
                                            <GridInput
                                                item
                                                xs={12}
                                                sm={8}
                                                lg={6}
                                                data-testid='AddGlobalAdminContent-Grid-Input-9'
                                            >
                                                <FormControlLabel
                                                    data-testid='AddGlobalAdminContent-FormControlLabel-HubsMaintenanceConnection'
                                                    control={
                                                        <CustomCheckBox
                                                            id='hubsMaintenanceConnection'
                                                            data-testid='AddGlobalAdminContent-CustomCheckBox-HubsMaintenanceConnection'
                                                            checked={!!values.hubsMaintenanceConnection}
                                                            onChange={handleChange}
                                                        />
                                                    }
                                                    label={
                                                        <FormLabel data-testid='AddGlobalAdminContent-FormLabel-HubsMaintenanceConnection'>
                                                            {translate('t.hubs_maintenance_connection')}
                                                        </FormLabel>
                                                    }
                                                />
                                            </GridInput>
                                            <GridInput
                                                item
                                                xs={12}
                                                sm={8}
                                                lg={6}
                                                data-testid='AddGlobalAdminContent-Grid-Input-10'
                                            >
                                                <FormControlLabel
                                                    data-testid='AddGlobalAdminContent-FormControlLabel-autoGenerateCmd7'
                                                    control={
                                                        <CustomCheckBox
                                                            id='autoGenerateCmd7'
                                                            data-testid='AddGlobalAdminContent-CustomCheckBox-autoGenerateCmd7'
                                                            checked={!!values.autoGenerateCmd7}
                                                            onChange={handleChange}
                                                        />
                                                    }
                                                    label={
                                                        <FormLabel data-testid='AddGlobalAdminContent-FormLabel-autoGenerateCmd7'>
                                                            {translate('t.autogenerate_ip_details')}
                                                        </FormLabel>
                                                    }
                                                />
                                            </GridInput>
                                            <GridInput
                                                item
                                                xs={12}
                                                sm={8}
                                                lg={6}
                                                data-testid='AddGlobalAdminContent-Grid-Input-12'
                                            >
                                                <FormControlLabel
                                                    data-testid='AddGlobalAdminContent-FormControlLabel-autoGenerateApn'
                                                    control={
                                                        <CustomCheckBox
                                                            id='autoGenerateApn'
                                                            data-testid='AddGlobalAdminContent-CustomCheckBox-autoGenerateApn'
                                                            checked={!!values.autoGenerateApn}
                                                            onChange={handleChange}
                                                        />
                                                    }
                                                    label={
                                                        <FormLabel data-testid='AddGlobalAdminContent-FormLabel-autoGenerateApn'>
                                                            {translate('t.auto_generate_apn')}
                                                        </FormLabel>
                                                    }
                                                />
                                            </GridInput>
                                        </Grid>
                                    </>
                                )}
                                <Grid container xs={12} sm={12} spacing={2} data-testid='AddGlobalAdminContent-Grid-9'>
                                    <GridInput item xs={12} sm={12} data-testid='AddGlobalAdminContent-Grid-Input-12'>
                                        <UploadLogo>
                                            <FormLabel data-testid='AddGlobalAdminContent-FormLabel-Logo'>
                                                {translate('t.logo')}:{' '}
                                            </FormLabel>
                                            <ContainerBtnLogo
                                                previewImg={props.previewImg}
                                                data-testid='AddGlobalAdminContent-ContainerBtnLogo'
                                            >
                                                {props.previewImg ? (
                                                    <Tooltip
                                                        title={translate('t.remove_image')}
                                                        data-testid='AddGlobalAdminContent-ToolTip-RemoveImage'
                                                    >
                                                        <Button
                                                            data-testid='AddGlobalAdminContent-Button-Remove'
                                                            color='error'
                                                            size='small'
                                                            variant='contained'
                                                            component='label'
                                                            onClick={() => {
                                                                props.setPreviewImg('');
                                                            }}
                                                            startIcon={
                                                                <UiIcon
                                                                    icon={faXmark}
                                                                    size='sm'
                                                                    data-testid='AddGlobalAdminContent-FontAwesome-IconfaXmark'
                                                                />
                                                            }
                                                        >
                                                            {translate('t.remove')}
                                                        </Button>
                                                    </Tooltip>
                                                ) : (
                                                    <Tooltip
                                                        title={translate('t.select_image')}
                                                        data-testid='AddGlobalAdminContent-ToolTip-SelectImage'
                                                    >
                                                        <Button
                                                            size='small'
                                                            variant='contained'
                                                            component='label'
                                                            data-testid='GlobalAdminEditLogoButton'
                                                            startIcon={
                                                                <UiIcon
                                                                    icon={faPencil}
                                                                    size='sm'
                                                                    data-testid='AddGlobalAdminContent-FontAwesome-IconfaPencil'
                                                                />
                                                            }
                                                        >
                                                            {translate('t.edit')}
                                                            <input
                                                                onChange={props.handleCapture}
                                                                hidden
                                                                accept='image/*'
                                                                type='file'
                                                            />
                                                        </Button>
                                                    </Tooltip>
                                                )}
                                            </ContainerBtnLogo>
                                        </UploadLogo>
                                        <ContentImge>
                                            {props.photoDataLoading ? (
                                                <UiLoadingPage
                                                    size='30px'
                                                    data-testid='AddGlobalAdminContent-CircularProgress-Logo'
                                                />
                                            ) : (
                                                <ImageLogo
                                                    data-testid='AddGlobalAdminContent-Image-Logo'
                                                    src={props.photoData}
                                                    alt={translate('t.logo')}
                                                />
                                            )}
                                        </ContentImge>
                                    </GridInput>
                                </Grid>
                            </GridContainer>
                            <GridContainer
                                container
                                xs={12}
                                sm={12}
                                marginBottom={1}
                                data-testid='AddGlobalAdminContent-UiButtons-Container'
                            >
                                {props.fullEdit ? (
                                    <>
                                        <UiButton
                                            data-testid='AddGlobalAdminContent-UiButton-Save'
                                            skin={SUCCESS}
                                            type='submit'
                                            color='primary'
                                            variant='contained'
                                            loading={isSubmitting}
                                            disabled={!(dirty && isValid) && !props.previewImg}
                                        >
                                            {translate('t.save')}
                                        </UiButton>
                                        <SetupMapArea
                                            customerId={values.id}
                                            data-testid='AddGlobalAdminContent-SetupMapArea'
                                        />
                                    </>
                                ) : (
                                    <RefButton
                                        type='submit'
                                        ref={props.refEditCustomer}
                                        data-testid='AddGlobalAdminContent-RefButton'
                                    />
                                )}
                            </GridContainer>
                        </Form>
                    );
                }}
            </Formik>
        </AddGlobalAdminContainer>
    );
};

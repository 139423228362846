import React, { useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import Graph from 'components/Graph/Graph';
import { StatisticsSpeedProps } from './StatisticsSpeed.type';
import { useTranslation } from 'react-i18next';
import { SpeedRecord, xDomainConfigType, yDomainConfigType } from '../StatisticsGraphs.type';
import { DEFAULT_SPEED_GRAPH_SCALE, SPEED_STATIONARY_TRESHOLD, deepCopyObj, generateYConfig } from 'helpers';
import TrackLogApi from 'api/TrackLog';
import { DateTime } from 'luxon';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { DateTimePicker, Timeline } from 'states/global/Statistics';
import { MapAction } from 'states/global/Map';
import { MapActionsEnum } from 'components/Map/Map.type';
import { RedirectParams } from 'pages/Redirect/Redirect.atom';

const TrackLog = new TrackLogApi();

const StatisticsSpeed: React.FC<StatisticsSpeedProps> = ({
    lineColor,
    selectedVehicle,
    onHoverGraph,
    dateTimePicker,
    timeline,
    setDateTimePicker,
    zoomInExternal,
    enableFastLeak
}): JSX.Element => {
    const [speedScale, setSpeedScale] = useState<number[]>([]);
    const { t: translate } = useTranslation();
    const globalTimeline = useRecoilValue(Timeline);
    const { fromTimezoneToUTC, fromUTCToTimezone, fromServerToUserUnit, displayUserUnits, convertType } =
        useConverter();
    const [globalDateTimePicker, setGlobalDateTimePicker] = useRecoilState(DateTimePicker);
    const setMapAction = useSetRecoilState(MapAction);
    const timelineUse = timeline || globalTimeline;
    const [fastLeakPreview, setFastLeakPreview] = useState<number>();
    const redirectParams = useRecoilValue(RedirectParams);

    const xDomainConfigSpeed: xDomainConfigType[] = [
        {
            dataKey: 'measuredAt',
            yAxisId: 'hiddenData',
            name: translate('t.measured_at'),
            hide: true,
            showDot: false
        },
        {
            dataKey: 'speedAvg',
            stroke: lineColor,
            yAxisId: '1',
            name: translate('t.average_speed'),
            unit: displayUserUnits.speed,
            showDot: true
        },
        {
            dataKey: 'speedMin',
            yAxisId: 'hiddenData',
            stroke: lineColor,
            name: translate('t.min_speed'),
            unit: displayUserUnits.speed,
            showDot: false,
            hide: true
        },
        {
            dataKey: 'speedMax',
            yAxisId: 'hiddenData',
            stroke: lineColor,
            name: translate('t.max_speed'),
            unit: displayUserUnits.speed,
            showDot: false,
            hide: true
        },
        {
            dataKey: '',
            yAxisId: '2',
            showDot: false,
            hide: true,
            name: ''
        }
    ];

    const yDomainSpeed: yDomainConfigType[] = [
        {
            id: '1',
            name: translate('t.speed_with_unit', { unit: displayUserUnits.speed }),
            isShared: true
        },
        {
            id: '2',
            name: translate('t.speed_with_unit', { unit: displayUserUnits.speed }),
            isShared: true
        }
    ];

    const yDomainConfigSpeed = generateYConfig(yDomainSpeed, DEFAULT_SPEED_GRAPH_SCALE, {
        left: speedScale,
        right: speedScale
    });

    const processSpeed = (
        speedData: SpeedRecord[]
    ): { timeKey: number; speedAvg: number; speedMin: number; speedMax: number }[] => {
        let min = 0;
        let max = 0;

        const copySpeed = deepCopyObj(speedData);
        let copyTimeline = deepCopyObj(timelineUse.timeline);

        const speedDataAmount: number = copySpeed.filter((speed) => speed.speedAvg !== null).length;

        for (let i = 0, length = copySpeed.length; i < length - 1; i++) {
            const currentRecord: SpeedRecord = speedData[i];
            const nextRecord: SpeedRecord = speedData[i + 1];

            const granularityConstant: number = timelineUse.granularity / 60 <= 5 ? 1 : timelineUse.granularity / 60;
            const hasStationaryGap: boolean =
                Math.abs(currentRecord.timeKey - nextRecord.timeKey) >=
                SPEED_STATIONARY_TRESHOLD * 60 * granularityConstant;
            const currentHasBeenStationary: boolean =
                (currentRecord.speedMin ?? 0) <= 0 && (currentRecord.speedMax ?? 0) > 0;

            const timekey = copySpeed[i].timeKey;
            const speedVal = copySpeed[i].speedAvg ?? 0;
            const nextTimekey = copySpeed[i + 1].timeKey;
            const nextSpeedVal = copySpeed[i + 1].speedAvg ?? 0;
            (
                copyTimeline as {
                    timeKey: number;
                    speedAvg: number | string;
                    speedMin: number | string;
                    speedMax: number | string;
                    measuredAt: string;
                }[]
            )[timekey] = {
                speedAvg: fromServerToUserUnit({
                    type: convertType.speed,
                    value: speedVal,
                    displayUnits: false,
                    fixed: 1,
                    displayIfEmpty: '----'
                }),
                speedMin: fromServerToUserUnit({
                    type: convertType.speed,
                    value: copySpeed[i].speedMin ?? 0,
                    displayUnits: false,
                    fixed: 1,
                    displayIfEmpty: '----'
                }),
                speedMax: fromServerToUserUnit({
                    type: convertType.speed,
                    value: copySpeed[i].speedMax ?? 0,
                    displayUnits: false,
                    fixed: 1,
                    displayIfEmpty: '----'
                }),
                timeKey: timekey,
                measuredAt: fromUTCToTimezone(copySpeed[i].measuredAt as string, true)
            };

            if (speedVal < min) {
                min = speedVal;
            }

            if (speedVal > max) {
                max = speedVal;
            }

            if (currentHasBeenStationary) {
                (copyTimeline as { speed: number; timeKey: number }[])[timekey + timelineUse.granularity] = {
                    speed: 0,
                    timeKey: timekey + timelineUse.granularity
                };
            }

            if (hasStationaryGap) {
                if (!currentHasBeenStationary) {
                    (copyTimeline as { speed: number; timeKey: number }[])[timekey + timelineUse.granularity] = {
                        speed: 0,
                        timeKey: timekey + timelineUse.granularity
                    };
                }
                (copyTimeline as { speed: number; timeKey: number }[])[nextTimekey - timelineUse.granularity] = {
                    speed: 0,
                    timeKey: nextTimekey - timelineUse.granularity
                };
            }

            (
                copyTimeline as {
                    timeKey: number;
                    speedAvg: number;
                    speedMin: number;
                    speedMax: number;
                    measuredAt: string;
                }[]
            )[nextTimekey] = {
                speedAvg: nextSpeedVal,
                speedMin: copySpeed[i + 1].speedMin ?? 0,
                speedMax: copySpeed[i + 1].speedMax ?? 0,
                timeKey: nextTimekey,
                measuredAt: fromUTCToTimezone(copySpeed[i + 1].measuredAt as string, true)
            };
        }

        if (!speedDataAmount) {
            min = 0;
            max = 0;
        }

        setSpeedScale([min, max + 10]);
        return (speedData.length ? Object.values(copyTimeline) : []) as {
            timeKey: number;
            speedAvg: number;
            speedMin: number;
            speedMax: number;
        }[];
    };

    const getSpeed = async (
        dateFrom: DateTime,
        dateTo: DateTime,
        vehicleId: number
    ): Promise<{ timeKey: number; speedAvg: number; speedMin: number; speedMax: number }[]> => {
        if (!vehicleId) return [];

        const speedXHR = await TrackLog.getSpeed(
            vehicleId,
            fromTimezoneToUTC(dateFrom.valueOf()),
            fromTimezoneToUTC(dateTo.valueOf()),
            timelineUse.granularity
        );
        const speedData = await processSpeed(speedXHR.vehicleSpeed);
        return speedData;
    };

    const fastLeakReference = useMemo(() => {
        if (fastLeakPreview) {
            return { label: '', x: fastLeakPreview, yAxisId: 1, stroke: 'red' };
        }
    }, [fastLeakPreview]);

    useEffect(() => {
        redirectParams.params?.fastLeak &&
            enableFastLeak &&
            setFastLeakPreview(DateTime.fromISO(redirectParams.params?.fastLeak).valueOf() / 1000);
    }, [redirectParams.params?.fastLeak]);

    useEffect(() => {
        return () => {
            setFastLeakPreview(undefined);
        };
    }, []);

    return (
        <Graph
            xDomainConfig={xDomainConfigSpeed}
            yDomainConfig={yDomainConfigSpeed}
            globalXDomain={dateTimePicker || globalDateTimePicker}
            setGlobalXDomain={setDateTimePicker || setGlobalDateTimePicker}
            zoomInExternal={zoomInExternal}
            testId={'Speed-Graph'}
            timeline={timeline || globalTimeline}
            fetchData={({ dateFrom, dateTo }) => {
                return getSpeed(dateFrom, dateTo, selectedVehicle);
            }}
            referenceLineY={fastLeakReference}
            xTickFormatter={(tickItem: number) => {
                return fromUTCToTimezone(tickItem * 1000, false);
            }}
            tooltipSettings={{
                zIndex: 1000
            }}
            onHoverGraph={
                onHoverGraph
                    ? (timekey) => {
                          setMapAction({
                              action: MapActionsEnum.DRAW_VEHICLE_AT_TIME,
                              params: {
                                  time: timekey
                              }
                          });
                      }
                    : undefined
            }
        />
    );
};

export default StatisticsSpeed;

import React from 'react';
import { FormControlCustom, UiInputSearchContent as UiInputSearchContentStyle } from './UiInputSearch.style';
import * as type from './UiInputSearch.type';
import { TextField, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { Clear, Search } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

export const UiInputSearchContent: React.FC<type.UiInputSearchViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const theme = useTheme();
    const showIconSearch = useMediaQuery(theme.breakpoints.between('xs', 'sm'));

    return (
        <UiInputSearchContentStyle data-testid='UiInputSearchContent' width={props.widthInputSearchInit}>
            <FormControlCustom width={props.widthInputSearchInit} $small={props.small}>
                <Tooltip
                    title={props.showTooltip && props.searchByEnter ? translate('t.press_enter_to_search') : ''}
                    open={props.showTooltip && props.searchByEnter}
                    arrow
                >
                    <TextField
                        size='small'
                        id='press&temp'
                        variant='outlined'
                        type='text'
                        onFocus={() => props.setShowTooltip(true)}
                        onBlur={() => props.setShowTooltip(false)}
                        onChange={props.handleChangeInputSearch}
                        onKeyDown={(e) => props.handleChangeInputSearch(e)}
                        value={props.valueSearch}
                        inputRef={props.inputSearchRef}
                        className='inputSearchAutoWidth'
                        placeholder={props.placeholder}
                        data-testid={`${props.$testid || 'InputSearch'}`}
                        InputProps={{
                            startAdornment: !showIconSearch ? (
                                <InputAdornment
                                    position='start'
                                    onClick={props.handleClickIconSearch}
                                    style={{ cursor: 'pointer' }}
                                    data-testid='Search-Button'
                                >
                                    <Search />
                                </InputAdornment>
                            ) : null,
                            endAdornment: (
                                <InputAdornment
                                    position='end'
                                    style={{ display: props.showClearIcon, cursor: 'pointer', height: '26px' }}
                                    onClick={props.handleClickInputAdornment}
                                    data-testid='RemoveValue-Button'
                                >
                                    <Clear fontSize='small' />
                                </InputAdornment>
                            )
                        }}
                    />
                </Tooltip>
            </FormControlCustom>
        </UiInputSearchContentStyle>
    );
};

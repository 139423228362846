import React, { useState } from 'react';
import { AlertBody, AlertIcon, AlertTitle, DenseLabel, LightAlertTitle } from './AlertsList.style';
import { ContentAlertsListView } from './AlertsList.type';
import { typeAlert as alertType, typeFilter } from '../TPMSAlertsListTabManager/TPMSAlertsListTabManager.type';
import { useTranslation } from 'react-i18next';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { Box, Collapse, Grid } from '@mui/material';
import { ChipLevel, DenseInfoCover } from '../AlertCustom/AlertCustom.style';
import { ExpandIconButton } from 'components/Alert/AlertsAffectedVehicles/AlertsAffectedVehicles.style';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ModelTpmsAlerts } from 'models/TpmsAlerts.type';
import { faBatteryLow, faHdd, faMemory, faSignalSlash } from '@fortawesome/pro-solid-svg-icons';

const ContentAlertsList: React.FC<ContentAlertsListView> = (props): JSX.Element => {
    const { fromServerToUserUnit, displayUserUnits, convertType, fromUTCtoUserTimezone } = useConverter();
    const { t: translate } = useTranslation();
    const [open, setOpen] = useState<boolean>(false);

    const createRow = (label: string, value: string | number, classCustom = '', leftValue?: string | number) => {
        return (
            <tr>
                <td
                    data-testid={`Alert-label-${props.alert.id}-${label.toLowerCase().replace(/\s+/g, '-')}`}
                    className={`${classCustom}`}
                >
                    {label}: {leftValue as string | number}
                </td>
                <td
                    data-testid={`Alert-value-${props.alert.id}-${label.toLowerCase().replace(/\s+/g, '-')}`}
                    className={`strong ${classCustom} alignRight`}
                >
                    {(value as string | number) ?? '----'}
                </td>
            </tr>
        );
    };

    const contentWearEmmc = (vehicleName, level) => {
        return (
            <>
                <AlertTitle data-testid={`Alert-title-${props.alert.id}`}>
                    <p>
                        <AlertIcon
                            icon={faMemory}
                            className={`${level == 3 ? 'redText' : ''}`}
                            color={level !== 3 ? 'rgb(159, 148, 21)' : undefined}
                            style={{ marginRight: 4 }}
                            size='sm'
                            fixedWidth
                        />
                        {translate(`p.emmc_worn_title_${level}`, { value: vehicleName })}
                    </p>
                </AlertTitle>
                <AlertBody>
                    <table className='tabletAlert'>
                        <tbody>
                            <tr>
                                <td> {translate(`p.emmc_worn_msg_${level}`)}</td>
                            </tr>
                        </tbody>
                    </table>
                </AlertBody>
            </>
        );
    };

    const contentInactiveHub = () => {
        return (
            <>
                <AlertBody>
                    <table className='tabletAlert'>
                        <tbody>
                            <tr>
                                <td>{translate('p.inactive_hub_msg')}</td>
                            </tr>
                        </tbody>
                    </table>
                </AlertBody>
            </>
        );
    };

    const contentPressure = (alert) => {
        return (
            <AlertBody>
                <table className='tabletAlert'>
                    <tbody>
                        {createRow(
                            translate('t.cold_pressure'),
                            fromServerToUserUnit({
                                type: convertType.pressure,
                                value: parseFloat(alert.tyreEventLog?.coldPressure),
                                displayUnits: true,
                                fixed: 1,
                                displayIfEmpty: '---'
                            })
                        )}
                        {createRow(
                            translate('t.recommended_cold_pressure'),
                            fromServerToUserUnit({
                                type: convertType.pressure,
                                value: parseFloat(alert.tyreEventLog?.targetColdPressure),
                                displayUnits: true,
                                fixed: 1,
                                displayIfEmpty: '---'
                            })
                        )}
                        {createRow(
                            translate('t.variance'),
                            fromServerToUserUnit({
                                type: convertType.pressure,
                                value:
                                    parseFloat(alert.tyreEventLog?.coldPressure || 0) -
                                    parseFloat(alert.tyreEventLog?.targetColdPressure || 0),
                                displayUnits: true,
                                fixed: 1,
                                displayIfEmpty: '----'
                            }),
                            'redText'
                        )}
                        {createRow(
                            translate('t.temperature'),
                            fromServerToUserUnit({
                                type: convertType.temperature,
                                value: parseFloat(alert.tyreEventLog?.temperature),
                                displayUnits: true,
                                fixed: 1,
                                displayIfEmpty: '----'
                            })
                        )}
                    </tbody>
                </table>
            </AlertBody>
        );
    };

    const contentHotPressure = (alert) => {
        return (
            <AlertBody>
                <table className='tabletAlert'>
                    <tbody>
                        {createRow(
                            translate('t.hot_pressure'),
                            fromServerToUserUnit({
                                type: convertType.pressure,
                                value: parseFloat(alert.tyreEventLog?.hotPressure),
                                displayUnits: true,
                                fixed: 1,
                                displayIfEmpty: '----'
                            })
                        )}
                        {createRow(
                            translate('t.target_hot_pressure'),
                            fromServerToUserUnit({
                                type: convertType.pressure,
                                value: parseFloat(alert.tyreEventLog?.targetHotPressure),
                                displayUnits: true,
                                fixed: 1,
                                displayIfEmpty: '----'
                            })
                        )}
                        {createRow(
                            translate('t.variance'),
                            fromServerToUserUnit({
                                type: convertType.pressure,
                                value:
                                    parseFloat(alert.tyreEventLog?.hotPressure) -
                                    parseFloat(alert.tyreEventLog?.targetHotPressure),
                                displayUnits: true,
                                fixed: 1,
                                displayIfEmpty: '----'
                            }),
                            'redText'
                        )}
                        {createRow(
                            translate('t.temperature'),
                            fromServerToUserUnit({
                                type: convertType.temperature,
                                value: parseFloat(alert.tyreEventLog?.temperature),
                                displayUnits: true,
                                fixed: 1,
                                displayIfEmpty: '----'
                            })
                        )}
                    </tbody>
                </table>
            </AlertBody>
        );
    };

    const contentTemperature = (alert) => {
        let displayLimit = alert?.ruleLimit?.limitTo;
        let displayVariance: number | null = null;

        if (alert.notification.type2 === 'high') {
            displayLimit = alert?.ruleLimit?.limitFrom;
        }

        if (displayLimit) {
            displayVariance =
                parseFloat(
                    fromServerToUserUnit({
                        type: convertType.temperature,
                        value: alert.tyreEventLog?.temperature,
                        displayUnits: false,
                        fixed: 1
                    })
                ) -
                parseFloat(
                    fromServerToUserUnit({
                        type: convertType.temperature,
                        value: displayLimit,
                        displayUnits: false,
                        fixed: 1
                    })
                );
        }
        return (
            <AlertBody>
                <table className='tabletAlert'>
                    <tbody>
                        {createRow(
                            translate('t.temperature'),
                            fromServerToUserUnit({
                                type: convertType.temperature,
                                value: alert.tyreEventLog?.temperature,
                                displayUnits: true,
                                fixed: 1,
                                displayIfEmpty: '----'
                            })
                        )}
                        {createRow(
                            translate('t.limit_temperature'),
                            fromServerToUserUnit({
                                type: convertType.temperature,
                                value: displayLimit,
                                displayUnits: true,
                                fixed: 1,
                                displayIfEmpty: '----'
                            })
                        )}
                        {createRow(
                            translate('t.variance'),
                            displayVariance ? `${displayVariance.toFixed(1)} ${displayUserUnits.temperature}` : '----',
                            'redText'
                        )}
                        {createRow(
                            translate('t.cold_pressure'),
                            fromServerToUserUnit({
                                type: convertType.pressure,
                                value: alert.tyreEventLog?.coldPressure,
                                displayUnits: true,
                                fixed: 1,
                                displayIfEmpty: '----'
                            })
                        )}
                    </tbody>
                </table>
            </AlertBody>
        );
    };

    const contentTemperaturePrediction = (alert): JSX.Element => {
        const variance = (alert.vehiclePrediction?.criticalTemperature || 0) - (alert.tyreEventLog?.temperature || 0);

        return (
            <AlertBody>
                <table className='tabletAlert'>
                    <tbody>
                        {createRow(
                            translate('t.temperature'),
                            fromServerToUserUnit({
                                type: convertType.temperature,
                                value: alert.tyreEventLog?.temperature,
                                displayUnits: true,
                                fixed: 1,
                                displayIfEmpty: '----'
                            })
                        )}
                        {createRow(
                            `${translate('t.remaining_time')} ${fromServerToUserUnit({
                                type: convertType.temperature,
                                value: alert.vehiclePrediction?.criticalTemperature,
                                displayUnits: true,
                                displayIfEmpty: '---',
                                fixed: 1
                            })}`,
                            alert.vehiclePrediction?.remainingHours ?? '----'
                        )}
                        {createRow(
                            translate('t.variance'),
                            fromServerToUserUnit({
                                type: convertType.temperature,
                                value: variance || undefined,
                                displayUnits: true,
                                fixed: 1,
                                displayIfEmpty: '----'
                            })
                        )}
                    </tbody>
                </table>
            </AlertBody>
        );
    };

    const getDenseAlertValue = (alert) => {
        switch (alert.notification?.type) {
            case typeFilter.PRESSURE:
                return fromServerToUserUnit({
                    type: convertType.pressure,
                    value: parseFloat(alert.tyreEventLog?.coldPressure),
                    displayUnits: true,
                    fixed: 1,
                    displayIfEmpty: '----'
                });
            case typeFilter.PRESSURE_HOT:
                return fromServerToUserUnit({
                    type: convertType.pressure,
                    value: parseFloat(alert.tyreEventLog?.hotPressure),
                    displayUnits: true,
                    fixed: 1,
                    displayIfEmpty: '----'
                });
            case typeFilter.TEMPERATURE:
                return fromServerToUserUnit({
                    type: convertType.temperature,
                    value: alert.tyreEventLog?.temperature,
                    displayUnits: false,
                    fixed: 1
                });
            case typeFilter.TEMPERATURE_PREDICTION:
                return fromServerToUserUnit({
                    type: convertType.temperature,
                    value: alert.tyreEventLog?.temperature,
                    displayUnits: false,
                    fixed: 1
                });
            default:
                return fromServerToUserUnit({
                    type: convertType.pressure,
                    value: parseFloat(alert.tyreEventLog?.coldPressure),
                    displayUnits: true,
                    fixed: 1,
                    displayIfEmpty: '----'
                });
        }
    };

    const TPMSandHistoricalLogContent = (alert) => {
        let contentTable: JSX.Element;
        switch (alert.notification?.type) {
            case typeFilter.PRESSURE:
                contentTable = contentPressure(alert);
                break;
            case typeFilter.PRESSURE_HOT:
                contentTable = contentHotPressure(alert);
                break;
            case typeFilter.TEMPERATURE:
                contentTable = contentTemperature(alert);
                break;
            case typeFilter.TEMPERATURE_PREDICTION:
                contentTable = contentTemperaturePrediction(alert);
                break;
            case typeFilter.ANTENNA:
                contentTable = (
                    <AlertBody>
                        <table className='tabletAlert'>
                            <tbody>
                                <tr>
                                    <td>{translate('p.sensor_antenna_fault_description')}</td>
                                </tr>
                            </tbody>
                        </table>
                    </AlertBody>
                );
                break;
            case typeFilter.EMMC:
                contentTable = contentWearEmmc(alert.vehicle.name, alert.notification.level);
                break;
            case 'inactive_hub':
                contentTable = contentInactiveHub();
                break;
            default:
                contentTable = contentPressure(alert);
                break;
        }

        const msgType: string = alert.notification?.type === 'leak' ? 'pressure_leak' : alert.notification?.type;
        const actualLevel =
            alert.notification?.type === 'temperature_prediction' ? Math.min((props.level || 0) + 1, 3) : props.level;
        if (props.dense) {
            return (
                <>
                    <Grid container xs={12} md={12} lg={12}>
                        <Grid item xs={12} md={12} lg={12}>
                            <AlertTitle data-testid={`Alert-title-${props.alert.id}`} dense>
                                <DenseInfoCover>
                                    <Box display={'flex'} alignItems={'center'}>
                                        <ChipLevel
                                            size='small'
                                            level={actualLevel || 0}
                                            label={`Level ${props.level}`}
                                        />
                                        <DenseLabel>
                                            {`
                                            ${
                                                alert.notification
                                                    ? translate(`t.${alert.notification.type2}_${msgType}`)
                                                    : ''
                                            }
                                            ${translate('t.tyre')} ${alert.wheel?.customPosition}
                                            ${translate('t.on_vehicle')} ${alert.vehicle.name}
                                        `}
                                            {!open && ` - ${getDenseAlertValue(props.alert)}`}
                                        </DenseLabel>
                                    </Box>
                                    <Box display={'flex'} alignItems={'center'}>
                                        <DenseLabel $date>
                                            {fromUTCtoUserTimezone({
                                                date: (props.alert as ModelTpmsAlerts).measuredAt,
                                                format: 'dateTime',
                                                displaySeconds: false
                                            })}
                                        </DenseLabel>
                                        <ExpandIconButton
                                            expand={open}
                                            size='small'
                                            onClick={() => {
                                                setOpen((current) => !current);
                                            }}
                                        >
                                            <ExpandMoreIcon />
                                        </ExpandIconButton>
                                    </Box>
                                </DenseInfoCover>
                            </AlertTitle>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <Collapse in={open}>{props.displayBody && contentTable}</Collapse>
                        </Grid>
                    </Grid>
                </>
            );
        }

        return (
            <>
                {alert.notification.type !== typeFilter.ANTENNA &&
                    alert.notification.type !== typeFilter.EMMC &&
                    alert.notification.type !== 'inactive_hub' && (
                        <AlertTitle data-testid={`Alert-title-${props.alert.id}`}>
                            <p>
                                {`
                            ${alert.notification ? translate(`t.${alert.notification.type2}_${msgType}`) : ''}
                            ${translate('t.tyre')} ${alert.wheel?.customPosition}
                            ${translate('t.on_vehicle')} ${alert.vehicle.name}
                        `}
                            </p>
                        </AlertTitle>
                    )}

                {alert.notification.type === 'inactive_hub' && (
                    <AlertTitle data-testid={`Alert-title-${alert.vehicle.name}`}>
                        <p>
                            <AlertIcon icon={faHdd} color={'rgb(159, 148, 21)'} size='sm' fixedWidth />

                            {`${translate('t.vehicle')} ${alert.vehicle.name} ${translate('p.has_inactive_hub')}`}
                        </p>
                    </AlertTitle>
                )}

                {alert.notification.type === typeFilter.ANTENNA && (
                    <AlertTitle data-testid={`Alert-title-${props.alert.id}`}>
                        <p>
                            {' '}
                            <AlertIcon icon={faSignalSlash} color={'rgb(159, 148, 21)'} size='sm' fixedWidth />
                            {translate('p.sensor_antenna_fault_msg', { value: alert.vehicle.name })}
                        </p>
                    </AlertTitle>
                )}

                {props.displayBody && contentTable}
            </>
        );
    };

    const getGPSTitle = (eventType: string): string => {
        const titles = {
            VehicleMaxSpeed: translate('t.maximum_speed_exceeded'),
            AreaMinSpeed: translate('t.minimum_speed_exceeded_area'),
            AreaMaxSpeed: translate('t.maximum_speed_exceeded_area')
        };
        return titles[eventType];
    };

    const GPSContent = (alert) => {
        return (
            <>
                <AlertTitle data-testid={`Alert-title-${props.alert.id}`}>
                    <p>{getGPSTitle(alert.eventType)}</p>
                </AlertTitle>

                <AlertBody>
                    <table className='tabletAlert'>
                        <tbody>
                            {createRow(translate('t.vehicle'), alert.vehicle.name)}
                            {alert.eventType === 'AreaMinSpeed'
                                ? createRow(
                                      translate('t.min_speed'),
                                      fromServerToUserUnit({
                                          type: convertType.speed,
                                          value: alert.minSpeed,
                                          displayUnits: true,
                                          fixed: 1
                                      })
                                  )
                                : createRow(
                                      translate('t.max_speed'),
                                      fromServerToUserUnit({
                                          type: convertType.speed,
                                          value: alert.maxSpeed,
                                          displayUnits: true,
                                          fixed: 1
                                      })
                                  )}
                            {alert.area && createRow(translate('t.area'), alert.area ? alert.area?.name : '- - - -')}
                            {createRow(
                                `${translate('t.current')} ${translate('t.speed')}`,
                                fromServerToUserUnit({
                                    type: convertType.speed,
                                    value: alert.currentSpeed,
                                    displayUnits: true,
                                    fixed: 1
                                })
                            )}
                        </tbody>
                    </table>
                </AlertBody>
            </>
        );
    };

    const SensorContent = (alert) => {
        switch (alert.notification.type) {
            case 'inactive_hub':
                return (
                    <>
                        <AlertTitle data-testid={`Alert-title-${props.alert.id}`}>
                            <p>
                                <AlertIcon icon={faHdd} color={'rgb(159, 148, 21)'} size='sm' fixedWidth />

                                {`${translate('t.vehicle')} ${alert.vehicle.name} ${translate('p.has_inactive_hub')}`}
                            </p>
                        </AlertTitle>
                        <AlertBody>
                            <table className='tabletAlert'>
                                <tbody>
                                    <tr>
                                        <td>{translate('p.inactive_hub_msg')}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </AlertBody>
                    </>
                );
            case 'antenna':
                return (
                    <>
                        <AlertTitle data-testid={`Alert-title-${props.alert.id}`}>
                            <AlertIcon icon={faSignalSlash} color={'rgb(159, 148, 21)'} size='sm' fixedWidth />
                            <p>{`${translate('t.vehicle')} ${alert.vehicle.name} ${translate(
                                'p.has_faulty_antenna'
                            )}`}</p>
                        </AlertTitle>
                        <AlertBody>
                            <table className='tabletAlert'>
                                <tbody>
                                    <tr>
                                        <td>{translate('p.review_faulty_antenna')}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </AlertBody>
                    </>
                );
            case 'battery_voltage':
                return (
                    <>
                        <AlertTitle data-testid={`Alert-title-${props.alert.id}`}>
                            <p
                                title={translate('p.sensor_battery_tooltip', {
                                    value: `${alert.sensor?.sensorMake?.criticalVoltage}`
                                })}
                            >
                                <AlertIcon icon={faBatteryLow} className='redText' size='sm' fixedWidth />
                                {translate('p.critical_low_battery', { value: alert.vehicle.name ?? '-' })}
                            </p>
                        </AlertTitle>
                        <AlertTitle data-testid={`Alert-title-${props.alert.id}`}>
                            <p
                                style={{
                                    fontWeight: 500,
                                    width: '100% !important',
                                    marginBottom: '13px',
                                    lineHeight: '1em'
                                }}
                                title={translate('p.sensor_battery_tooltip', {
                                    value: `${alert.sensor?.sensorMake?.criticalVoltage}`
                                })}
                            >
                                {translate('p.sensor_battery_is_bellow_percentage', {
                                    value: `${alert.sensor?.sensorMake?.criticalVoltage}`
                                })}
                            </p>
                        </AlertTitle>

                        <AlertBody>
                            <table className='tabletAlert'>
                                <tbody>
                                    {createRow(translate('t.serial_number'), alert.sensor?.serialNumberHex ?? '-')}

                                    {createRow(
                                        translate('t.sensor_type'),
                                        alert.sensor?.sensorType?.name === 'Internal'
                                            ? translate('t.internal')
                                            : translate('t.external')
                                    )}
                                    {createRow(translate('t.wheel_position'), alert.wheel?.customPosition ?? '-')}
                                </tbody>
                            </table>
                        </AlertBody>
                    </>
                );
            case 'emmc':
                return contentWearEmmc(alert.vehicle.name, alert.notification.level);
            default:
                return (
                    <AlertTitle data-testid={`Alert-title-${props.alert.id}`}>
                        <p>
                            {translate('t.unknown_alert')} ({alert.notification.type})
                        </p>
                    </AlertTitle>
                );
        }
    };

    const VehicleEventContent = (alert) => {
        return (
            <>
                <AlertTitle data-testid={`Alert-title-${props.alert.id}`}>
                    <LightAlertTitle>
                        {translate('p.fault_code_on_vehicle')} <strong>{alert.vehicle.name}</strong>
                    </LightAlertTitle>
                </AlertTitle>
                <AlertBody>
                    <table className='tabletAlert'>
                        <tbody>
                            {createRow(translate('t.code'), ` ${alert.code.description}`, '', alert.code.code)}
                        </tbody>
                    </table>
                </AlertBody>
            </>
        );
    };

    return (
        <>
            {props.typeAlertContent === alertType.VehicleEvent
                ? VehicleEventContent(props.alert)
                : props.typeAlertContent === alertType.GPS
                ? GPSContent(props.alert)
                : props.typeAlertContent === alertType.Sensor
                ? SensorContent(props.alert)
                : TPMSandHistoricalLogContent(props.alert)}
        </>
    );
};

export default ContentAlertsList;

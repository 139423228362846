import React from 'react';
import {
    AlertInfo,
    RemoveTextBox,
    RequestProgress,
    Text,
    TextDiv,
    VehicleActionsViewContent
} from './VehicleActions.style';
import { VehicleActionsViewProps } from './VehicleActions.type';
import Modal from 'components/Modal/Modal';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { VehicleActionAtom } from 'states/component/Vehicle';
import { ShowVehicleModal } from 'states/global/Modal';
import Button from 'components/Ui/Components/UiButton/UiButton';
import { ERROR, SUCCESS } from 'components/Ui/colors';
import { VehicleActionsFormik } from './VehicleActions.formik';
import { CRUD } from 'variables';
import { SIZE_BUTTON, VARIANT_BUTTON } from 'components/Ui/variables';
import { useTranslation } from 'react-i18next';

import { Theme } from 'states/global/Theme';
import UiLoadingPage from 'components/Ui/Components/UiLoadingPage/UiLoadingPage';

export const VehicleActionsContent: React.FC<VehicleActionsViewProps> = (props): JSX.Element => {
    const vehicleActionAtom = useRecoilValue(VehicleActionAtom);
    const resetVehicleActionAtom = useResetRecoilState(VehicleActionAtom);
    const vehicleModal = useSetRecoilState(ShowVehicleModal);
    const isEditAction: boolean = vehicleActionAtom.action === CRUD.EDIT;
    const isRemoveAction: boolean = vehicleActionAtom.action === CRUD.DELETE;
    const { t: translate } = useTranslation();
    const modalLabel = {
        add: translate('t.add_vehicle'),
        edit: translate('t.edit_vehicle'),
        clone: translate('t.clone_vehicle'),
        delete: `${translate('t.remove_vehicle')} - ${vehicleActionAtom.vehicleName}`
    };
    const ThemeState = useRecoilValue(Theme);

    const createRemoveMessage = (): JSX.Element => {
        return (
            <RemoveTextBox>
                <TextDiv>
                    <Text textAlign='center'>
                        {translate('p.are_you_sure_to_delete_entity', {
                            entity: vehicleActionAtom.vehicleName
                        })}
                    </Text>
                </TextDiv>
                <AlertInfo severity='warning'>
                    <Text variant='subtitle1'>{translate('p.vehicle_removing_historical_data')}</Text>
                </AlertInfo>
                {!!props.attachedSensorVehicleData?.length || vehicleActionAtom.hasDevice ? (
                    <AlertInfo severity='info'>
                        <Text variant='subtitle1'>{translate('t.automatic_hardware_unmount')}</Text>
                    </AlertInfo>
                ) : (
                    <></>
                )}
            </RemoveTextBox>
        );
    };

    const buttons = (): JSX.Element => {
        if (props.isProcessing) return <RequestProgress />;
        return (
            <Button
                variant={VARIANT_BUTTON}
                size={SIZE_BUTTON}
                skin={isRemoveAction ? 'error' : 'success'}
                color={isRemoveAction ? 'error' : 'primary'}
                form='vehicle-action-form'
                type='submit'
                disabled={props.isLoading || (!isRemoveAction && props.isNameUnique)}
                onClick={() => {
                    vehicleActionAtom.action === CRUD.DELETE && props.removeVehicle();
                }}
                testid={
                    isEditAction
                        ? 'ButtonToSaveDataFromTheEditVehicleForm'
                        : isRemoveAction
                        ? 'ButtonToConfirmTheDeletionOfAVehicle'
                        : 'ButtonToSaveDataFromTheAddVehicleForm'
                }
            >
                {isEditAction ? translate('t.save') : isRemoveAction ? translate('t.remove') : translate('t.add')}
            </Button>
        );
    };

    const contentRender = () => {
        if (props.isLoading) return <UiLoadingPage height='45px' size='30px' />;
        return isRemoveAction ? createRemoveMessage() : <VehicleActionsFormik {...props} />;
    };

    return (
        <VehicleActionsViewContent data-testid='VehicleActionsContent'>
            <Modal
                testid='vehicle-action-modal'
                id='VehicleActionModal'
                leftTitle={modalLabel[vehicleActionAtom.action as CRUD]}
                rightTitle={isEditAction ? `${translate('t.vehicle')}: ${vehicleActionAtom.vehicleName}` : ''}
                type='ShowVehicleModal'
                buttons={buttons()}
                onClose={() => {
                    props.setIsNameUnique(false);
                    vehicleModal(false);
                    resetVehicleActionAtom();
                    props.setFormikValuesToDefault();
                }}
                cancelColor={isRemoveAction ? ERROR : SUCCESS}
                width={isRemoveAction ? 515 : 820}
                height={isRemoveAction ? undefined : 99}
                heightUnit='vh'
                maxHeight={ThemeState.bigFonts ? '505px' : '755px'}
                content={contentRender()}
            />
        </VehicleActionsViewContent>
    );
};

import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Alert, Grid, TextField } from '@mui/material';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import HelperText from 'components/Mixs/HelperText';
import useModal from 'components/CustomHooks/ModalHook';
import UiButton from 'components/Ui/Components/UiButton';
import { activateTablet } from '../RequestFunctions';
import QrCodeModal from '../QrCodeModal/QrCodeModal';
import { ExpiredMessage } from '../QrCodeModal/QrCodeModal.style';
import { QrCodeIsExpired } from '../TabletActivation.atom';

const TabletActivationModal = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const { closeModal } = useModal();
    const { setModalState } = useModal(1);
    const [loading, setLoading] = useState<boolean>(false);
    const [qrCodeIsExpired, setQrCodeIsExpired] = useRecoilState(QrCodeIsExpired);

    const formValidation = Yup.object().shape({
        name: Yup.string().required(translate('t.required'))
    });

    const getCode = async (name: string): Promise<void> => {
        setLoading(true);
        await activateTablet(name).then((res) => {
            setQrCodeIsExpired(false);
            setModalState({
                isOpen: true,
                leftTitle: 'QR code',
                content: <QrCodeModal code={res.code} expiresIn={res.expires_in} />,
                width: 330,
                height: 440
            });
        });
    };

    return (
        <Formik
            initialValues={{ name: '', role: 'ROLE_BASE_USER' }}
            validationSchema={formValidation}
            onSubmit={(values) => {
                getCode(values.name).then(() => setLoading(false));
            }}
        >
            {({ errors, touched, handleChange, values, dirty, isValid }) => {
                return (
                    <Form id='vehicle_activation_form' style={{ paddingTop: '30px' }}>
                        <Grid
                            container
                            style={{ paddingLeft: '3px', paddingBottom: qrCodeIsExpired ? '20px' : '70px' }}
                        >
                            <Grid item xs={12}>
                                <TextField
                                    data-testid='tablet-name'
                                    id='tablet-name'
                                    name='name'
                                    error={!!errors.name && touched.name}
                                    label={translate('t.tablet_name')}
                                    value={values.name}
                                    variant='outlined'
                                    size='small'
                                    type='text'
                                    fullWidth
                                    onChange={(e) => {
                                        if (!e.currentTarget.value) {
                                            setQrCodeIsExpired(false);
                                        }
                                        handleChange(e);
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    helperText={
                                        <HelperText
                                            text={errors.name}
                                            defaultText={translate('t.required')}
                                            error={!!errors.name && !!touched.name}
                                        />
                                    }
                                />
                            </Grid>
                            {qrCodeIsExpired && values.name && (
                                <Grid item xs={12}>
                                    <ExpiredMessage>
                                        <Alert severity={'warning'} style={{ width: '100%' }}>
                                            {translate('p.qr_code_expired')}
                                        </Alert>
                                    </ExpiredMessage>
                                </Grid>
                            )}
                        </Grid>
                        <>
                            <UiButton
                                skin='success'
                                variant='contained'
                                form='vehicle_activation_form'
                                type='submit'
                                testid='generate-qr'
                                disabled={!(dirty && isValid)}
                                loading={loading}
                            >
                                {translate('t.generate_qr_code')}
                            </UiButton>
                            <UiButton skin='success' variant='outlined' testid='close-modal' onClick={closeModal}>
                                {translate('t.cancel')}
                            </UiButton>
                        </>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default TabletActivationModal;

import React from 'react';
import {
    ConnectedDisplaysTD,
    DetailsTD,
    DetailsTable,
    DisplayTD,
    VehicleDetailsSummaryViewContent
} from './VehicleDetailsSummary.style';
import { VehicleDetailsSummaryViewProps } from './VehicleDetailsSummary.type';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { UserInfo } from '../../../states/global/User';
import UiButton from '../../Ui/Components/UiButton/UiButton';
import { checkRole } from 'helpers/authentication';
import { ROLES } from 'variables';
import { VehicleDetailsWidgetAtom } from '../VehicleDetailsWidget/VehicleDetailsWidget.atom';
import SensorButtonAround from '../../Hub/SensorButtonAround/SensorButtonAround';
import { SIZE_BUTTON, VARIANT_BUTTON } from '../../Ui/variables';
import UiLink from '../../Ui/Components/UiLink/UiLink';
import useConverter from '../../CustomHooks/Converter/Converter';
import { VehicleModel } from 'models/Vehicle.type';
import { DisplayType } from 'models/VehicleInfo.type';

export const VehicleDetailsSummaryContent: React.FC<VehicleDetailsSummaryViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const userInfo = useRecoilValue(UserInfo);
    const { fromUTCToTimezone, convertType, fromServerToUserUnit } = useConverter();
    const privileges = checkRole(userInfo.user, [
        ROLES.ROLE_SUPER_ADMIN,
        ROLES.ROLE_ADMIN,
        ROLES.ROLE_TECHNICAL_ADMIN,
        ROLES.ROLE_TECHNICAL_USER
    ]);
    const vehicleDetailsWidgetAtom = useRecoilValue(VehicleDetailsWidgetAtom());
    const vehicleDetails = vehicleDetailsWidgetAtom.vehicleDetails as VehicleModel | undefined;

    return (
        <VehicleDetailsSummaryViewContent data-testid='VehicleDetailsSummaryContent'>
            <DetailsTable>
                <tbody>
                    <tr>
                        <DetailsTD>{translate('t.vehicle_name')}:</DetailsTD>
                        <td>{vehicleDetails?.name}</td>
                    </tr>
                    <tr>
                        <DetailsTD>Toolbox ID:</DetailsTD>
                        <DetailsTD>{`${userInfo.user?.customer.id}-${vehicleDetails?.id}`}</DetailsTD>
                    </tr>
                    <tr>
                        <DetailsTD>{translate('t.manufacturer')}:</DetailsTD>
                        <td>{vehicleDetails?.vehicleManufacturerV2}</td>
                    </tr>
                    <tr>
                        <DetailsTD>{translate('t.vehicle_model')}:</DetailsTD>
                        <td>{vehicleDetails?.vehicleModelV2}</td>
                    </tr>
                    <tr>
                        <DetailsTD>{translate('t.rim_size')}:</DetailsTD>
                        <td>{vehicleDetails?.customRimSize?.diameter || vehicleDetails?.rimSize?.diameter}</td>
                    </tr>
                    <tr>
                        <DetailsTD>{translate('t.hub_serial_number')}:</DetailsTD>
                        <td>
                            {vehicleDetailsWidgetAtom.vehicleDetails && vehicleDetails?.device ? (
                                privileges ? (
                                    <UiLink
                                        content={vehicleDetails?.device.serialNumber}
                                        title={vehicleDetails?.device.serialNumber}
                                        url={`/device/${vehicleDetails?.device.id}`}
                                        testId='SummaryTab-LinkTo-HubDetails-Button'
                                    />
                                ) : (
                                    vehicleDetails?.device.serialNumber
                                )
                            ) : (
                                translate('t.no_hub_attached')
                            )}
                        </td>
                    </tr>
                    {typeof vehicleDetails?.vehicleInfo.display !== 'string' && (
                        <tr>
                            <ConnectedDisplaysTD>
                                {`${translate('t.connected_displays')} 
                                ${vehicleDetails?.vehicleInfo?.display?.length || 0}`}
                                :
                            </ConnectedDisplaysTD>
                            <DisplayTD>
                                {vehicleDetails?.vehicleInfo && vehicleDetails?.vehicleInfo.display?.length > 0
                                    ? (vehicleDetails.vehicleInfo.display as DisplayType[]).map((display, index) => (
                                          <>
                                              <table key={index}>
                                                  <tbody>
                                                      <tr>
                                                          <td>{translate('t.connected_displays_mac')}:</td>
                                                          <td>{display.MAC}</td>
                                                      </tr>
                                                      <tr>
                                                          <td>{translate('t.connected_displays_ip')}:</td>
                                                          <td>{display.IP}</td>
                                                      </tr>
                                                      <tr>
                                                          <td>{translate('t.connected_displays_app_version')}:</td>
                                                          <td>{display.apk_version ?? '-'}</td>
                                                      </tr>
                                                      <tr>
                                                          <td>{translate('t.connected_displays_app_name')}:</td>
                                                          <td>{display.apk_name ?? '-'}</td>
                                                      </tr>
                                                      <tr>
                                                          <td>{translate('t.connected_displays_topicon_id')}:</td>
                                                          <td>{display.topicon_id ?? '-'}</td>
                                                      </tr>
                                                      <tr>
                                                          <td>{translate('t.connected_displays_topicon_fw')}:</td>
                                                          <td>
                                                              {display.fw_version ?? '-'}
                                                              <UiButton
                                                                  variant={VARIANT_BUTTON}
                                                                  size={SIZE_BUTTON}
                                                                  skin='success'
                                                                  color='primary'
                                                                  style={{ float: 'right' }}
                                                                  onClick={() => {
                                                                      props.handleScreenshotButton(display.MAC);
                                                                  }}
                                                                  testid='SummaryTab-Screenshot-Button'
                                                              >
                                                                  Screenshot
                                                              </UiButton>
                                                          </td>
                                                      </tr>
                                                      <tr>
                                                          <td>{translate('t.connected_displays_attempts')}:</td>
                                                          <td>{display.attempts}</td>
                                                      </tr>
                                                  </tbody>
                                              </table>
                                          </>
                                      ))
                                    : '--'}
                            </DisplayTD>
                        </tr>
                    )}
                    <tr>
                        <DetailsTD>{translate('t.data_feed')}</DetailsTD>
                        <td>
                            {vehicleDetails?.device?.deviceInfo
                                ? props.serialConnectionStatus(
                                      vehicleDetails?.device?.deviceInfo.sc,
                                      vehicleDetails?.device?.deviceInfo.scc
                                  )
                                : translate('t.unknown')}
                        </td>
                    </tr>
                    {vehicleDetails?.vehicleInfo.displayUpdateTime && (
                        <tr>
                            <DetailsTD>{translate('t.connected_displays_status_change')}:</DetailsTD>
                            <td>{fromUTCToTimezone(vehicleDetails?.vehicleInfo.displayUpdateTime, false)}</td>
                        </tr>
                    )}
                    <tr>
                        <DetailsTD>{translate('t.axle')}:</DetailsTD>
                        <td>{vehicleDetails?.vehicleConfigurationV2.axles}</td>
                    </tr>
                    <tr>
                        <DetailsTD>{translate('t.wheels')}</DetailsTD>
                        <td>
                            {vehicleDetails?.vehicleConfigurationV2.wheels}
                            {vehicleDetails?.device ? (
                                <SensorButtonAround
                                    deviceId={vehicleDetails?.device.id}
                                    lastTimeConnected={vehicleDetails.device.deviceInfo.lastTransmission}
                                />
                            ) : (
                                <></>
                            )}
                        </td>
                    </tr>
                    <tr>
                        <DetailsTD>{translate('t.start_odometer_reading')}</DetailsTD>
                        <td>
                            {fromServerToUserUnit({
                                type: convertType.distance,
                                value: +(vehicleDetails?.startOdometer || 0),
                                displayUnits: true,
                                fixed: 0
                            })}
                        </td>
                    </tr>
                    <tr>
                        <DetailsTD>{translate('t.accelerometer_calibrated')}</DetailsTD>
                        <td>{translate(`t.${vehicleDetails?.accelerometerIsCalibrated ? 'yes' : 'no'}`)}</td>
                    </tr>
                    <tr>
                        <DetailsTD>{translate('t.alerts_count')}</DetailsTD>
                        <td>{vehicleDetails?.alertsCount}</td>
                    </tr>
                    <tr>
                        <DetailsTD>{translate('t.hub_firmware')}</DetailsTD>
                        <td>
                            {vehicleDetails?.device?.deviceInfo.fmw
                                ? vehicleDetails?.device?.deviceInfo.fmw
                                : translate('t.no_device_attached')}
                        </td>
                    </tr>
                    <tr>
                        <DetailsTD>{translate('t.hub_software')}</DetailsTD>
                        <td>
                            {vehicleDetails?.device?.deviceInfo.service
                                ? vehicleDetails?.device?.deviceInfo.service
                                : translate('t.no_device_attached')}
                        </td>
                    </tr>
                    <tr>
                        <DetailsTD>{translate('t.hub_tools')}</DetailsTD>
                        <td>
                            {vehicleDetails?.device?.deviceInfo.tools
                                ? vehicleDetails?.device?.deviceInfo.tools
                                : translate('t.no_device_attached')}
                        </td>
                    </tr>
                    <tr>
                        <DetailsTD>{translate('t.hub_revision')}</DetailsTD>
                        <td>
                            {vehicleDetails?.device?.deviceRevision
                                ? vehicleDetails?.device?.deviceRevision?.description
                                : translate('t.no_hub_attached')}
                        </td>
                    </tr>
                    <tr>
                        <DetailsTD>{translate('t.passing_vehicles')}</DetailsTD>
                        <td>{vehicleDetails?.id && props.showButtonPass()}</td>
                    </tr>
                    <tr>
                        <DetailsTD>{translate('t.comment')}</DetailsTD>
                        <td>{vehicleDetails?.comment}</td>
                    </tr>
                    <tr>
                        <DetailsTD>{translate('t.created')}</DetailsTD>
                        <td>{fromUTCToTimezone(vehicleDetails?.createdAt || '', false)}</td>
                    </tr>
                    <tr>
                        <DetailsTD>{translate('t.last_updated')}</DetailsTD>
                        <td>
                            {vehicleDetails?.vehicleInfo.measuredAt
                                ? fromUTCToTimezone(vehicleDetails?.vehicleInfo.measuredAt as string, false)
                                : '-'}
                        </td>
                    </tr>
                </tbody>
            </DetailsTable>
        </VehicleDetailsSummaryViewContent>
    );
};

import React from 'react';
import { VehicleDetailsViewProps } from './VehicleDetails.type';
import {
    AlertsTab,
    DeviceText,
    GpsLastRecord,
    IconLabel,
    LastGpsMeasuredAt,
    UiBadge,
    VehicleDetailsTabs,
    VehicleDetailsViewContent
} from './VehicleDetails.style';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import TabContent from '../../Ui/Components/UiTab/UiTab';
import VehicleDetailsSummary from '../VehicleDetailsSummary/VehicleDetailsSummary';
import { typeAlert } from '../../AlertNotification/TPMSAlertsListTabManager/TPMSAlertsListTabManager.type';
import { isAdmin } from 'states/global/User';
import { ActiveVehicleDetailsTab } from './VehicleDetails.state';
import { VehicleDetailsWidgetAtom } from '../VehicleDetailsWidget/VehicleDetailsWidget.atom';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';
import VehicleDetailsTyres from '../../Tyre/VehicleDetailsTyres';
import { applyStyleByMode } from 'helpers';
import { SECONDARY_DARK_MODE, WHITE } from 'components/Ui/colors';
import { Theme } from 'states/global/Theme';
import UiScroll from 'components/Ui/Components/UiScroll/UiScroll';
import VehicleDetailsSettings from '../VehicleDetailsSettings';
import { LazyLoadingAlertsList } from './LazyLoadingAlertsList';
import { VehicleModel } from 'models/Vehicle.type';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { CustomerSettings } from 'states/global/CustomerSettings';

const PAGE_COUNT = 5;

export const VehicleDetailsContent: React.FC<VehicleDetailsViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const { fromUTCToTimezone } = useConverter();
    const activeTabAtom = useRecoilValue(ActiveVehicleDetailsTab);
    const activeTab = props.isVehicleAlertsWidget ? activeTabAtom.alertsWidget : activeTabAtom.detailsWidget;
    const isDetailsOnly = location.hash.includes('detail');
    const isUserAdmin = useRecoilValue(isAdmin);
    const vehicleDetailsWidgetAtom = useRecoilValue(VehicleDetailsWidgetAtom());
    const ThemeMode = useRecoilValue(Theme);
    const customerSettings = useRecoilValue(CustomerSettings);

    return (
        <VehicleDetailsViewContent data-testid='VehicleDetailsContent'>
            {(vehicleDetailsWidgetAtom.vehicleDetails as VehicleModel)?.id && (
                <>
                    <VehicleDetailsTabs
                        value={activeTab}
                        onChange={props.handleTabChange}
                        indicatorColor='primary'
                        textColor='primary'
                        variant='scrollable'
                        scrollButtons='auto'
                    >
                        <AlertsTab
                            label={
                                <IconLabel>
                                    <UiIcon
                                        icon={['fas', 'tire-rugged']}
                                        fontSize={17}
                                        fixedWidth
                                        color={applyStyleByMode({
                                            theme: ThemeMode?.mode,
                                            light: '#0000008a',
                                            dark: WHITE
                                        })}
                                        transform='up-1 left-2'
                                    />
                                    <div>{translate('t.tyres')}</div>
                                </IconLabel>
                            }
                            $hide={props.displayTab(props.isVehicleAlertsWidget, false)}
                            data-testid='VehicleStatus-TyresTab-Button'
                        />
                        <AlertsTab
                            label={
                                <IconLabel>
                                    <UiIcon
                                        icon={['fas', 'file-alt']}
                                        fontSize={15}
                                        fixedWidth
                                        color={applyStyleByMode({
                                            theme: ThemeMode?.mode,
                                            light: '#0000008a',
                                            dark: WHITE
                                        })}
                                        transform='up-1 left-3'
                                    />
                                    <div>{translate('t.summary')}</div>
                                </IconLabel>
                            }
                            $hide={props.displayTab(props.isVehicleAlertsWidget, false)}
                            data-testid='VehicleStatus-SummaryTab-Button'
                        />
                        <AlertsTab
                            label={
                                <IconLabel>
                                    <UiBadge badgeContent={`${props.TPMS}`}>
                                        <UiIcon
                                            icon={['fas', 'tire-pressure-warning']}
                                            fontSize={17}
                                            fixedWidth
                                            transform='up-0 down-1 left-3'
                                        />
                                        TPMS
                                    </UiBadge>
                                </IconLabel>
                            }
                            $hide={props.displayTab(props.isVehicleAlertsWidget, true)}
                            $badge={true}
                        />
                        <AlertsTab
                            label={
                                <IconLabel>
                                    <UiBadge badgeContent={`${props.vehicleAlerts}`}>
                                        <UiIcon
                                            icon={['fas', 'truck']}
                                            fontSize={17}
                                            fixedWidth
                                            transform='up-0 down-1 left-3'
                                        />
                                        {translate('t.vehicles_alerts')}
                                    </UiBadge>
                                </IconLabel>
                            }
                            $hide={
                                props.displayTab(props.isVehicleAlertsWidget, true) ||
                                !customerSettings.vehicle_alerts_enabled
                            }
                            $badge={true}
                        />
                        <AlertsTab
                            label={
                                <IconLabel>
                                    <UiBadge badgeContent={`${props.GPS}`} $color='#a90329'>
                                        <UiIcon
                                            icon={['fas', 'map-marker']}
                                            fontSize={17}
                                            fixedWidth
                                            transform='up-0 down-1 left-3'
                                        />
                                        GPS
                                    </UiBadge>
                                </IconLabel>
                            }
                            $hide={props.displayTab(props.isVehicleAlertsWidget, true)}
                            $badge={true}
                            data-testid='VehicleStatus-GPSTab-Button'
                        />
                        <AlertsTab
                            label={
                                <IconLabel>
                                    <UiBadge badgeContent={`${props.systemAlerts}`} $color='#a90329'>
                                        <UiIcon
                                            icon={['fas', 'triangle-exclamation']}
                                            fontSize={15}
                                            fixedWidth
                                            color={applyStyleByMode({
                                                theme: ThemeMode?.mode,
                                                light: SECONDARY_DARK_MODE,
                                                dark: WHITE
                                            })}
                                            transform='left-3'
                                        />
                                        <div>{translate('t.system_alerts')}</div>
                                    </UiBadge>
                                </IconLabel>
                            }
                            $hide={props.displayTab(props.isVehicleAlertsWidget, true)}
                            $badge={true}
                            data-testid='VehicleStatus-SystemAlertsTab-Button'
                        />
                        <AlertsTab
                            label={
                                <IconLabel>
                                    <UiBadge badgeContent={`${props.HistoricalLog}`} $color='#a90329'>
                                        <UiIcon
                                            icon={['fas', 'book']}
                                            fontSize={17}
                                            fixedWidth
                                            transform='up-0 down-1 left-3'
                                        />
                                        {translate('t.logs')}
                                    </UiBadge>
                                </IconLabel>
                            }
                            $hide={props.displayTab(props.isVehicleAlertsWidget, true)}
                            $badge={true}
                            data-testid='VehicleStatus-LogsTab-Button'
                        />
                        <AlertsTab
                            label={
                                <IconLabel>
                                    <UiIcon
                                        icon={['fas', 'gear']}
                                        fontSize={17}
                                        fixedWidth
                                        color={applyStyleByMode({
                                            theme: ThemeMode?.mode,
                                            light: '#0000008a',
                                            dark: WHITE
                                        })}
                                        transform='up-1 down-0 left-3'
                                    />
                                    <div>{translate('t.settings')}</div>
                                </IconLabel>
                            }
                            $hide={!isUserAdmin || props.displayTab(props.isVehicleAlertsWidget, false)}
                            $badge={true}
                            data-testid='VehicleStatus-SettingsTab-Button'
                        />
                    </VehicleDetailsTabs>
                    <TabContent value={activeTab} index={0} testId='VehicleDetailsTyres'>
                        <UiScroll maxHeight='67.7vh'>
                            <VehicleDetailsTyres />
                        </UiScroll>
                    </TabContent>
                    <TabContent value={activeTab} index={1} testId='VehicleDetailsSummary'>
                        <UiScroll maxHeight='67.7vh'>
                            <VehicleDetailsSummary />
                        </UiScroll>
                    </TabContent>
                    <TabContent
                        value={activeTab}
                        index={2}
                        renderBeforeActive={!isDetailsOnly || props.isVehicleAlertsWidget}
                        testId='VehicleDetailsTPMS'
                    >
                        <LazyLoadingAlertsList
                            queryId='_alertTPMS'
                            limit={PAGE_COUNT}
                            solved={false}
                            alertType={typeAlert.TPMS}
                            vehicleId={(vehicleDetailsWidgetAtom.vehicleDetails as VehicleModel)?.id}
                            changeNumberOfAlerts={props.changeNumberOfAlerts}
                            tabNumber={0}
                            displayAlertActions={true}
                            showSolveButton={true}
                            showValidateButton={true}
                            tab='tpms'
                            maxHeight={420}
                        />
                    </TabContent>

                    <TabContent
                        value={activeTab}
                        index={3}
                        renderBeforeActive={
                            (!isDetailsOnly || props.isVehicleAlertsWidget) && customerSettings.vehicle_alerts_enabled
                        }
                        testId='VehicleDetailsVehicleAlerts'
                    >
                        <LazyLoadingAlertsList
                            queryId='_alertVehicleAlerts'
                            limit={PAGE_COUNT}
                            solved={false}
                            alertType={typeAlert.VehicleEvent}
                            vehicleId={(vehicleDetailsWidgetAtom.vehicleDetails as VehicleModel)?.id}
                            changeNumberOfAlerts={props.changeNumberOfAlerts}
                            tabNumber={1}
                            displayAlertActions={false}
                            showSolveButton={false}
                            showValidateButton={false}
                            maxHeight={420}
                        />
                    </TabContent>

                    <TabContent
                        value={activeTab}
                        index={4}
                        renderBeforeActive={!isDetailsOnly || props.isVehicleAlertsWidget}
                        testId='VehicleDetailsGpsAlerts'
                    >
                        <GpsLastRecord>
                            {(vehicleDetailsWidgetAtom.vehicleDetails as VehicleModel)?.device ? (
                                (vehicleDetailsWidgetAtom.vehicleDetails as VehicleModel)?.vehicleInfo
                                    .lastGpsMeasuredAt && (
                                    <>
                                        <UiIcon icon={['fas', 'rss']} />
                                        <DeviceText data-testid='GpsRecodedAt-VehicleDetails'>
                                            {translate('t.gps_recorded_at')}:
                                        </DeviceText>
                                        <LastGpsMeasuredAt>
                                            {fromUTCToTimezone(
                                                (vehicleDetailsWidgetAtom.vehicleDetails as VehicleModel).vehicleInfo
                                                    .lastGpsMeasuredAt,
                                                false
                                            )}
                                        </LastGpsMeasuredAt>
                                    </>
                                )
                            ) : (
                                <>
                                    <UiIcon icon={['fas', 'window-close']} />
                                    <DeviceText>{translate('t.no_hub_attached')}</DeviceText>
                                </>
                            )}
                        </GpsLastRecord>
                        <LazyLoadingAlertsList
                            queryId='_alertGPS'
                            limit={PAGE_COUNT}
                            alertType={typeAlert.GPS}
                            vehicleId={(vehicleDetailsWidgetAtom.vehicleDetails as VehicleModel)?.id}
                            changeNumberOfAlerts={props.changeNumberOfAlerts}
                            tabNumber={2}
                            displayAlertActions={false}
                            maxHeight={394}
                        />
                    </TabContent>
                    <TabContent
                        value={activeTab}
                        index={5}
                        renderBeforeActive={!isDetailsOnly || props.isVehicleAlertsWidget}
                        testId='VehicleDetailsSystemAlerts'
                    >
                        <LazyLoadingAlertsList
                            queryId='_alertSensor'
                            limit={PAGE_COUNT}
                            alertType={typeAlert.Sensor}
                            vehicleId={(vehicleDetailsWidgetAtom.vehicleDetails as VehicleModel)?.id}
                            changeNumberOfAlerts={props.changeNumberOfAlerts}
                            tabNumber={1}
                            displayAlertActions={false}
                            tab='system'
                            solved={false}
                            maxHeight={420}
                        />
                    </TabContent>
                    <TabContent
                        value={activeTab}
                        index={6}
                        renderBeforeActive={!isDetailsOnly || props.isVehicleAlertsWidget}
                        testId='VehicleDetailsLogAlerts'
                    >
                        <LazyLoadingAlertsList
                            queryId='_alertHistoryLog'
                            limit={PAGE_COUNT}
                            alertType={typeAlert.HistoricalLog}
                            vehicleId={(vehicleDetailsWidgetAtom.vehicleDetails as VehicleModel)?.id}
                            changeNumberOfAlerts={props.changeNumberOfAlerts}
                            tabNumber={3}
                            displayAlertActions={false}
                            solved={true}
                            tab='log'
                            maxHeight={420}
                        />
                    </TabContent>

                    <TabContent value={activeTab} index={7} testId='VehicleDetailsSettings'>
                        <VehicleDetailsSettings
                            vehicleDetails={vehicleDetailsWidgetAtom.vehicleDetails as VehicleModel}
                            vehicleId={(vehicleDetailsWidgetAtom.vehicleDetails as VehicleModel)?.id}
                        />
                    </TabContent>
                </>
            )}
        </VehicleDetailsViewContent>
    );
};

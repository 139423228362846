import { Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import logo from 'assets/images/site.png';
import Button from 'components/Ui/Components/UiButton';
import { useTranslation } from 'react-i18next';
import { DARK_STEEL_GREY } from 'components/Ui/colors';
import { ErrorPageProps } from './ErrorPage.type';
import { ErrorPageContainer, ErrorTextBox, LogoBox } from './ErrorPage.style';

const ErrorPageContent: React.FC<ErrorPageProps> = (props): JSX.Element => {
    const navigate = useNavigate();
    const { t: translate } = useTranslation();
    // consider to change displaying messages
    const msg = {
        401: translate('t.not_permission'),
        404: translate('t.not_found')
    };

    return (
        <ErrorPageContainer data-testid='ErrorPageContent'>
            <ErrorTextBox>
                <Typography variant='h1' gutterBottom sx={{ color: DARK_STEEL_GREY }}>
                    {translate('t.sorry')}.
                </Typography>
                <Typography variant='h2' gutterBottom>
                    {translate('t.page')} {props.code} {msg[props.code] || ''}
                </Typography>
                <Button color='primary' variant='contained' onClick={() => navigate('/')}>
                    {translate('t.go_back')}
                </Button>
            </ErrorTextBox>
            <LogoBox>
                <img src={logo} className='logo-login' alt='logo' />
            </LogoBox>
        </ErrorPageContainer>
    );
};

export default ErrorPageContent;

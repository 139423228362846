import React, { useMemo } from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { FormikType, DefaultFormikValues } from './VehicleActions.type';
import { DropdownModel } from 'models/VehicleGroup.type';
import { FormControlLabel, Grid, Switch, TextField, InputAdornment, Alert } from '@mui/material';
import {
    CustomAutocomplete,
    CustomBox,
    TempPredTextfield,
    CustomTextfield,
    FormikSectionContainer,
    FormikSectionHeader,
    HubBayContainer,
    HubBayIconLock,
    LabelContainer,
    SingleVehicleConfig,
    VehicleLayouContent,
    VehicleLayoutCover,
    VehicleLayoutLabel
} from './VehicleActions.style';
import { useRecoilValue } from 'recoil';
import { VehicleActionAtom } from 'states/component/Vehicle';
import { CRUD } from 'variables';
import UiIconPicker from 'components/Ui/Components/UiIconPicker/UiIconPicker';
import { VehicleModelModel } from 'models/VehicleModel.type';
import { useTranslation } from 'react-i18next';
import Schema from '../NewVehicleSchema';
import { UserInfo } from 'states/global/User';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { checkRole } from 'helpers/authentication';
import { ROLES } from 'variables';
import { DeviceModel } from 'models/Device.type';
import { Manufacturer } from 'models/VehicleManufacturer.type';
import { CustomerSettings } from 'states/global/CustomerSettings';
import { faLock } from '@fortawesome/pro-solid-svg-icons';
import { applyStyleByMode, isVehicleTyreBayHub } from 'helpers';
import { WHITE } from 'components/Ui/colors';
import { Theme } from 'states/global/Theme';
import { getImgSource } from 'helpers/image';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';
import VehicleActionsInfoTooltip from './VehicleActionsInfoTooltip';
import { RimSizeModel } from 'models/RimSize.type';

export const VehicleActionsFormik: React.FC<FormikType> = (props): JSX.Element => {
    const vehicleActionAtom = useRecoilValue(VehicleActionAtom);
    const basicColors: string[] = ['Darkgrey', 'White', 'Red', 'Blue', 'Yellow'];
    const { t: translate } = useTranslation();
    const manufacturerOptions = props.getManufacturerOptions();
    const userSettings = useRecoilValue(UserInfo);
    const { displayUserUnits, convertType, fromServerToUserUnit } = useConverter();
    const userInfo = useRecoilValue(UserInfo);
    const isSuperAdmin = useMemo(() => checkRole(userInfo.user, [ROLES.ROLE_SUPER_ADMIN]), [userInfo]);
    const customerSettings = useRecoilValue(CustomerSettings);
    const ThemeMode = useRecoilValue(Theme);
    const showTemperatureSettings = useMemo(() => {
        return (
            checkRole(userInfo.user, [ROLES.ROLE_SUPER_ADMIN, ROLES.ROLE_ADMIN, ROLES.ROLE_TECHNICAL_ADMIN]) &&
            customerSettings.temperature_prediction
        );
    }, [userInfo, customerSettings.temperature_prediction]);

    const formValidationSchema = Yup.object().shape({
        name: Yup.string().min(2, 'Too Short!').required(translate('t.required')),
        odometer: Yup.number().required(translate('t.required')),
        model: Yup.mixed()
            .notRequired()
            .when('manufacturer.id', {
                is: (val) => typeof val === 'number',
                then: Yup.object()
                    .shape({
                        id: Yup.number(),
                        modelName: Yup.string(),
                        vehicleConfigurationId: Yup.string(),
                        vehicleManufacturerId: Yup.string(),
                        vehicleGroupId: Yup.string()
                    })
                    .required(translate('t.required')),
                otherwise: Yup.mixed().notRequired()
            }),
        hub: Yup.mixed()
            .notRequired()
            .when('model.id', {
                is: 9,
                then: Yup.mixed().required(translate('t.required')),
                otherwise: Yup.mixed().notRequired()
            }),
        externalSystems:
            isSuperAdmin && (vehicleActionAtom.action === CRUD.EDIT || vehicleActionAtom.action === CRUD.CLONE)
                ? Yup.array().of(
                      Yup.object().shape({
                          externalSystemId: Yup.number(),
                          externalSystemName: Yup.string(),
                          value: Yup.string().notRequired()
                      })
                  )
                : Yup.array(),
        predictionTemperatureCriticalThreshold: Yup.number()
            .required(translate('t.required'))
            .test('decimal-places', translate('t.is_not_in_correct_format'), (value) => {
                if (value === undefined) {
                    return false;
                }
                return /^\d+(\.\d{0,2})?$/.test(value.toString());
            }),
        predictionTemperatureThresholdLevel0: Yup.number()
            .required(translate('t.required'))
            .test('decimal-places', translate('t.is_not_in_correct_format'), (value) => {
                if (value === undefined) {
                    return false;
                }
                return /^\d+(\.\d{0,2})?$/.test(value.toString());
            }),
        predictionTemperatureThresholdLevel1: Yup.number()
            .required(translate('t.required'))
            .test('decimal-places', translate('t.is_not_in_correct_format'), (value) => {
                if (value === undefined) {
                    return false;
                }
                return /^\d+(\.\d{0,2})?$/.test(value.toString());
            }),
        predictionTemperatureThresholdLevel2: Yup.number()
            .required(translate('t.required'))
            .test('decimal-places', translate('t.is_not_in_correct_format'), (value) => {
                if (value === undefined) {
                    return false;
                }
                return /^\d+(\.\d{0,2})?$/.test(value.toString());
            })
    });

    const GenerateFormikSections = ({ children, title, noBorderBottom = false, hide = false }): JSX.Element => (
        <FormikSectionContainer noBorderBottom={noBorderBottom} hide={hide}>
            <FormikSectionHeader title={title} />

            <Grid container padding={1} spacing={2}>
                {children}
            </Grid>
        </FormikSectionContainer>
    );

    return (
        <Formik
            initialValues={props.defaultFormikValues}
            validationSchema={formValidationSchema}
            enableReinitialize
            onSubmit={(values) => {
                props.onSubmit(values);
            }}
        >
            {(formikProps) => {
                const vehicleTyreBayHubVerified =
                    isVehicleTyreBayHub(formikProps.values.model.id) &&
                    formikProps.values.model.vehicleManufacturerId === 11;
                return (
                    <Form id='vehicle-action-form'>
                        <GenerateFormikSections title={translate('t.basic_vehicle_information')}>
                            <Grid item xs={12} md={5} lg={5}>
                                <CustomTextfield
                                    data-testid='vehicle-name'
                                    id='vehicle-name'
                                    name='name'
                                    type='text'
                                    error={
                                        (!!formikProps.errors.name && formikProps.touched.name) || props.isNameUnique
                                    }
                                    label={translate('t.name')}
                                    value={formikProps.values.name}
                                    onBlur={() => {
                                        !props.hasOnlySpaces(formikProps.values.name) &&
                                            props.checkUniquenessOfVehicleName(formikProps.values.name);
                                    }}
                                    variant='outlined'
                                    size='small'
                                    fullWidth
                                    helperText={
                                        props.isNameUnique
                                            ? translate('p.vehicle_name_exist')
                                            : formikProps.errors.name && formikProps.touched.name
                                            ? formikProps.errors.name
                                            : ''
                                    }
                                    onChange={formikProps.handleChange}
                                    inputProps={{
                                        form: {
                                            autocomplete: 'off'
                                        }
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />

                                {!vehicleTyreBayHubVerified && (
                                    <UiIconPicker
                                        defaultIcon={`${formikProps.values.icon}`}
                                        data={[
                                            {
                                                group: 'mining/48x48/adt1_Yellow.png',
                                                colors: basicColors
                                            },
                                            {
                                                group: 'mining/48x48/adt2_Yellow.png',
                                                colors: basicColors
                                            },
                                            {
                                                group: 'mining/48x48/adt_Yellow.png',
                                                colors: basicColors
                                            },
                                            {
                                                group: 'mining/48x48/dumptruck_Yellow.png',
                                                colors: basicColors.concat(['Pink', 'Rainbow'])
                                            },
                                            {
                                                group: 'mining/48x48/grader_Yellow.png',
                                                colors: basicColors
                                            },
                                            {
                                                group: 'mining/48x48/loader_Yellow.png',
                                                colors: basicColors
                                            },
                                            {
                                                group: 'mining/48x48/undeground_Yellow.png',
                                                colors: basicColors
                                            }
                                        ]}
                                        style={{
                                            margin: '8px',
                                            marginLeft: 0
                                        }}
                                        onIconSelect={(icon) => {
                                            formikProps.setFieldValue('icon', icon, false);
                                        }}
                                    />
                                )}
                                {!vehicleTyreBayHubVerified && (
                                    <>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <CustomAutocomplete
                                                    disableClearable
                                                    data-testid='vehicle-type-select'
                                                    id='vehicle-group-select'
                                                    size='small'
                                                    value={formikProps.values.vehicleGroup}
                                                    options={props.vehicleGroups ?? []}
                                                    getOptionLabel={(option) => (option as DropdownModel).name ?? ''}
                                                    onChange={(event, newValue) => {
                                                        formikProps.setFieldValue('vehicleGroup', newValue, false);
                                                        formikProps.setFieldValue(
                                                            'maxSpeed',
                                                            (newValue as DefaultFormikValues).maxSpeed,
                                                            false
                                                        );
                                                        formikProps.setFieldValue(
                                                            'icon',
                                                            `${(newValue as DefaultFormikValues).icon}`,
                                                            false
                                                        );
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label={translate('t.vehicle_group')}
                                                            variant='outlined'
                                                            type='text'
                                                            style={{
                                                                marginTop: 8,
                                                                marginBottom: 8
                                                            }}
                                                            size='small'
                                                            key={params.id}
                                                            name={params.id}
                                                            InputLabelProps={{
                                                                shrink: true
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                    </>
                                )}
                            </Grid>

                            <Grid item xs={12} md={4} lg={4}>
                                <CustomAutocomplete
                                    disableClearable
                                    id='vehicle-manufacturer-select'
                                    data-testid='vehicle-manufacturer-select'
                                    size='small'
                                    style={{
                                        marginBottom: '8px'
                                    }}
                                    value={formikProps.values.manufacturer}
                                    isOptionEqualToValue={(option, value) => {
                                        return (option as Manufacturer).id === (value as Manufacturer).id;
                                    }}
                                    options={
                                        props.vehicleManufacturerData
                                            ?.filter(
                                                (option) =>
                                                    manufacturerOptions.includes(option.id) ||
                                                    vehicleActionAtom.action === CRUD.ADD
                                            )
                                            ?.sort((a, b) =>
                                                a.manufacturer > b.manufacturer
                                                    ? 1
                                                    : b.manufacturer > a.manufacturer
                                                    ? -1
                                                    : 0
                                            ) ?? []
                                    }
                                    getOptionLabel={(option) => (option as { manufacturer: string }).manufacturer ?? ''}
                                    onChange={(event, newValue) => {
                                        if (props.vehicleModelData) {
                                            formikProps.setFieldValue(
                                                'model',
                                                props.vehicleModelData.codeBook.filter(
                                                    (model: VehicleModelModel) =>
                                                        model.vehicleManufacturerId ===
                                                            (newValue as { id: number }).id &&
                                                        (vehicleActionAtom.action !== CRUD.ADD
                                                            ? model?.vehicleConfigurationId ===
                                                              formikProps.values.model.vehicleConfigurationId
                                                            : true)
                                                )[0] ?? [],
                                                false
                                            );
                                        }
                                        formikProps.setFieldValue('manufacturer', newValue, false);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={translate('t.manufacturer')}
                                            variant='outlined'
                                            type='text'
                                            size='small'
                                            key={params.id}
                                            name={params.id}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                        />
                                    )}
                                />
                                <CustomAutocomplete
                                    disableClearable
                                    id='vehicle-model-select'
                                    data-testid='vehicle-model-select'
                                    size='small'
                                    value={formikProps.values.model}
                                    style={{
                                        marginBottom: '8px'
                                    }}
                                    options={
                                        props.vehicleModelData?.codeBook
                                            ? props.vehicleModelData?.codeBook
                                                  .filter(
                                                      (model: VehicleModelModel) =>
                                                          model.vehicleManufacturerId ===
                                                              formikProps.values.manufacturer.id &&
                                                          (vehicleActionAtom.action === CRUD.ADD ||
                                                              model.vehicleConfigurationId ===
                                                                  formikProps.values.model.vehicleConfigurationId)
                                                  )
                                                  .sort((a, b) =>
                                                      a.modelName > b.modelName ? 1 : b.modelName > a.modelName ? -1 : 0
                                                  ) ?? []
                                            : []
                                    }
                                    getOptionLabel={(option) => (option as VehicleModelModel).modelName ?? ''}
                                    onChange={(event, newValue) => {
                                        formikProps.setFieldValue('model', newValue, false);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={translate('t.model')}
                                            variant='outlined'
                                            type='text'
                                            size='small'
                                            key={params.id}
                                            name={params.id}
                                            error={!!formikProps.errors.model && !!formikProps.touched.model}
                                            helperText={
                                                !!formikProps.errors.model && !!formikProps.touched.model
                                                    ? translate('t.required')
                                                    : ''
                                            }
                                        />
                                    )}
                                />
                                {!vehicleTyreBayHubVerified && (
                                    <>
                                        <CustomTextfield
                                            data-testid='model-rim-size'
                                            id='model-rim-size'
                                            error={!!formikProps.errors.model && !!formikProps.touched.model}
                                            label={translate('t.model_rim_size')}
                                            value={formikProps.values.model.rimSize?.diameter}
                                            variant='outlined'
                                            type='text'
                                            disabled
                                            size='small'
                                            fullWidth
                                            inputProps={{
                                                form: {
                                                    autocomplete: 'off'
                                                }
                                            }}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                        />
                                        <CustomAutocomplete
                                            disableClearable
                                            id='rim-size-select'
                                            data-testid='rim-size-select'
                                            size='small'
                                            value={
                                                formikProps.values.customRimSize || {
                                                    id: 0,
                                                    diameter: translate('t.model_rim_size')
                                                }
                                            }
                                            options={[
                                                { id: 0, diameter: translate('t.none') },
                                                ...(props.rimSizeData || [])
                                            ].filter((a) => a.id !== formikProps.values.model.rimSize?.id)}
                                            isOptionEqualToValue={(a, b) => {
                                                return (a as RimSizeModel).id === (b as RimSizeModel).id;
                                            }}
                                            getOptionLabel={(option) => {
                                                if ((option as RimSizeModel).id === 0) {
                                                    return translate('t.none');
                                                }
                                                return (option as RimSizeModel).diameter ?? '';
                                            }}
                                            onChange={(event, newValue) => {
                                                formikProps.setFieldValue('customRimSize', newValue, false);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={translate('t.custom_rim_size')}
                                                    variant='outlined'
                                                    type='text'
                                                    size='small'
                                                    key={params.id}
                                                    name={params.id}
                                                    error={
                                                        !!formikProps.errors.customRimSize &&
                                                        !!formikProps.touched.customRimSize
                                                    }
                                                    helperText={
                                                        !!formikProps.errors.customRimSize &&
                                                        !!formikProps.touched.customRimSize
                                                            ? translate('t.required')
                                                            : ''
                                                    }
                                                />
                                            )}
                                        />
                                    </>
                                )}

                                {vehicleTyreBayHubVerified && (
                                    <HubBayContainer>
                                        <Grid
                                            container
                                            alignContent='center'
                                            alignItems='center'
                                            justifyContent='center'
                                        >
                                            <Grid md={8}>
                                                <img
                                                    width={40}
                                                    alt='img'
                                                    src={getImgSource('hub/48x48/tyre_bay_hub.png')}
                                                />
                                            </Grid>
                                            <Grid md={4}>
                                                <HubBayIconLock>
                                                    <UiIcon
                                                        size='1x'
                                                        icon={faLock}
                                                        color={applyStyleByMode({
                                                            theme: ThemeMode?.mode,
                                                            light: '#0000008a',
                                                            dark: WHITE
                                                        })}
                                                    />
                                                </HubBayIconLock>
                                            </Grid>
                                        </Grid>
                                    </HubBayContainer>
                                )}
                            </Grid>
                            <Grid item xs={12} md={2} lg={2}>
                                {!vehicleTyreBayHubVerified && (
                                    <Grid container paddingLeft={1}>
                                        <Grid item display={'inline-flex'}>
                                            <VehicleLayouContent>
                                                <VehicleLayoutCover>
                                                    <VehicleLayoutLabel>
                                                        {translate('t.vehicle_configuration')}
                                                    </VehicleLayoutLabel>
                                                    <SingleVehicleConfig>
                                                        <Schema
                                                            emptySchema={true}
                                                            schema={
                                                                props.getVehicleConfiguration(
                                                                    formikProps.values.model.vehicleConfigurationId
                                                                ) || '2-2'
                                                            }
                                                        />
                                                    </SingleVehicleConfig>
                                                </VehicleLayoutCover>
                                            </VehicleLayouContent>
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                        </GenerateFormikSections>

                        <GenerateFormikSections title={translate('t.aditional_vehicle_information')}>
                            <Grid item xs={12} md={5} lg={5}>
                                {props.availableDevices != null && (
                                    <CustomAutocomplete
                                        data-testid='available-hubs-select'
                                        id='available-hubs-select'
                                        size='small'
                                        style={{ marginBottom: 8 }}
                                        options={props.availableDevices?.codeBook ?? []}
                                        value={formikProps.values.hub}
                                        getOptionLabel={(option) => (option as DeviceModel).serialNumber}
                                        onChange={(event, newValue) => {
                                            formikProps.setFieldValue('hub', newValue, false);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={translate('t.hub')}
                                                variant='outlined'
                                                size='small'
                                                type='text'
                                                key={params.id}
                                                name={params.id}
                                                error={!!formikProps.errors.hub && formikProps.touched.hub}
                                                helperText={
                                                    formikProps.errors.hub && formikProps.touched.hub
                                                        ? formikProps.errors.hub
                                                        : ''
                                                }
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                        )}
                                    />
                                )}

                                {!vehicleTyreBayHubVerified && (
                                    <>
                                        <CustomTextfield
                                            data-testid='vehicle-odometer'
                                            id='vehicle-odometer'
                                            label={translate('t.odometer')}
                                            value={formikProps.values.odometer}
                                            variant='outlined'
                                            size='small'
                                            name='odometer'
                                            error={!!formikProps.errors.odometer && !!formikProps.touched.odometer}
                                            type='number'
                                            fullWidth
                                            onChange={formikProps.handleChange}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position='end'>
                                                        {(userSettings &&
                                                            userSettings?.user?.userSetting?.distanceUnit) ||
                                                            displayUserUnits.speed}
                                                    </InputAdornment>
                                                )
                                            }}
                                            inputProps={{
                                                step: 0.1,
                                                min: 0
                                            }}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            helperText={formikProps.errors.odometer}
                                        />
                                    </>
                                )}
                            </Grid>

                            <Grid item xs={12} md={7} lg={7}>
                                <CustomTextfield
                                    data-testid='vehicle-comment'
                                    id='vehicle-comment'
                                    label={translate('t.comment')}
                                    name='comment'
                                    type='text'
                                    multiline
                                    rows={3}
                                    value={formikProps.values.comment}
                                    onChange={formikProps.handleChange}
                                    variant='outlined'
                                    size='small'
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            </Grid>
                        </GenerateFormikSections>

                        <GenerateFormikSections
                            title={translate('t.speed_configuration')}
                            noBorderBottom={showTemperatureSettings === false && vehicleActionAtom.action === CRUD.ADD}
                        >
                            <Grid item xs={6} sm={6} md={6} lg={6}>
                                {vehicleActionAtom.action === CRUD.EDIT && (
                                    <div style={{ marginBottom: 6 }}>
                                        <FormControlLabel
                                            value={true}
                                            style={{ marginLeft: 0 }}
                                            control={
                                                <Switch
                                                    data-testid='apply-max-speed-switch'
                                                    color='primary'
                                                    checked={formikProps.values.inheritMaxSpeed}
                                                    value={formikProps.values.inheritMaxSpeed}
                                                    name='showTargetPressure'
                                                />
                                            }
                                            onChange={(e, value) => {
                                                formikProps.setFieldValue('inheritMaxSpeed', value, false);
                                                if (value) {
                                                    formikProps.setFieldValue(
                                                        'maxSpeed',
                                                        formikProps.values.vehicleGroup?.maxSpeed
                                                    );
                                                }
                                            }}
                                            label={translate('t.apply_maximum_speed')}
                                            labelPlacement='start'
                                        />

                                        {formikProps.values.inheritMaxSpeed && (
                                            <Alert severity='info' style={{ fontSize: 12, marginBottom: 12 }}>
                                                {' '}
                                                {translate('p.inherit_speed_info')}
                                            </Alert>
                                        )}
                                    </div>
                                )}
                                {!vehicleTyreBayHubVerified && (
                                    <CustomTextfield
                                        data-testid='vehicle-max-speed'
                                        id='vehicle-max-speed'
                                        label={translate('t.max_speed')}
                                        value={formikProps.values.maxSpeed}
                                        variant='outlined'
                                        size='small'
                                        name='maxSpeed'
                                        type='number'
                                        fullWidth
                                        disabled={formikProps.values.inheritMaxSpeed}
                                        onChange={formikProps.handleChange}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    {(userSettings && userSettings?.user?.userSetting?.speedUnit) ||
                                                        displayUserUnits.speed}
                                                </InputAdornment>
                                            )
                                        }}
                                        inputProps={{
                                            step: 0.1,
                                            min: 0
                                        }}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                    />
                                )}
                            </Grid>
                            {vehicleActionAtom.action === CRUD.EDIT && (
                                <Grid item xs={6} sm={6} md={6} lg={6}>
                                    <LabelContainer>
                                        <FormControlLabel
                                            value={formikProps.values.enableTemperatureCheckbox}
                                            style={{ marginLeft: 0 }}
                                            control={
                                                <Switch
                                                    data-testid='out-of-service-switch'
                                                    color='primary'
                                                    checked={formikProps.values.enableTemperatureCheckbox}
                                                    value={formikProps.values.enableTemperatureCheckbox}
                                                    name='showTargetPressure'
                                                />
                                            }
                                            onChange={(e, value) => {
                                                formikProps.setFieldValue('enableTemperatureCheckbox', value, false);
                                            }}
                                            label={translate('t.temperature_on_speed_alerts')}
                                            labelPlacement='start'
                                        />
                                        <VehicleActionsInfoTooltip
                                            textInfo={translate('p.max_speed_for_temperature')}
                                            testid={'temp-speed-alerts-value-info'}
                                        />
                                    </LabelContainer>

                                    <Grid container spacing={1}>
                                        <Grid item xs={4} sm={4} md={4} lg={4}>
                                            <CustomTextfield
                                                data-testid='max-speed-temperature-1'
                                                id='maxSpeedTemperatureL1'
                                                name='maxSpeedTemperatureL1'
                                                error={
                                                    !!formikProps.errors.maxSpeedTemperatureL1 &&
                                                    formikProps.touched.maxSpeedTemperatureL1
                                                }
                                                label={`${translate('t.level')} 1`}
                                                variant='outlined'
                                                size='small'
                                                fullWidth
                                                type='number'
                                                defaultValue={0}
                                                value={formikProps.values.maxSpeedTemperatureL1}
                                                disabled={
                                                    !formikProps.values.enableTemperatureCheckbox ||
                                                    formikProps.values.inheritMaxSpeed
                                                }
                                                helperText={
                                                    formikProps.errors.maxSpeedTemperatureL1 &&
                                                    formikProps.touched.maxSpeedTemperatureL1
                                                        ? formikProps.errors.maxSpeedTemperatureL1
                                                        : ''
                                                }
                                                onChange={formikProps.handleChange}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position='end'>
                                                            {(userSettings &&
                                                                userSettings?.user?.userSetting?.speedUnit) ||
                                                                displayUserUnits.speed}
                                                        </InputAdornment>
                                                    )
                                                }}
                                                inputProps={{
                                                    form: {
                                                        autocomplete: 'off'
                                                    },
                                                    step: 0.1,
                                                    min: 0
                                                }}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={4} sm={4} md={4} lg={4}>
                                            <CustomTextfield
                                                data-testid='max-speed-temperature-2'
                                                id='maxSpeedTemperatureL2'
                                                name='maxSpeedTemperatureL2'
                                                error={
                                                    !!formikProps.errors.maxSpeedTemperatureL2 &&
                                                    formikProps.touched.maxSpeedTemperatureL2
                                                }
                                                label={`${translate('t.level')} 2`}
                                                variant='outlined'
                                                size='small'
                                                defaultValue={0}
                                                value={formikProps.values.maxSpeedTemperatureL2}
                                                disabled={
                                                    !formikProps.values.enableTemperatureCheckbox ||
                                                    formikProps.values.inheritMaxSpeed
                                                }
                                                fullWidth
                                                type='number'
                                                helperText={
                                                    formikProps.errors.maxSpeedTemperatureL2 &&
                                                    formikProps.touched.maxSpeedTemperatureL2
                                                        ? formikProps.errors.maxSpeedTemperatureL2
                                                        : ''
                                                }
                                                onChange={formikProps.handleChange}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position='end'>
                                                            {(userSettings &&
                                                                userSettings?.user?.userSetting?.speedUnit) ||
                                                                displayUserUnits.speed}
                                                        </InputAdornment>
                                                    )
                                                }}
                                                inputProps={{
                                                    form: {
                                                        autocomplete: 'off'
                                                    },
                                                    step: 0.1,
                                                    min: 0
                                                }}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={4} sm={4} md={4} lg={4}>
                                            <CustomTextfield
                                                data-testid='max-speed-temperature-3'
                                                id='maxSpeedTemperatureL3'
                                                name='maxSpeedTemperatureL3'
                                                error={
                                                    !!formikProps.errors.maxSpeedTemperatureL3 &&
                                                    formikProps.touched.maxSpeedTemperatureL3
                                                }
                                                label={`${translate('t.level')} 3`}
                                                variant='outlined'
                                                size='small'
                                                defaultValue={0}
                                                value={formikProps.values.maxSpeedTemperatureL3}
                                                disabled={
                                                    !formikProps.values.enableTemperatureCheckbox ||
                                                    formikProps.values.inheritMaxSpeed
                                                }
                                                fullWidth
                                                type='number'
                                                helperText={
                                                    formikProps.errors.maxSpeedTemperatureL3 &&
                                                    formikProps.touched.maxSpeedTemperatureL3
                                                        ? formikProps.errors.maxSpeedTemperatureL3
                                                        : ''
                                                }
                                                onChange={formikProps.handleChange}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position='end'>
                                                            {(userSettings &&
                                                                userSettings?.user?.userSetting?.speedUnit) ||
                                                                displayUserUnits.speed}
                                                        </InputAdornment>
                                                    )
                                                }}
                                                inputProps={{
                                                    form: {
                                                        autocomplete: 'off'
                                                    },
                                                    step: 0.1,
                                                    min: 0
                                                }}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                        </GenerateFormikSections>

                        <GenerateFormikSections
                            hide={showTemperatureSettings === false && vehicleActionAtom.action === CRUD.ADD}
                            title={translate('t.aditional_vehicle_configuration')}
                            noBorderBottom
                        >
                            {showTemperatureSettings && (
                                <Grid
                                    item
                                    md={customerSettings.temperature_prediction_workflow === 0 ? 8 : 12}
                                    lg={customerSettings.temperature_prediction_workflow === 0 ? 8 : 12}
                                >
                                    <CustomBox $height={'120px'}>
                                        <VehicleLayoutLabel>{translate('t.temperature_settings')}</VehicleLayoutLabel>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    data-testid='predicted-alert-switch'
                                                    color='primary'
                                                    checked={formikProps.values.predictionTemperature}
                                                    value={formikProps.values.predictionTemperature}
                                                    name='showTargetPressure'
                                                />
                                            }
                                            value={formikProps.values.predictionTemperature}
                                            onChange={(e, value) => {
                                                if (!value) {
                                                    formikProps.setFieldValue('genericTemperatureAlert', true, false);
                                                }
                                                formikProps.setFieldValue('predictionTemperature', value, false);
                                            }}
                                            label={translate('t.predicted_alert')}
                                            labelPlacement='start'
                                            disabled={!isSuperAdmin}
                                        />
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    data-testid='generic-alert-switch'
                                                    color='primary'
                                                    checked={formikProps.values.genericTemperatureAlert}
                                                    value={formikProps.values.genericTemperatureAlert}
                                                    name='showTargetPressure'
                                                    disabled={
                                                        !formikProps.values.predictionTemperature &&
                                                        formikProps.values.genericTemperatureAlert
                                                    }
                                                />
                                            }
                                            value={formikProps.values.genericTemperatureAlert}
                                            onChange={(e, value) => {
                                                formikProps.setFieldValue('genericTemperatureAlert', value, false);
                                            }}
                                            label={translate('t.standart_alert')}
                                            labelPlacement='start'
                                        />
                                        <Grid container md={12} lg={12}>
                                            {customerSettings.temperature_prediction_workflow === 0 && (
                                                <Grid item md={12} lg={12} display={'inline-flex'}>
                                                    <TempPredTextfield
                                                        data-testid='threshold-field'
                                                        id='predictionTemperatureCriticalThreshold'
                                                        name='predictionTemperatureCriticalThreshold'
                                                        error={
                                                            !!formikProps.errors.predictionTemperatureCriticalThreshold
                                                        }
                                                        title={translate('t.safety_margin')}
                                                        label={translate('t.safety_margin')}
                                                        variant='outlined'
                                                        size='small'
                                                        fullWidth
                                                        type='number'
                                                        defaultValue={
                                                            formikProps.values.predictionTemperatureCriticalThreshold
                                                        }
                                                        value={fromServerToUserUnit({
                                                            type: convertType.temperature,
                                                            value: formikProps.values
                                                                .predictionTemperatureCriticalThreshold,
                                                            fixed: 1
                                                        })}
                                                        disabled={
                                                            !formikProps.values.predictionTemperature || !isSuperAdmin
                                                        }
                                                        helperText={
                                                            formikProps.errors.predictionTemperatureCriticalThreshold
                                                                ? formikProps.errors
                                                                      .predictionTemperatureCriticalThreshold
                                                                : ''
                                                        }
                                                        onChange={formikProps.handleChange}
                                                        onBlur={formikProps.handleBlur}
                                                        inputProps={{
                                                            form: {
                                                                autocomplete: 'off'
                                                            },
                                                            step: 0.01,
                                                            min: 0
                                                        }}
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position='end'>
                                                                    {displayUserUnits.temperature}
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                    />
                                                </Grid>
                                            )}

                                            {customerSettings.temperature_prediction_workflow === 1 && (
                                                <>
                                                    <Grid item md={4} lg={4} display={'inline-flex'}>
                                                        <TempPredTextfield
                                                            title={`${translate('t.safety_margin')} 0 (${translate(
                                                                't.trigger_calculation'
                                                            )})`}
                                                            data-testid='threshold-field1'
                                                            id='predictionTemperatureThresholdLevel0'
                                                            name='predictionTemperatureThresholdLevel0'
                                                            error={
                                                                !!formikProps.errors
                                                                    .predictionTemperatureThresholdLevel0
                                                            }
                                                            label={`${translate('t.safety_margin')} 0 (${translate(
                                                                't.trigger_calculation'
                                                            )})`}
                                                            variant='outlined'
                                                            size='small'
                                                            fullWidth
                                                            type='number'
                                                            defaultValue={
                                                                formikProps.values.predictionTemperatureThresholdLevel0
                                                            }
                                                            value={
                                                                formikProps.values.predictionTemperatureThresholdLevel0
                                                            }
                                                            disabled={
                                                                customerSettings.temperature_prediction_workflow !==
                                                                    1 ||
                                                                !formikProps.values.predictionTemperature ||
                                                                !isSuperAdmin
                                                            }
                                                            helperText={
                                                                formikProps.errors.predictionTemperatureThresholdLevel0
                                                                    ? formikProps.errors
                                                                          .predictionTemperatureThresholdLevel0
                                                                    : ''
                                                            }
                                                            onChange={formikProps.handleChange}
                                                            onBlur={formikProps.handleBlur}
                                                            inputProps={{
                                                                form: {
                                                                    autocomplete: 'off'
                                                                },
                                                                step: 0.01,
                                                                min: 0
                                                            }}
                                                            InputLabelProps={{
                                                                shrink: true
                                                            }}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position='end'>
                                                                        {displayUserUnits.temperature}
                                                                    </InputAdornment>
                                                                )
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item md={4} lg={4} display={'inline-flex'}>
                                                        <TempPredTextfield
                                                            data-testid='threshold-field2'
                                                            id='predictionTemperatureThresholdLevel1'
                                                            name='predictionTemperatureThresholdLevel1'
                                                            error={
                                                                !!formikProps.errors
                                                                    .predictionTemperatureThresholdLevel1
                                                            }
                                                            title={`${translate('t.safety_margin')} 1 (${translate(
                                                                't.alert'
                                                            )} 0)`}
                                                            label={`${translate('t.safety_margin')} 1 (${translate(
                                                                't.alert'
                                                            )} 0)`}
                                                            variant='outlined'
                                                            size='small'
                                                            fullWidth
                                                            type='number'
                                                            defaultValue={
                                                                formikProps.values.predictionTemperatureThresholdLevel1
                                                            }
                                                            value={
                                                                formikProps.values.predictionTemperatureThresholdLevel1
                                                            }
                                                            disabled={
                                                                customerSettings.temperature_prediction_workflow !==
                                                                    1 ||
                                                                !formikProps.values.predictionTemperature ||
                                                                !isSuperAdmin
                                                            }
                                                            helperText={
                                                                formikProps.errors.predictionTemperatureThresholdLevel1
                                                                    ? formikProps.errors
                                                                          .predictionTemperatureThresholdLevel1
                                                                    : ''
                                                            }
                                                            onChange={formikProps.handleChange}
                                                            onBlur={formikProps.handleBlur}
                                                            inputProps={{
                                                                form: {
                                                                    autocomplete: 'off'
                                                                },
                                                                step: 0.01,
                                                                min: 0
                                                            }}
                                                            InputLabelProps={{
                                                                shrink: true
                                                            }}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position='end'>
                                                                        {displayUserUnits.temperature}
                                                                    </InputAdornment>
                                                                )
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item md={4} lg={4} display={'inline-flex'}>
                                                        <TempPredTextfield
                                                            data-testid='threshold-field3'
                                                            id='predictionTemperatureThresholdLevel2'
                                                            name='predictionTemperatureThresholdLevel2'
                                                            error={
                                                                !!formikProps.errors
                                                                    .predictionTemperatureThresholdLevel2
                                                            }
                                                            title={`${translate('t.safety_margin')} 2 (${translate(
                                                                't.alert'
                                                            )} 2)`}
                                                            label={`${translate('t.safety_margin')} 2 (${translate(
                                                                't.alert'
                                                            )} 2)`}
                                                            variant='outlined'
                                                            size='small'
                                                            fullWidth
                                                            type='number'
                                                            defaultValue={
                                                                formikProps.values.predictionTemperatureThresholdLevel2
                                                            }
                                                            value={
                                                                formikProps.values.predictionTemperatureThresholdLevel2
                                                            }
                                                            disabled={
                                                                customerSettings.temperature_prediction_workflow !==
                                                                    1 ||
                                                                !formikProps.values.predictionTemperature ||
                                                                !isSuperAdmin
                                                            }
                                                            helperText={
                                                                formikProps.errors.predictionTemperatureThresholdLevel2
                                                                    ? formikProps.errors
                                                                          .predictionTemperatureThresholdLevel2
                                                                    : ''
                                                            }
                                                            onChange={formikProps.handleChange}
                                                            onBlur={formikProps.handleBlur}
                                                            inputProps={{
                                                                form: {
                                                                    autocomplete: 'off'
                                                                },
                                                                step: 0.01,
                                                                min: 0
                                                            }}
                                                            InputLabelProps={{
                                                                shrink: true
                                                            }}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position='end'>
                                                                        {displayUserUnits.temperature}
                                                                    </InputAdornment>
                                                                )
                                                            }}
                                                        />
                                                    </Grid>
                                                </>
                                            )}
                                        </Grid>
                                    </CustomBox>
                                </Grid>
                            )}

                            {isSuperAdmin &&
                                (vehicleActionAtom.action === CRUD.EDIT || vehicleActionAtom.action === CRUD.CLONE) &&
                                formikProps.values.externalSystems.length > 0 && (
                                    <Grid item md={4} lg={4}>
                                        {formikProps.values.externalSystems.map((externalSystem, index) => (
                                            <CustomTextfield
                                                key={index}
                                                id={`external-system-for-${externalSystem.externalSystemName}`}
                                                data-testid={`external-system-for-${externalSystem.externalSystemName}`}
                                                size='small'
                                                type='text'
                                                style={{ height: '62px' }}
                                                fullWidth
                                                label={
                                                    <>
                                                        {externalSystem.externalSystemName}
                                                        <VehicleActionsInfoTooltip
                                                            textInfo={translate('p.key_pair_representation')}
                                                            testid={'external-system-value-info'}
                                                        />
                                                    </>
                                                }
                                                placeholder={translate('t.key_pairing_value')}
                                                onBlur={formikProps.handleBlur}
                                                value={externalSystem.value}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                onChange={(event) => {
                                                    let newValues = formikProps.values.externalSystems;
                                                    newValues[index].value = event.target.value;
                                                    formikProps.setFieldValue('externalSystems', newValues, true);
                                                }}
                                                error={
                                                    !!(formikProps.errors.externalSystems
                                                        ? formikProps.errors.externalSystems[index]
                                                        : false) &&
                                                    !!(formikProps.touched.externalSystems
                                                        ? formikProps.touched.externalSystems[index]
                                                        : false)
                                                }
                                            />
                                        ))}
                                    </Grid>
                                )}

                            <Grid item md={3} lg={3}>
                                {vehicleActionAtom.action === CRUD.EDIT && (
                                    <FormControlLabel
                                        value={formikProps.values.tpmsFromForeignHubs}
                                        control={
                                            <Switch
                                                data-testid='tpms-foreign-hubs-switch'
                                                color='primary'
                                                checked={formikProps.values.tpmsFromForeignHubs}
                                                value={formikProps.values.tpmsFromForeignHubs}
                                                name='tpmsFromForeignHubs'
                                            />
                                        }
                                        label={translate('t.get_tpms_passing_trucks')}
                                        labelPlacement='start'
                                        onChange={(e, value) => {
                                            formikProps.setFieldValue('tpmsFromForeignHubs', value, false);
                                        }}
                                    />
                                )}
                            </Grid>
                            <Grid item md={3} lg={3}>
                                {vehicleActionAtom.action === CRUD.EDIT && (
                                    <FormControlLabel
                                        value={formikProps.values.outOfService}
                                        control={
                                            <Switch
                                                data-testid='out-of-service-switch'
                                                color='primary'
                                                checked={formikProps.values.outOfService}
                                                value={formikProps.values.outOfService}
                                                name='showTargetPressure'
                                            />
                                        }
                                        label={translate('t.out_of_service')}
                                        labelPlacement='start'
                                        onChange={(e, value) => {
                                            formikProps.setFieldValue('outOfService', value, false);
                                        }}
                                    />
                                )}
                            </Grid>
                        </GenerateFormikSections>
                    </Form>
                );
            }}
        </Formik>
    );
};

import React, { useCallback, useEffect } from 'react';
import { TyreTableContent } from './TyreTable.view';
import { TyreTableProps } from './TyreTable.type';
import { ActionButton, CustomLabel } from './TyreTable.style';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { TyreModel, TyreQueryKeys } from 'models/Tyre.type';
import Menu from 'components/Ui/Components/UiMenu';
import SettingsIcon from '@mui/icons-material/Settings';
import CircularProgress from '@mui/material/CircularProgress';
import { ModalActionTypesEnum, ModalAtom, ModalDispatcher } from 'states/global/Modal';
import Button from 'components/Ui/Components/UiButton/UiButton';
import AddTyre from '../AddTyre/AddTyre';
import Tyre from 'api/Tyre';
import { useMutation } from '@tanstack/react-query';
import { Success } from 'components/Popup/Popup';
import { checkRole } from 'helpers/authentication';
import { UserInfo } from 'states/global/User';
import { ROLES } from 'variables';
import CommentUpdate from '../CommentUpdate/CommentUpdate';
import { Box, TextField, FormControl } from '@mui/material';
import { VARIANT_BUTTON } from 'components/Ui/variables';
import useConverter from 'components/CustomHooks/Converter/Converter';
import useModal from 'components/CustomHooks/ModalHook';
import TemperatureExposureTyreModal from '../TemperatureExposureTyreModal';
import UiCheckbox from 'components/Ui/Components/UiCheckbox/UiCheckbox';
import { Theme } from 'states/global/Theme';
import UiLink from 'components/Ui/Components/UiLink/UiLink';
import { CustomerSettings } from 'states/global/CustomerSettings';
import { Column } from 'components/Ui/UiTable/UiTable.type';
import { QueryFn } from 'components/Ui/UiTable/TableAtom';
import useTableFilter from 'components/CustomHooks/TableFilterState/TableFilterState';

const tyreApi = new Tyre();

const TyreTable: React.FC<TyreTableProps> = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const userInfo = useRecoilValue(UserInfo);
    const [modalAtom, setStateModalAtom] = useRecoilState(ModalAtom);
    const modalDispach = ModalDispatcher(modalAtom, setStateModalAtom);
    const queryTableFn = useRecoilValue(QueryFn(`${TyreQueryKeys.getAll}-QueryFn`));
    const { convertType, fromServerToUserUnit } = useConverter();
    const customerSettings = useRecoilValue(CustomerSettings);
    const { setModalState } = useModal();
    const ThemeState = useRecoilValue(Theme);

    const { mutate: removeTyreMutate, isLoading: removeTyreLoading } = useMutation(tyreApi.removeTyre, {
        onSuccess: () => {
            Success({
                text: translate('t.removed_tyre')
            });
            queryTableFn.refetch instanceof Function && queryTableFn.refetch();
            modalDispach({ type: ModalActionTypesEnum.CLOSE_MODAL });
        }
    });

    const updateModalRemove = () => {
        modalDispach({
            type: ModalActionTypesEnum.UPDATE_MODAL_STATE,
            ModalPropsPayload: {
                ...modalAtom,
                buttons: (
                    <Button skin='error' color='error' variant={VARIANT_BUTTON} disabled={removeTyreLoading}>
                        {removeTyreLoading ? <CircularProgress size='1.75em' /> : translate('t.remove')}
                    </Button>
                )
            }
        });
    };

    const handleTemperatureExposureModal = useCallback((tyre: TyreModel) => {
        setModalState({
            id: 'TemperatureExposureModal',
            isOpen: true,
            content: <TemperatureExposureTyreModal tyreId={tyre.id} />,
            width: 99,
            widthUnit: 'vw',
            height: 445,
            leftTitle: translate('t.distribution_temperature_by_tyre'),
            rightTitle: `${translate('t.tyre')} ${tyre.tyreSerialNumber}`
        });
    }, []);

    const handleModalRemove = (tyre: TyreModel) => {
        const contentModalRemove = {
            id: 'UserModalActionsRemove',
            content: (
                <Box
                    data-testid='Remove-Tyre-Modal'
                    component='div'
                    textAlign='center'
                    marginBottom='20px'
                    marginTop='20px'
                >
                    {!tyre.vehicleId
                        ? translate('p.are_you_sure_to_delete')
                        : translate('p.will_unmount_are_you_sure_to_delete')}
                </Box>
            ),
            customContent: null,
            leftTitle: `${translate('t.remove_tyre')} - ${tyre.tyreSerialNumber}`,
            width: 400,
            height: 100,
            widthUnit: 'px',
            buttons: (
                <Button
                    testid='Remove-Tyre-Button'
                    skin='error'
                    color='error'
                    variant={VARIANT_BUTTON}
                    onClick={() => {
                        removeTyreMutate(tyre.id);
                    }}
                >
                    {translate('t.remove')}
                </Button>
            ),
            onClose: () =>
                modalDispach({
                    type: ModalActionTypesEnum.UPDATE_MODAL_STATE,
                    ModalPropsPayload: {
                        ...contentModalRemove,
                        isOpen: false
                    }
                }),
            isOpen: true
        };

        modalDispach({
            type: ModalActionTypesEnum.UPDATE_MODAL_STATE,
            ModalPropsPayload: contentModalRemove
        });
    };

    const handleModalAdd = () => {
        const contentModalAdd = {
            id: 'TireModalActionsAdd',
            customContent: <AddTyre />,
            leftTitle: translate('t.add_tyre'),
            width: 700,
            height: 500,
            widthUnit: 'px',
            onClose: () =>
                modalDispach({
                    type: ModalActionTypesEnum.UPDATE_MODAL_STATE,
                    ModalPropsPayload: {
                        ...contentModalAdd,
                        isOpen: false
                    }
                })
        };

        modalDispach({
            type: ModalActionTypesEnum.UPDATE_MODAL_STATE,
            ModalPropsPayload: {
                ...contentModalAdd,
                isOpen: true
            }
        });
    };

    const itemsMenu = (value, row) => {
        const items = [
            {
                title: translate('t.remove'),
                value: value,
                action: () => {
                    handleModalRemove(row);
                }
            },
            {
                title: translate('t.tyre_temp_levels'),
                value: value,
                action: () => {
                    handleTemperatureExposureModal(row);
                }
            }
        ];
        return items;
    };
    const columns: Column<TyreModel>[] = [
        {
            Header: translate('t.serial_number'),
            accessor: 'tyreSerialNumber',
            width: 110,
            Cell: (props: { row: { original: TyreModel } }) => (
                <>
                    {checkRole(userInfo.user, [ROLES.ROLE_SUPER_ADMIN]) ? (
                        <UiLink
                            $padding='0'
                            content={`${props.row.original.tyreSerialNumber}`}
                            url={`/lifespan-report?id=${props.row.original.id}`}
                        />
                    ) : (
                        <>{props.row.original.tyreSerialNumber}</>
                    )}
                </>
            )
        },
        {
            Header: translate('t.comment'),
            accessor: 'comment',
            width: 120,
            Cell: (props: { value: string; row: { original: TyreModel } }) => {
                if (
                    checkRole(userInfo.user, [ROLES.ROLE_ENGINEERING, ROLES.ROLE_BASE_USER, ROLES.ROLE_TECHNICAL_USER])
                ) {
                    return <>{props.value ?? '-'}</>;
                }

                return <CommentUpdate tyreId={props.row.original.id} showComment={props.value} queryKey='tyres' />;
            }
        },
        {
            Header: translate('t.position'),
            accessor: 'customPosition',
            width: 65,
            Cell: (props: { value: number }) => <>{props.value ?? '-'}</>,
            Filter: ({ column, setFilter, state, ...filterProps }) => {
                const { cellValue, setFilterValue } = useTableFilter({
                    filterContent: {
                        name: column.Header,
                        value: state?.filters.filter((filter) => filter.id === 'customPosition')[0]?.value?.value
                    },
                    setFilter: setFilter,
                    applyFiltersFlag: filterProps.applyFiltersFlag,
                    filterId: column.id
                });

                return (
                    <TextField
                        variant='outlined'
                        margin='normal'
                        id='positionfilter'
                        label={`${translate('t.position')}`}
                        value={cellValue?.value || ''}
                        onChange={(newValue) => {
                            const regex = /[^0-9]/;
                            const value = !newValue
                                ? undefined
                                : {
                                      name: `${translate('t.position')}`,

                                      value: !regex.test(newValue.target.value)
                                          ? newValue.target.value
                                          : state.filters.filter((filter) => filter.id === 'customPosition')[0]?.value
                                                .value
                                  };

                            if (newValue.target.value != '' && !regex.test(newValue.target.value))
                                setFilterValue(value);
                            else if (newValue.target.value == '') setFilterValue(undefined);
                        }}
                        onFocus={() => {}}
                        onBlur={() => {}}
                        InputLabelProps={{
                            shrink: true
                        }}
                        sx={{ marginTop: '5px' }}
                        inputProps={{
                            style: {
                                padding: '10.5px'
                            }
                        }}
                    />
                );
            }
        },
        {
            Header: translate('t.vehicle'),
            accessor: 'vehicleName',
            width: 150,
            Cell: (props: { value: string }) => <>{props.value ?? '-'}</>
        },
        {
            Header: `${customerSettings.mdd_bes ? customerSettings.mdd_bes_display_name.toUpperCase() : ''}`,
            accessor: 'highestBesSeverityRank',
            disableFilters: true,
            disableSortBy: true
        },
        {
            Header: `${translate('t.cold_pressure')} (${translate('t.max')})`,
            accessor: (row: TyreModel) => row.maxColdPressure,
            id: 'maxColdPressure',
            width: 90,
            Cell: (props: { value: number }) => (
                <>
                    {fromServerToUserUnit({
                        type: convertType.pressure,
                        value: props.value,
                        displayUnits: true,
                        displayIfEmpty: '-'
                    })}
                </>
            )
        },
        {
            Header: `${translate('t.hot_pressure')} (${translate('t.max')})`,
            accessor: (row: TyreModel) => row.maxHotPressure,
            id: 'maxHotPressure',
            width: 90,
            Cell: (props: { value: number }) => (
                <>
                    {fromServerToUserUnit({
                        type: convertType.pressure,
                        value: props.value,
                        displayUnits: true,
                        displayIfEmpty: '-'
                    })}
                </>
            )
        },
        {
            Header: `${translate('t.temperature')} (${translate('t.max')})`,
            accessor: (row: TyreModel) => row.maxTemperature,
            id: 'maxTemperature',
            width: 90,
            Cell: (props: { value: number }) => (
                <>
                    {fromServerToUserUnit({
                        type: convertType.temperature,
                        value: props.value,
                        displayUnits: true,
                        displayIfEmpty: '-'
                    })}
                </>
            )
        },
        {
            Header: translate('t.tread_depth'),
            accessor: (row: TyreModel) => row.treadDepth,
            id: 'treadDepth',
            width: 90,
            Cell: (props: { value: string }) => <>{props.value ?? '-'}</>
        },
        {
            Header: translate('t.mileage'),
            accessor: (row: TyreModel) => row.mileage,
            id: 'mileage',
            width: 90,
            Cell: (props: { value: string }) => <>{props.value ?? '-'}</>
        },
        {
            Header: translate('t.operated_hours'),
            accessor: (row: TyreModel) => row.operatedHours,
            id: 'operatedHours',
            width: 90,
            Cell: (props: { value: string }) => <>{props.value ?? '-'}</>
        },

        checkRole(userInfo.user, [
            ROLES.ROLE_SUPER_ADMIN,
            ROLES.ROLE_ADMIN,
            ROLES.ROLE_TYRE_BAY_USER,
            ROLES.ROLE_TECHNICAL_ADMIN
        ])
            ? {
                  Header: translate('t.actions'),
                  accessor: 'id',
                  width: 50,
                  height: 20,
                  disableFilters: true,
                  disableSortBy: true,
                  Cell: (props: { value: number; row: { original: TyreModel } }) => (
                      <Menu
                          testid='Table-Tyres-Row-Action-Menu'
                          items={[...itemsMenu(props.value, props.row.original)]}
                          rootEl={
                              <ActionButton
                                  aria-controls='simple-menu'
                                  aria-haspopup='true'
                                  data-testid='Table-Tyres-Row-Action-Button'
                              >
                                  <SettingsIcon />
                              </ActionButton>
                          }
                      />
                  )
              }
            : {
                  accessor: 'id',
                  width: 0,
                  height: 0,
                  disableFilters: true,
                  disableSortBy: true,
                  Cell: <></>
              },
        {
            Header: '',
            width: 0,
            accessor: 'hasWheel',
            disableSortBy: true,
            Filter: ({ column, setFilter, ...filterProps }) => {
                const { cellValue, setFilterValue } = useTableFilter({
                    filterContent: {
                        name: translate('t.assigned_to_wheels'),
                        value: column?.filterValue?.value,
                        humanValue: column?.filterValue?.humanValue
                    },
                    setFilter: setFilter,
                    applyFiltersFlag: filterProps.applyFiltersFlag,
                    filterId: column.id
                });

                return (
                    <FormControl variant='outlined' size='small'>
                        <div className='ContainerChecboxFilter'>
                            <CustomLabel>{translate('t.assigned_to_wheels')}</CustomLabel>
                            <UiCheckbox
                                checked={cellValue?.value === '1'}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    let value: object | undefined = undefined;
                                    if (e.target.checked) {
                                        value = {
                                            name: translate('t.assigned_to_wheels'),
                                            value: '1',
                                            humanValue: 'True'
                                        };
                                    }
                                    setFilterValue(value);
                                }}
                                name='_checkboxHasWheel'
                                color='primary'
                            />
                        </div>
                    </FormControl>
                );
            }
        },
        {
            width: 0,
            Header: '',
            disableFilters: true,
            disableSortBy: true,
            accessor: 'position'
        }
    ];

    if (checkRole(userInfo.user, [ROLES.ROLE_ENGINEERING, ROLES.ROLE_BASE_USER, ROLES.ROLE_TECHNICAL_USER])) {
        columns.splice(9, 1);
    }

    useEffect(() => {
        updateModalRemove();
    }, [removeTyreLoading]);

    return <TyreTableContent data-testid={'TyreTable-testid'} columns={columns} handleModalAdd={handleModalAdd} />;
};

export default TyreTable;

import React, { useState } from 'react';
import { AlertNotificationManagmentTableContent } from './AlertNotificationManagmentTable.view';
import {
    AlertNotificationManagmentTableProps,
    AlertNotificationSelectedType
} from './AlertNotificationManagmentTable.type';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { AlertNotificationModel } from 'models/AlertNotification.type';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { Theme } from 'states/global/Theme';
import { ALERT_LEVEL_2, BLUE_BRIDGESTONE } from 'components/Ui/colors';
import UserList from './UserList/UserList';
import ActionButtons from './ActionButtons/ActionButtons';
import { Wrapper } from 'helpers/wrapper';

const AlertNotificationManagmentTable: React.FC<AlertNotificationManagmentTableProps> = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const { userFormatUTC } = useConverter();
    const [alertNotificationSelected, setAlertNotificationSelected] = useState<AlertNotificationSelectedType>();
    const ThemeMode = useRecoilValue(Theme);

    const columns = [
        {
            Header: translate('t.date'),
            accessor: 'date',
            width: 45,
            height: 20,
            Cell: (props: { row: { original: AlertNotificationModel } }) => {
                return userFormatUTC({
                    date: props.row.original.date,
                    format: 'date',
                    displaySeconds: false
                });
            },
            BackgroundTr: (original: AlertNotificationModel) => {
                const rowColor = {
                    light: ALERT_LEVEL_2,
                    dark: BLUE_BRIDGESTONE
                };

                if (original.snoozedTo) {
                    return rowColor[ThemeMode?.mode];
                }
            }
        },
        {
            Header: translate('t.time_from'),
            accessor: 'timeFrom',
            width: 40,
            height: 20,
            disableSortBy: true,
            Cell: (props: { row: { original: AlertNotificationModel } }) => {
                return userFormatUTC({
                    date: props.row.original.timeFrom,
                    format: 'time',
                    displaySeconds: true
                });
            }
        },
        {
            Header: translate('t.time_to'),
            accessor: 'timeTo',
            width: 40,
            height: 20,
            disableSortBy: true,
            Cell: (props: { row: { original: AlertNotificationModel } }) => {
                return userFormatUTC({
                    date: props.row.original.timeTo,
                    format: 'time',
                    displaySeconds: true
                });
            }
        },
        {
            Header: translate('t.snooze_until'),
            accessor: 'snoozedTo',
            width: 40,
            height: 20,
            disableSortBy: true,
            Cell: (props: { row: { original: AlertNotificationModel } }) => {
                return userFormatUTC({
                    date: props.row.original.snoozedTo,
                    format: 'time',
                    displaySeconds: true
                });
            }
        },
        {
            Header: translate('t.users'),
            accessor: 'alertNotificationCallsUser',
            width: 30,
            height: 20,
            disableSortBy: true,
            Cell: (props: { row: { original: AlertNotificationModel } }) => {
                return (
                    <UserList
                        id={props.row.original.id}
                        alertNotificationCallsUser={props.row.original.alertNotificationCallsUser}
                    />
                );
            }
        },
        {
            Header: translate('t.actions'),
            accessor: 'id',
            width: 100,
            height: 20,
            disableSortBy: true,
            Cell: (props: { row: { original: AlertNotificationModel } }) => {
                return (
                    <ActionButtons
                        alertNotification={props.row.original}
                        alertNotificationSelected={alertNotificationSelected}
                        setAlertNotificationSelected={setAlertNotificationSelected}
                    />
                );
            }
        }
    ];

    return (
        <AlertNotificationManagmentTableContent
            data-testid='AlertNotificationManagmentTable-testid'
            columns={columns}
        />
    );
};

export default Wrapper(AlertNotificationManagmentTable);

import React, { useMemo } from 'react';
import UiWidgetTab from 'components/Ui/Components/UiWidgetTab/UiWidgetTab';
import AlertRefresh from './AlertRefresh.view';
import AlertsList from '../AlertsList/AlertsList';
import { useTranslation } from 'react-i18next';
import TpmsAlertsQuery from 'components/Queries/TpmsAlertQuery/TpmsAlertQuery';
import { useRecoilValue } from 'recoil';
import { AlertsByVehicleContainer } from './SystemAlertsListTabManager.style';
import { applyStyleByMode } from 'helpers';
import { faBook } from '@fortawesome/pro-solid-svg-icons';
import UiIcon from 'components/Ui/Components/UiIcon';
import { WHITE } from 'components/Ui/colors';
import { AlertsListViewProps, typeAlert } from './SystemAlertsListTabManager.type';
import { Theme } from 'states/global/Theme';
import { TpmsAlertsQueryKeys } from 'models/TpmsAlerts.type';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { DateTime } from 'luxon';
import { CustomerSettings } from 'states/global/CustomerSettings';
import AlertsPopUpStateView from './AlertsPopUpState.view';

export const SystemAlertsListTabManagerContent: React.FC<AlertsListViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const { fromTimezoneToUTC } = useConverter();
    const customerSettings = useRecoilValue(CustomerSettings);
    const ThemeMode = useRecoilValue(Theme);
    let contentHeight = !props.displayRefresh ? 473 : 465;

    const tabHeaders = useMemo(() => {
        const headers = [
            {
                label: translate('t.system_alerts'),
                testid: 'System-alerts-dashboard-button-tab',
                icon: (
                    <UiIcon
                        icon={['fas', 'triangle-exclamation']}
                        size='1x'
                        transform='left-4'
                        fixedWidth
                        color={applyStyleByMode({
                            theme: ThemeMode?.mode,
                            light: '#0000008a',
                            dark: WHITE
                        })}
                    />
                )
            },
            {
                label: translate('t.historical_log'),
                testid: 'System-alerts-Historical-Log-dashboard-button-tab',
                icon: (
                    <UiIcon
                        icon={faBook}
                        size='1x'
                        transform='left-4'
                        fixedWidth
                        color={applyStyleByMode({
                            theme: ThemeMode?.mode,
                            light: '#0000008a',
                            dark: WHITE
                        })}
                    />
                )
            }
        ];

        return headers;
    }, [customerSettings]);

    const tabContent = useMemo(() => {
        const contentTab = [
            {
                id: 0,
                body: (
                    <TpmsAlertsQuery
                        alertFilters={props.alertFilters}
                        measuredFrom={fromTimezoneToUTC(DateTime.local().minus({ days: 30 }))}
                        customConfig={props.customConfigReactQuery.TpmsQuery}
                        currentPage={1}
                        limit={150}
                        solved={false}
                        tab={'system'}
                        keyQuery={TpmsAlertsQueryKeys.getNotificationSensorSolved}
                    >
                        {({ data, isLoading }) => (
                            <AlertsList
                                {...props}
                                data={data?.items}
                                alertKey={'_alertSensor'}
                                testId='System-alerts-dashboard-alerts-list'
                                typeAlert={typeAlert.Sensor}
                                isLoading={isLoading}
                                tabNumber={0}
                                displayBody={false}
                            />
                        )}
                    </TpmsAlertsQuery>
                )
            },

            {
                id: 1,
                body: (
                    <TpmsAlertsQuery
                        alertFilters={props.alertFilters}
                        measuredFrom={fromTimezoneToUTC(DateTime.local().minus({ days: 30 }))}
                        customConfig={props.customConfigReactQuery.TpmsQuery}
                        currentPage={1}
                        limit={150}
                        solved={true}
                        tab={'system'}
                        keyQuery={`${TpmsAlertsQueryKeys.getNotificationSolved}-systemAlerts`}
                    >
                        {({ data, isLoading }) => (
                            <AlertsList
                                {...props}
                                data={data?.items}
                                testId='System-alerts-Historical-log-dashboard-alerts-list'
                                alertKey={'_alertHistoryLog'}
                                displayAlertActions={false}
                                typeAlert={typeAlert.HistoricalLog}
                                isLoading={isLoading}
                                tabNumber={3}
                                displayBody={true}
                            />
                        )}
                    </TpmsAlertsQuery>
                )
            }
        ];

        return contentTab;
    }, [customerSettings, props.alertFilters]);

    return (
        <AlertsByVehicleContainer>
            <UiWidgetTab
                title={translate('t.alerts_by_vehicle')}
                $contentHeight={contentHeight}
                height={512}
                actionScroll={props.actionScroll}
                testid='Widget-SystemAlerts'
                unit='px'
                setNumberTab={props.setNumberTab}
                tab={{
                    headers: tabHeaders,
                    constents: tabContent
                }}
                headerAction={
                    <AlertsPopUpStateView
                        AddFilterAlertByLevel={props.AddFilterAlertByLevel}
                        setFilterByType={props.setFilterByType}
                    />
                }
            />
            <AlertRefresh
                setEnabledQuery={props.setEnabledQuery}
                enabledQuery={props.enabledQuery}
                displayRefresh={!!props.displayRefresh}
            />
        </AlertsByVehicleContainer>
    );
};

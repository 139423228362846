import { useCallback } from 'react';
import { DateTime } from 'luxon';
import TeamShift from 'api/TeamShift';
import { useQueryClient } from '@tanstack/react-query';
import { TeamShiftModel } from 'models/TeamShift.type';
import { DateRange, TimeRange } from '../ShiftTimeCalendarAction/ShiftTimeCalendarAction.type';
import TeamShiftTime from 'api/TeamShiftTime';
import { TeamShiftTimeModel } from 'models/TeamShiftTime.type';

const useFreeShiftChecker = () => {
    const teamShiftApi = new TeamShift();
    const teamShiftTimeApi = new TeamShiftTime();
    const queryClient = useQueryClient();

    const getTeamShiftData = async (dateFilter: string): Promise<{ teamShift: TeamShiftModel[] }> => {
        return await queryClient.fetchQuery(['GetTeamShiftListByShiftDate', dateFilter], () =>
            teamShiftApi.getTeamShiftByShiftDate(dateFilter)
        );
    };

    const getShiftTimeData = async (): Promise<{ items: TeamShiftTimeModel[] }> => {
        return await queryClient.fetchQuery(['GetTeamShiftTimeListDate'], () => teamShiftTimeApi.get({}));
    };

    const addOneSecond = useCallback((time: string): string => {
        const [hours, minutes, seconds] = time.split(':').map(Number);
        const date = new Date();
        date.setHours(hours, minutes, seconds + 1);
        return date.toTimeString().split(' ')[0];
    }, []);

    const subtractOneSecond = useCallback((time: string): string => {
        const [hours, minutes, seconds] = time.split(':').map(Number);
        const date = new Date();
        date.setHours(hours, minutes, seconds - 1);
        return date.toTimeString().split(' ')[0];
    }, []);

    const generateEmptyRanges = useCallback(
        (timeSlots: string[]): TimeRange[] => {
            const emptyRanges: TimeRange[] = [];
            let lastEndTime = '00:00:00';

            for (let i = 0; i < timeSlots.length; i++) {
                const slot = timeSlots[i];

                if (slot === '') {
                    let startTime = lastEndTime;

                    let j = i;
                    while (j < timeSlots.length && timeSlots[j] === '') {
                        j++;
                    }

                    if (j < timeSlots.length) {
                        const [nextStart] = timeSlots[j].split(' - ');
                        const endTime = subtractOneSecond(nextStart);

                        emptyRanges.push({
                            startTime: startTime,
                            endTime
                        });

                        i = j - 1;
                    } else {
                        emptyRanges.push({
                            startTime: startTime,
                            endTime: '23:59:59'
                        });
                        break;
                    }
                } else {
                    const [, end] = slot.split(' - ');
                    lastEndTime = end;
                }
            }

            return emptyRanges;
        },
        [addOneSecond, subtractOneSecond]
    );

    const canFitInEmptyRange = useCallback((newRange: TimeRange, emptyRanges: TimeRange[]): boolean => {
        return emptyRanges.some(
            (emptyRange) => newRange.startTime >= emptyRange.startTime && newRange.endTime <= emptyRange.endTime
        );
    }, []);

    const getFreeHours = useCallback(
        (
            teamShiftList: TeamShiftModel[] | TeamShiftTimeModel[],
            timeRange: TimeRange,
            isShiftTImeList: boolean
        ): boolean => {
            const freeHours: string[] = Array(24).fill('');

            teamShiftList.forEach((teamShift) => {
                const { shiftTime } = teamShift;
                const { endTime, startTime } = teamShift;

                const startTimeUTC = DateTime.fromISO(isShiftTImeList ? startTime : shiftTime.startTime).setZone('utc');
                const endTimeUTC = DateTime.fromISO(isShiftTImeList ? endTime : shiftTime.endTime).setZone('utc');

                let startTimeShift = Number(startTimeUTC.toFormat('HH'));
                let endTimeShift = Number(endTimeUTC.toFormat('HH'));

                if (startTimeShift > endTimeShift) {
                    endTimeShift = 23;
                }

                for (let i = Number(startTimeUTC.toFormat('HH')); i <= endTimeShift; i++) {
                    freeHours[i] = `${startTimeUTC.toFormat('HH:mm:ss')} - ${endTimeUTC.toFormat('HH:mm:ss')}`;
                }
            });

            const emptyRanges = generateEmptyRanges(freeHours);
            return canFitInEmptyRange(timeRange, emptyRanges);
        },
        [generateEmptyRanges, canFitInEmptyRange]
    );

    const isRangeFreeTime = useCallback(
        async (
            dateFilter: string,
            shiftId: number,
            timeRange: TimeRange,
            differentDay = false,
            dateRange: DateRange
        ): Promise<boolean> => {
            if (differentDay && dateRange) {
                let rangeFreeTime = true;
                const timeRangeFilter = [
                    { startTime: timeRange.startTime, endTime: '23:59:59' },
                    { startTime: '00:00:00', endTime: timeRange.endTime }
                ];

                for (let i = 0; i <= Object.keys(dateRange).length - 1; i++) {
                    const { teamShift } = await getTeamShiftData(i === 0 ? dateRange.dateFrom : dateRange.dateTo);

                    if (teamShift && teamShift?.length) {
                        if (shiftId) {
                            return getFreeHours(
                                teamShift.filter((shift) => shift.id !== shiftId),
                                timeRangeFilter[i],
                                false
                            );
                        }
                        if (!getFreeHours(teamShift, timeRangeFilter[i], false)) {
                            rangeFreeTime = getFreeHours(teamShift, timeRangeFilter[i], false);
                        }
                    }
                }
                return rangeFreeTime;
            } else {
                const { teamShift } = await getTeamShiftData(dateFilter);

                if (teamShift && teamShift?.length) {
                    if (shiftId) {
                        return getFreeHours(
                            teamShift.filter((shift) => shift.id !== shiftId),
                            timeRange,
                            false
                        );
                    }
                    return getFreeHours(teamShift, timeRange, false);
                } else {
                    return true;
                }
            }
        },
        [getFreeHours]
    );

    const isRangeFreeTimeShiftTime = useCallback(
        async (
            shiftTimeId: number | null,
            timeRange: TimeRange,
            differentDay = false,
            dateRange: DateRange
        ): Promise<boolean> => {
            if (differentDay && dateRange) {
                let rangeFreeTime = true;
                const timeRangeFilter = [
                    { startTime: timeRange.startTime, endTime: '23:59:59' },
                    { startTime: '00:00:00', endTime: timeRange.endTime }
                ];

                for (let i = 0; i <= Object.keys(dateRange).length; i++) {
                    const { items } = await getShiftTimeData();

                    if (items && items?.length) {
                        if (shiftTimeId) {
                            return getFreeHours(
                                items.filter((shift) => shift.id !== shiftTimeId),
                                timeRangeFilter[i],
                                true
                            );
                        }
                        rangeFreeTime = getFreeHours(items, timeRange, true);
                    }
                }
                return rangeFreeTime;
            } else {
                const { items } = await getShiftTimeData();

                if (items && items?.length) {
                    if (shiftTimeId) {
                        return getFreeHours(
                            items.filter((shiftTime) => shiftTime.id !== shiftTimeId),
                            timeRange,
                            true
                        );
                    }
                    return getFreeHours(items, timeRange, true);
                } else {
                    return true;
                }
            }
        },
        [getFreeHours]
    );

    return {
        isRangeFreeTime,
        isRangeFreeTimeShiftTime
    };
};

export default useFreeShiftChecker;

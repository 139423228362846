import React from 'react';
import { TyreManufacturerLazyLoadingProps } from './TyreManufacturerLazyLoading.type';
import LazyloadingAutocomplete from 'components/Mixs/LazyloadingAutocomplete';
import TyreManufacturer from 'api/TyreManufacturer';
import { useTranslation } from 'react-i18next';

const tyreManufacturerAPI = new TyreManufacturer();

const TyreManufacturerLazyLoading: React.FC<TyreManufacturerLazyLoadingProps> = () => {
    const { t: translate } = useTranslation();

    return (
        <div style={{ marginBottom: '20px' }}>
            <LazyloadingAutocomplete
                id='tyre-manufacturers'
                queryId='TyreManufacturerLazyLoading'
                query={tyreManufacturerAPI.getTable}
                optionKey='name'
                fullWidth
                optionValue='id'
                label={translate('t.tyre_manufacturer')}
                width={320}
                size='small'
            />
        </div>
    );
};

export default TyreManufacturerLazyLoading;

import { TextField } from '@mui/material';
import { Autocomplete } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as type from './RolesDropDown.type';
import { GridFieldFormik } from '../ProfileTabManager/ProfileTabManager.style';

export const RolesDropDownContent: React.FC<type.RolesDropDownViewProps> = ({
    value,
    error,
    roles,
    rawValue,
    defaultValue,
    lg = 6,
    md = 6,
    sm = 6,
    xs = 12,
    xl = 4,
    getValue,
    disabled = false,
    formatText,
    testId
}): JSX.Element => {
    const { t: translate } = useTranslation();
    return (
        <GridFieldFormik item xl={xl} lg={lg} md={md} sm={sm} xs={xs}>
            <Autocomplete
                data-testid={testId}
                options={roles}
                getOptionLabel={(role) => (role && !!Object.keys(role).length ? formatText(role.name, 'ROLE_') : '')}
                onChange={(e, role) => {
                    if (rawValue == true) {
                        getValue(
                            role ? role.id : null,
                            role && !!Object.keys(role).length ? formatText(role?.name, 'ROLE_') : '',
                            role?.name
                        );
                    } else {
                        getValue(
                            role ? role.id : null,
                            role && !!Object.keys(role).length ? formatText(role?.name, 'ROLE_') : ''
                        );
                    }
                }}
                disabled={disabled}
                size='small'
                defaultValue={
                    rawValue
                        ? roles.find((role) => role.name === defaultValue)
                        : roles.find((role) => role.id === defaultValue)
                }
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={translate('t.role')}
                        variant='outlined'
                        name='role'
                        type='text'
                        value={value}
                        InputLabelProps={{
                            shrink: true
                        }}
                        helperText={error && translate('p.this_field_is_required')}
                        error={!!error}
                    />
                )}
            />
        </GridFieldFormik>
    );
};

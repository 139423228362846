import React from 'react';
import LazyloadingAutocomplete from 'components/Mixs/LazyloadingAutocomplete';
import TyreModel from 'api/TyreModel';
import { useTranslation } from 'react-i18next';

const tyreModelAPI = new TyreModel();

const TyreModelLazyLoading = () => {
    const { t: translate } = useTranslation();

    return (
        <div style={{ marginBottom: '20px' }}>
            <LazyloadingAutocomplete
                id='tyre-model'
                queryId='TyreModelLazyLoading'
                query={tyreModelAPI.getTable}
                optionKey='name'
                fullWidth
                optionValue='id'
                label={translate('t.tyre_model')}
                width={320}
                size='small'
                onChange={(event) => {}}
            />
        </div>
    );
};

export default TyreModelLazyLoading;

import { Paginator } from './Paginator.type';
import { DeviceRevisionModel } from './DeviceRevision.type';
import { DeviceInfoModel } from './DeviceInfo.type';
import { DeviceFaultyStatusAssigned, PatchDeviceFaultyStatus } from './DeviceFaultyStatus.type';
import { HubTestResultResponse } from './HubTestResult.type';
import { Paginator as GenericPaginator } from 'helpers/api/type';
import { AppRegionModel } from './AppRegion.type';

type VehicleDeviceType = {
    id: number;
    name: string;
    outOfService: boolean;
};

export type DeviceModel = {
    id: number;
    serialNumber: string;
    comment?: string;
    uuid: string;
    revision: string;
    revisionId: number;
    createdAt: string;
    deleteCmd: number;
    mounted: number;
    imei?: string;
    deviceRevision: DeviceRevisionModel | null;
    customer?: {
        id: number;
        name: string;
    };
    deviceInfo: DeviceInfoModel;
    vehicle?: VehicleDeviceType;
    faultyStatus?: DeviceFaultyStatusAssigned[];
    batteryFrom?: string;
    batteryTo?: string;
    powerSourceFrom?: string;
    powerSourceTo?: string;
    lastTransmissionFrom?: string;
    lastTransmissionTo?: string;
    appRegion?: AppRegionModel;
    deviceEmmcPercFrom?: string;
    deviceEmmcPercTo?: string;
    ipFullText?: string;
    notIpFullText?: string;
    wifiChannelFullText?: string;
    notWifiChannelFullText?: string;
    maskFullText?: string;
    notMaskFullText?: string;
    gatewayFullText?: string;
    notGatewayFullText?: string;
    macAddressWifi?: string;
    device: {
        deviceInfo: {
            lastTransmission: string;
        };
        id: number;
        serialNumber: string;
    };
};

export type PatchDeviceModel = Partial<
    Omit<DeviceModel, 'vehcile' | 'faultyStatus'> & {
        vehicle: number;
        faultyStatus?: PatchDeviceFaultyStatus;
    }
>;

export type DeviceType = {
    codeBook: DeviceModel[];
};

export type DeviceTable = {
    items: DeviceModel[];
    paginator: Paginator;
};

export type DeviceSerialConnectionTypeList = {
    codeBook: DeviceSerialConnectionType[];
};

type DeviceSerialConnectionType = {
    id: number;
    connectionType: string;
};

export type DeviceIdDeviceInfo = Pick<DeviceModel, 'id'> & {
    deviceInfo: Pick<DeviceInfoModel, 'lastTransmission'>;
};

export type NewHubTestDataResponse = {
    newHubTestData: HubTestResultResponse;
};
export type DeviceDetailsModel = {
    id: number;
    vehicleName: string;
    uuid: string;
    service: string;
    tools: string;
    revision: string;
    firmware: string;
    sensorTypes: string;
    wifiBand: string;
    channel: string;
    ip: string;
    mask: string;
    gateway: string;
};

export type softwarePackagesModel = {
    kernel: string;
    uuid: string;
    service: string;
    tools: string;
    revision: string;
    firmware: string;
    sensorTypes: string;

    sshTunnel: string;
    ip: string;
    pingReboot: string;
    exchange: string;
};

export type DeviceCommandsTableModel = {
    id: number;
    command: number;
    commandType: number;
    createdAt: string;
    name: string;
    content: string;
    result?: string | null;
    submittedAt?: string;
    executedAt?: string;
    executed?: boolean;
};

export type DeviceCommandsTableResponse = {
    items: DeviceCommandsTableModel[] | unknown;
    paginator: Paginator;
};

export type DeviceStatistics =
    | {
          disk: number | null;
          memory: number | null;
          cpu: number | null;
          battery: number;
          powerSource: number;
          lastTransmission: string | null;
      }
    | undefined;

export type DeviceCommandsTerminalTable = DeviceCommandsTableModel;

export type DeviceCommandsHistoryTable = DeviceCommandsTableModel;

export type AllHubsTableData = {
    id: number;
    serialNumber: string;
    comment: string;
    uuid: string;
    createdAt: string;
    mounted: boolean;
    vehicle: {
        id: number;
        name: string;
    };
    customer: {
        id: number;
        name: string;
    };
    deviceInfo: DeviceInfoModel;
    deviceRevision: DeviceRevisionModel;
    faultyComment: string;
    faultyStatus: DeviceFaultyStatusAssigned[];
    stockItemId: number;
    testStatus: number;
    lastTestAt: string | null;
    createdAtFrom: string;
    createdAtTo: string;
    lastTransmissionFrom: string;
    lastTransmissionTo: string;
};

export type AllHubsListResponse = GenericPaginator<AllHubsTableData>;

export enum DeviceQueryKeys {
    getDetails = 'device-getDetails',
    getDeviceById = 'device-getDeviceById',
    getAvailableDevices = 'device-getAvailableDevices',
    getDevicesList = 'device-getDevicesList',
    getStatistics = 'device-getStatistics',
    getWifiCredentials = 'device-getWifiCredentials',
    getLazyDropdown = 'device-getLazyDropdown'
}

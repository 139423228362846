import React from 'react';
import { useRecoilState } from 'recoil';
import { TyreLifespanComparisonViewContent, DropDownContainer } from './TyreLifespanComparison.style';
import { TyreLifespanComparisonViewProps } from './TyreLifespanComparison.type';
import { useTranslation } from 'react-i18next';
import LifespanReportDetails from 'components/Tyre/LifespanReportDetails/LifespanReportDetails';
import { Grid } from '@mui/material';
import LazyloadingAutocomplete from 'components/Mixs/LazyloadingAutocomplete/LazyloadingAutocomplete';
import { TyreLifeSpanDataAtom } from './TyreLifespanComparison.atom';
import Tyre from 'api/Tyre';
import { TyreQueryKeys } from 'models/Tyre.type';

const tyreApi = new Tyre();

export const TyreLifespanComparisonContent: React.FC<TyreLifespanComparisonViewProps> = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const [firstTyreData, setFirstTyreData] = useRecoilState(TyreLifeSpanDataAtom('TyreLifespanData-1'));
    const [secondTyreData, setSecondTyreData] = useRecoilState(TyreLifeSpanDataAtom('TyreLifespanData-2'));

    return (
        <TyreLifespanComparisonViewContent data-testid='TyreLifespanReportContent'>
            <Grid container>
                <Grid item xs={6} md={6}>
                    <DropDownContainer>
                        <LazyloadingAutocomplete
                            $testid='TyreLifespanComparison-TyreSerialNumber-one-LazyloadingAutocomplete'
                            id='tyres-comparison-one-id'
                            queryId={`${TyreQueryKeys.getLazyDropdown}-comparison-one`}
                            optionKey='tyreSerialNumber'
                            optionValue='id'
                            width={150}
                            size='small'
                            customMargin='0px'
                            query={tyreApi.getTyreLazyDropdown}
                            label={translate('t.tyre_serial_number')}
                            onValueChange={(value) => {
                                if (value && value !== firstTyreData.tyreId) {
                                    setFirstTyreData({ ...firstTyreData, tyreId: value });
                                }
                            }}
                            preselectedValue={
                                firstTyreData.tyreId
                                    ? {
                                          value: firstTyreData.tyreId,
                                          label: `${firstTyreData.tyreSerialNumber}`,
                                          origItem: {}
                                      }
                                    : undefined
                            }
                            querySortBy={[
                                {
                                    desc: false,
                                    id: 'tyreSerialNumber'
                                }
                            ]}
                        />
                    </DropDownContainer>
                </Grid>
                <Grid item xs={6} md={6}>
                    <DropDownContainer>
                        <LazyloadingAutocomplete
                            $testid='TyreLifespanComparison-TyreSerialNumber-two-LazyloadingAutocomplete'
                            id='tyres-comparison-two-id'
                            queryId={`${TyreQueryKeys.getLazyDropdown}-comparison-two`}
                            optionKey='tyreSerialNumber'
                            optionValue='id'
                            width={150}
                            size='small'
                            customMargin='0px'
                            query={tyreApi.getTyreLazyDropdown}
                            label={translate('t.tyre_serial_number')}
                            onValueChange={(value) => {
                                if (value && value !== secondTyreData.tyreId) {
                                    setSecondTyreData({ ...secondTyreData, tyreId: value });
                                }
                            }}
                            preselectedValue={
                                secondTyreData.tyreId
                                    ? {
                                          value: secondTyreData.tyreId,
                                          label: `${secondTyreData.tyreSerialNumber}`,
                                          origItem: {}
                                      }
                                    : undefined
                            }
                            querySortBy={[
                                {
                                    desc: false,
                                    id: 'tyreSerialNumber'
                                }
                            ]}
                        />
                    </DropDownContainer>
                </Grid>
                <Grid item xs={6} md={6}>
                    <LifespanReportDetails
                        testid='LifespanReportDetails-Comparison-1'
                        key={firstTyreData.tyreId}
                        tyreId={firstTyreData.tyreId}
                        customPosition={firstTyreData.customPosition}
                        vehicleId={firstTyreData.vehicleId}
                        label={`${translate('t.tyre_lifespan_comparison')} - ${translate('t.first_tyre')}  `}
                    />
                </Grid>
                <Grid item xs={6} md={6}>
                    <LifespanReportDetails
                        testid='LifespanReportDetails-Comparison-2'
                        key={secondTyreData.tyreId}
                        tyreId={secondTyreData.tyreId}
                        customPosition={secondTyreData.customPosition}
                        vehicleId={secondTyreData.vehicleId}
                        label={`${translate('t.tyre_lifespan_comparison')} - ${translate('t.second_tyre')}  `}
                    />
                </Grid>
            </Grid>
        </TyreLifespanComparisonViewContent>
    );
};

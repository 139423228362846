import React from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { CustomTextfield } from './SaveRoutePopup.style';
import { SaveRoutePopupFormikProps } from './SaveRoutePopup.type';

export const SaveRoutePopupFormik: React.FC<SaveRoutePopupFormikProps> = ({ sendData }): JSX.Element => {
    const { t: translate } = useTranslation();

    const FormSchema = Yup.object().shape({
        routeName: Yup.string().min(2, 'Too Short!').required(translate('t.required'))
    });

    return (
        <Formik
            initialValues={{ routeName: '' }}
            validationSchema={FormSchema}
            enableReinitialize={true}
            onSubmit={(values) => {
                sendData(values);
            }}
        >
            {({ errors, touched, handleChange, values }) => {
                return (
                    <Form id='save-route-form'>
                        <CustomTextfield
                            id='route-name'
                            name='routeName'
                            error={!!errors.routeName && touched.routeName}
                            label={translate('t.name')}
                            value={values.routeName}
                            variant='outlined'
                            size='small'
                            type='text'
                            fullWidth
                            helperText={errors.routeName && touched.routeName ? errors.routeName : ''}
                            onChange={handleChange}
                            inputProps={{
                                form: {
                                    autocomplete: 'off'
                                }
                            }}
                            InputLabelProps={{
                                shrink: true
                            }}
                        />
                    </Form>
                );
            }}
        </Formik>
    );
};

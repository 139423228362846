import React from 'react';
import { BugReporterFormikProps, WebtrackPagesProps } from './BugReporter.type';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { Grid, TextField } from '@mui/material';
import * as Yup from 'yup';
import {
    Button,
    ContainerForm,
    CustomAutocomplete,
    DescriptionTextField,
    LoadingButton,
    TitleTextField
} from './BugReporter.style';
import DragAndDrop from '../DragAndDrop';
import HelperText from '../HelperText';
import LazyloadingAutocomplete from '../LazyloadingAutocomplete';
import JiraIssueApi from 'api/JiraIssue';
import JiraIssueProjectApi from 'api/JiraIssueProject';
import { JiraModel, JiraQueryKeys } from 'models/Jira.type';
import { JiraIssueProjectModel } from 'models/JiraIssueProject.type';

const JiraIssue = new JiraIssueApi();
const JiraIssueProject = new JiraIssueProjectApi();

const webtrackPages: WebtrackPagesProps[] = [
    { label: 'dashboard', value: 'dashboard' },
    { label: 'vehicle_status', value: 'vehicleStatus' },
    { label: 'tracking', value: 'tracking' },
    { label: 'tyres', value: 'tyres' },
    { label: 'vehicles', value: 'vehicles' },
    { label: 'data_exports', value: 'dataExports' },
    { label: 'administration', value: 'administration' },
    { label: 'global_admin', value: 'globalAdmin' },
    { label: 'help', value: 'help' },
    { label: 'profile', value: 'profile' },
    { label: 'other', value: 'other' }
];

export const BugReporterFormik: React.FC<BugReporterFormikProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();

    const formValidationSchema = Yup.object().shape({
        page: Yup.object().shape({
            label: Yup.string(),
            value: Yup.string()
        }),
        title: Yup.string().min(4, translate('t.too_short')).required(translate('t.required')),
        description: Yup.string().min(10, translate('t.too_short')).required(translate('t.required')),
        test: Yup.string(),
        jiraPriority: Yup.object()
            .shape({
                id: Yup.number(),
                description: Yup.string(),
                translationKey: Yup.string(),
                jiraIssueProject: Yup.object().shape({
                    id: Yup.number().required(translate('t.required')),
                    name: Yup.string().required(translate('t.required')),
                    translationKey: Yup.string().required(translate('t.required'))
                }),
                jiraIssue: Yup.object()
                    .shape({
                        jiraPriorityId: Yup.number().required(translate('t.required'))
                    })
                    .required(translate('t.required'))
            })
            .required(translate('t.required')),
        jiraProject: Yup.object().shape({
            id: Yup.number(),
            name: Yup.string(),
            translationKey: Yup.string()
        })
    });

    return (
        <ContainerForm>
            <Formik
                initialValues={{
                    page: webtrackPages[0],
                    title: '',
                    description: '',
                    jiraPriority: {
                        id: 11,
                        description: 'General guidance',
                        translationKey: 'jira_general_guidance',
                        jiraIssueProject: {
                            id: 2,
                            name: 'iTrack Portal',
                            translationKey: 'jira_issue_itrack_portal'
                        },
                        jiraIssue: {
                            jiraPriorityId: 3
                        }
                    },
                    jiraProject: {
                        id: 2,
                        name: 'iTrack Portal',
                        translationKey: 'jira_issue_itrack_portal'
                    }
                }}
                validationSchema={formValidationSchema}
                onSubmit={(values) => {
                    props.handleRaiseRequest(values);
                }}
                enableReinitialize={true}
                validateOnChange={true}
            >
                {(formikProps) => {
                    return (
                        <Form id='bug-reporter-form'>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6} lg={4}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={12} lg={12}>
                                            <LazyloadingAutocomplete
                                                id={'jira-project-dropdown'}
                                                label={translate('t.bug_report_in_project')}
                                                queryId={JiraQueryKeys.getLazyDropdown}
                                                query={JiraIssueProject.getTable}
                                                apiProject={''}
                                                defaultFilter={[]}
                                                optionKey={'translationKey'}
                                                optionValue={'jiraProject'}
                                                size={'small'}
                                                fullWidth
                                                data-testid='priority-bug-reporter'
                                                getOptionLabel={(item) => ({
                                                    text: translate(`jira.${item.translationKey}`),
                                                    html: undefined
                                                })}
                                                onValueChange={(
                                                    value?: number,
                                                    label?: string,
                                                    origValue?: JiraIssueProjectModel
                                                ) => {
                                                    if (origValue) {
                                                        formikProps.setFieldValue('jiraProject', origValue);
                                                        formikProps.setFieldValue('jiraPriority', {
                                                            id: 0,
                                                            description: undefined,
                                                            translationKey: undefined,
                                                            jiraIssueProject: {
                                                                id: 0,
                                                                name: undefined,
                                                                translationKey: undefined
                                                            },
                                                            jiraIssue: {
                                                                jiraPriorityId: 0
                                                            }
                                                        });
                                                    }
                                                }}
                                                customMargin={'0 0 10px 0'}
                                                helperText={
                                                    <HelperText
                                                        error={
                                                            !!formikProps.errors.jiraProject &&
                                                            !!formikProps.touched.jiraProject
                                                        }
                                                        defaultText={translate('t.required')}
                                                        text={formikProps.errors.jiraProject?.translationKey}
                                                    />
                                                }
                                                preselectedValue={{
                                                    value: formikProps?.values?.jiraProject?.id,
                                                    label: translate(
                                                        `jira.${formikProps?.values?.jiraProject?.translationKey}`
                                                    )
                                                }}
                                            />

                                            <CustomAutocomplete
                                                disableClearable
                                                id='page-auto-select'
                                                size='small'
                                                value={formikProps.values.page}
                                                options={webtrackPages}
                                                getOptionLabel={(option) =>
                                                    translate(`t.${(option as WebtrackPagesProps).label}`)
                                                }
                                                disabled={formikProps.values.jiraProject.id !== 2}
                                                hidden={formikProps.values.jiraProject.id !== 2}
                                                onChange={(e, newValue) => {
                                                    formikProps.setFieldValue('page', newValue, false);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        disabled={formikProps.values.jiraProject.id !== 2}
                                                        label={translate('t.page')}
                                                        helperText={
                                                            <HelperText
                                                                error={
                                                                    !!formikProps.errors.page &&
                                                                    !!formikProps.touched.page
                                                                }
                                                                defaultText={translate('t.required')}
                                                                text={formikProps.errors.page?.label || ''}
                                                            />
                                                        }
                                                        variant='outlined'
                                                        size='small'
                                                        key={params.id}
                                                        name={params.id}
                                                        data-testid='page-bug-reporter'
                                                    />
                                                )}
                                            />

                                            <LazyloadingAutocomplete
                                                id={'priority-dropdown'}
                                                label={translate('t.issue_type')}
                                                queryId={JiraQueryKeys.getLazyDropdownIssueType}
                                                query={JiraIssue.getTable}
                                                rows={2}
                                                apiProject={''}
                                                defaultFilter={[
                                                    {
                                                        id: 'projectId',
                                                        value: {
                                                            value: (formikProps?.values.jiraProject.id || 0).toString(),
                                                            name: 'projectId'
                                                        }
                                                    }
                                                ]}
                                                error={!!formikProps.errors.jiraPriority}
                                                helperText={
                                                    <HelperText
                                                        error={
                                                            !!formikProps.errors.jiraPriority &&
                                                            !!formikProps.touched.jiraPriority
                                                        }
                                                        defaultText={translate('t.required')}
                                                        text={formikProps.errors.jiraPriority?.translationKey}
                                                    />
                                                }
                                                querySortBy={[{ id: 'position', desc: false }]}
                                                optionKey={'translationKey'}
                                                optionValue={'jiraPriority'}
                                                size={'small'}
                                                fullWidth
                                                data-testid='priority-bug-reporter'
                                                getOptionLabel={(item) => ({
                                                    text: translate(`jira.${item.translationKey}`),
                                                    html: undefined
                                                })}
                                                onValueChange={(
                                                    value?: number,
                                                    label?: string,
                                                    origValue?: JiraModel
                                                ) => {
                                                    if (origValue) {
                                                        formikProps.setFieldValue('jiraPriority', origValue);
                                                    }
                                                }}
                                                preselectedValue={{
                                                    value: formikProps?.values?.jiraPriority?.id || 0,
                                                    label: formikProps?.values?.jiraPriority.translationKey
                                                        ? translate(
                                                              `jira.${formikProps?.values?.jiraPriority?.translationKey}`
                                                          )
                                                        : 'reset'
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={6} lg={8}>
                                    <TitleTextField
                                        id='report-title'
                                        name='title'
                                        label={translate('t.title')}
                                        variant='outlined'
                                        size='small'
                                        fullWidth
                                        data-testid='title-bug-reporter'
                                        onChange={formikProps.handleChange}
                                        error={!!formikProps.errors.title && !!formikProps.touched.title}
                                        onBlur={formikProps.handleBlur}
                                        inputProps={{
                                            form: {
                                                autocomplete: 'off'
                                            }
                                        }}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        helperText={
                                            <HelperText
                                                error={!!formikProps.errors.title && !!formikProps.touched.title}
                                                defaultText={translate('t.required')}
                                                text={formikProps.errors.title}
                                            />
                                        }
                                    />
                                    <DescriptionTextField
                                        id='report-description'
                                        name='description'
                                        label={translate('t.description')}
                                        variant='outlined'
                                        data-testid='description-bug-reporter'
                                        size='medium'
                                        fullWidth
                                        multiline
                                        rows={5}
                                        onChange={formikProps.handleChange}
                                        error={!!formikProps.errors.description && !!formikProps.touched.description}
                                        onBlur={formikProps.handleBlur}
                                        helperText={
                                            <HelperText
                                                error={
                                                    !!formikProps.errors.description &&
                                                    !!formikProps.touched.description
                                                }
                                                text={formikProps.errors.description}
                                                defaultText={translate('t.required')}
                                            />
                                        }
                                        inputProps={{
                                            form: {
                                                autocomplete: 'off'
                                            }
                                        }}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12} lg={12}>
                                    <DragAndDrop
                                        updateAttachments={props.getUploadedAttachments}
                                        setError={props.setError}
                                        data-testid='drag-and-drop-bug-reporter'
                                    />
                                </Grid>
                            </Grid>
                            <Button
                                skin='success'
                                color='primary'
                                variant='contained'
                                form='bug-reporter-form'
                                type='submit'
                                data-testid='submit-bug-reporter'
                                disabled={props.hasAttachmentError || !(formikProps.dirty && formikProps.isValid)}
                            >
                                {props.isProcessing ? <LoadingButton /> : translate('t.raise_request')}
                            </Button>
                        </Form>
                    );
                }}
            </Formik>
        </ContainerForm>
    );
};

import { atom, selector } from 'recoil';
import { AvailableFilter } from './AlertsAvailableFilters.type';

export enum FilterIds {
    MANUFACTURER = 1,
    VEHICLE_MODEL = 2,
    VEHICLE_CONFIGURATION = 3,
    VEHICLE = 4,
    DEFAULT = 5,
    VEHICLE_GROUP = 6,
    TYRE_MODEL = 7
}

export const AvailableFiltersAtom = atom<AvailableFilter[]>({
    key: 'AvailableFiltersAtom',
    default: [
        {
            id: FilterIds.MANUFACTURER,
            name: 'manufacturer',
            tableId: 'vehicleManufacturerV2',
            hidden: true,
            withFilterRemoveAlso: [],
            availableWith: []
        },
        {
            id: FilterIds.VEHICLE_MODEL,
            name: 'vehicle_model',
            tableId: 'vehicleModelV2',
            hidden: false,
            withFilterRemoveAlso: [FilterIds.VEHICLE_CONFIGURATION],
            availableWith: [FilterIds.VEHICLE_CONFIGURATION]
        },
        {
            id: FilterIds.VEHICLE_CONFIGURATION,
            name: 'tyre_position',
            tableId: 'vehicleConfiguration',
            hidden: false,
            withFilterRemoveAlso: [],
            availableWith: [FilterIds.VEHICLE_MODEL, FilterIds.VEHICLE]
        },
        {
            id: FilterIds.VEHICLE,
            name: 'vehicle',
            tableId: 'vehicle',
            hidden: false,
            withFilterRemoveAlso: [FilterIds.VEHICLE_CONFIGURATION],
            availableWith: [FilterIds.VEHICLE_CONFIGURATION]
        },
        {
            id: FilterIds.DEFAULT,
            name: 'default_alert_settings',
            tableId: 'default',
            hidden: false,
            withFilterRemoveAlso: [],
            availableWith: [],
            tooltip: {
                translationKey: 'p.settings_set_as_default_template_all_vehicles'
            }
        },
        {
            id: FilterIds.VEHICLE_GROUP,
            name: 'vehicle_group',
            tableId: 'vehicleGroupName',
            hidden: false,
            withFilterRemoveAlso: [],
            availableWith: []
        },
        {
            id: FilterIds.TYRE_MODEL,
            name: 'tyre_model',
            tableId: 'tyreModel',
            hidden: true,
            withFilterRemoveAlso: [],
            availableWith: []
        }
    ] // will be state feeded from BE, structure might change
});

export const SelectedAvailableFilterIds = atom<number[]>({
    key: 'SelectedAvailableFiltersIds',
    default: []
});

export const UnselectedAvailableFilters = selector({
    key: 'UnselectedAvailableFilters',
    get: ({ get }) => {
        const selectedFilters = get(SelectedAvailableFilterIds);
        const availableFilters = get(AvailableFiltersAtom);
        return availableFilters.filter(
            (availableFilter) => !selectedFilters.includes(availableFilter.id) && !availableFilter.hidden
        );
    }
});

export const SelectedAvailableFilters = selector({
    key: 'SelectedAvailableFilters',
    get: ({ get }) => {
        const selectedFilters = get(SelectedAvailableFilterIds);
        const availableFilters = get(AvailableFiltersAtom);
        return availableFilters.filter(
            (availableFilter) => selectedFilters.includes(availableFilter.id) && !availableFilter.hidden
        );
    }
});

export const ProcessingFilterSelection = atom({
    key: 'ProcessingFilterSelection',
    default: false
});

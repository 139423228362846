import styled from 'styled-components';
import LazyloadingAutocomplete from 'components/Mixs/LazyloadingAutocomplete/LazyloadingAutocomplete';
import DropDown from 'components/Ui/Components/UiDropDown/UiDropDown';

export const ReplaceSensorContainer = styled.div`
    padding-top: 20px;
`;

export const LazyAutocomplete = styled(LazyloadingAutocomplete)`
    & .MuiInputBase-root {
        height: 36.77px !important;
    }
`;

export const Autocomplete = styled(DropDown)`
    &&& .MuiInputBase-root {
        height: 30px !important;
    }
`;

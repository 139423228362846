import React, { useMemo } from 'react';
import UiWidgetTab from 'components/Ui/Components/UiWidgetTab/UiWidgetTab';
import AlertRefresh from './AlertRefresh.view';
import AlertsPopUpStateView from './AlertsPopUpState.view';
import AlertsList from '../AlertsList/AlertsList';
import { useTranslation } from 'react-i18next';
import TpmsAlertsQuery from '../../Queries/TpmsAlertQuery/TpmsAlertQuery';
import GpsAlertQuery from '../../Queries/GpsAlertQuery/GpsAlertQuery';
import VehicleEventQuery from 'components/Queries/VehicleEvent';
import { useRecoilValue } from 'recoil';
import { AlertsByVehicleContainer } from './TPMSAlertsListTabManager.style';
import { applyStyleByMode } from 'helpers';
import { faBook, faMapMarker, faTirePressureWarning, faTruck } from '@fortawesome/pro-solid-svg-icons';
import UiIcon from 'components/Ui/Components/UiIcon';
import { WHITE } from 'components/Ui/colors';
import { AlertsListViewProps, TabNumber, typeAlert, typeFilter } from './TPMSAlertsListTabManager.type';
import { Theme } from 'states/global/Theme';
import { ModelTpmsAlerts, TpmsAlertsQueryKeys } from 'models/TpmsAlerts.type';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { DateTime } from 'luxon';
import { CustomerSettings } from 'states/global/CustomerSettings';

export const TPMSAlertsListTabManagerContent: React.FC<AlertsListViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const { fromTimezoneToUTC } = useConverter();
    const customerSettings = useRecoilValue(CustomerSettings);
    const ThemeMode = useRecoilValue(Theme);
    let contentHeight = !props.displayRefresh ? 473 : 465;

    const tabHeaders = useMemo(() => {
        const headers = [
            {
                label: translate('t.tpms_alerts'),
                testid: 'TPMS-alerts-dashboard-button-tab',
                icon: (
                    <UiIcon
                        icon={faTirePressureWarning}
                        size='1x'
                        transform='left-4'
                        fixedWidth
                        color={applyStyleByMode({
                            theme: ThemeMode?.mode,
                            light: '#0000008a',
                            dark: WHITE
                        })}
                    />
                )
            },
            {
                label: `GPS ${translate('t.alerts')}`,
                testid: 'GPS-alerts-dashboard-button-tab',
                icon: (
                    <UiIcon
                        icon={faMapMarker}
                        size='1x'
                        transform='left-4'
                        fixedWidth
                        color={applyStyleByMode({
                            theme: ThemeMode?.mode,
                            light: '#0000008a',
                            dark: WHITE
                        })}
                    />
                )
            },

            {
                label: translate('t.historical_log'),
                testid: 'TPMS-alerts-dashboard-Historical-Log-button-tab',
                icon: (
                    <UiIcon
                        icon={faBook}
                        size='1x'
                        transform='left-4'
                        fixedWidth
                        color={applyStyleByMode({
                            theme: ThemeMode?.mode,
                            light: '#0000008a',
                            dark: WHITE
                        })}
                    />
                )
            }
        ];

        if (customerSettings.vehicle_alerts_enabled) {
            headers.splice(1, 0, {
                label: translate('t.vehicles_alerts'),
                testid: 'Vehicles-alerts-dashboard-button-tab',
                icon: (
                    <UiIcon
                        icon={faTruck}
                        size='1x'
                        transform='left-4'
                        fixedWidth
                        color={applyStyleByMode({
                            theme: ThemeMode?.mode,
                            light: '#0000008a',
                            dark: WHITE
                        })}
                    />
                )
            });
        }

        return headers;
    }, [customerSettings]);

    const tabContent = useMemo(() => {
        const contentTab = [
            {
                id: 0,
                body: (
                    <TpmsAlertsQuery
                        alertFilters={props.alertFilters}
                        customConfig={props.customConfigReactQuery.TpmsQuery}
                        currentPage={1}
                        limit={150}
                        tab={'tpms'}
                        solved={false}
                        measuredFrom={fromTimezoneToUTC(DateTime.local().minus({ days: 30 }))}
                    >
                        {({ data, isLoading }) => (
                            <AlertsList
                                {...props}
                                data={data?.items.reduce((acc: ModelTpmsAlerts[], current) => {
                                    current.notification.type !== typeFilter.BATTERY && acc.push(current);
                                    return acc;
                                }, [])}
                                alertKey='_alertTPMS'
                                testId='TPMS-alerts-dashboard-alerts-list'
                                displayAlertActions={true}
                                typeAlert={typeAlert.TPMS}
                                isLoading={isLoading}
                                showSolveButton={props.showSolveButton}
                                showValidateButton={props.showValidateButton}
                                tabNumber={0}
                                displayBody={true}
                            />
                        )}
                    </TpmsAlertsQuery>
                )
            },
            {
                id: 1,
                body: (
                    <GpsAlertQuery customConfig={props.customConfigReactQuery.GpsQuery} page={1} limit={150}>
                        {({ data, isLoading }) => (
                            <AlertsList
                                {...props}
                                data={data?.items}
                                testId='GPS-alerts-dashboard-alerts-list'
                                alertKey={'_alertGPS'}
                                displayAlertActions={false}
                                typeAlert={typeAlert.GPS}
                                isLoading={isLoading}
                                tabNumber={2}
                                displayBody={true}
                            />
                        )}
                    </GpsAlertQuery>
                )
            },

            {
                id: 3,
                body: (
                    <TpmsAlertsQuery
                        alertFilters={props.alertFilters}
                        customConfig={props.customConfigReactQuery.TpmsQuery}
                        currentPage={1}
                        limit={150}
                        solved={true}
                        tab={'log'}
                        keyQuery={TpmsAlertsQueryKeys.getNotificationSolved}
                    >
                        {({ data, isLoading }) => (
                            <AlertsList
                                {...props}
                                data={data?.items.reduce((acc: ModelTpmsAlerts[], current) => {
                                    current.notification.type !== typeFilter.BATTERY &&
                                        current.notification.type !== typeFilter.ANTENNA &&
                                        current.notification.type !== typeFilter.EMMC &&
                                        acc.push(current);
                                    return acc;
                                }, [])}
                                testId='Historical-log-dashboard-alerts-list'
                                alertKey={'_alertHistoryLog'}
                                displayAlertActions={false}
                                typeAlert={typeAlert.HistoricalLog}
                                isLoading={isLoading}
                                tabNumber={3}
                                displayBody={true}
                            />
                        )}
                    </TpmsAlertsQuery>
                )
            }
        ];

        if (customerSettings.vehicle_alerts_enabled) {
            contentTab.splice(1, 0, {
                id: 1,
                body: (
                    <VehicleEventQuery customConfig={props.customConfigReactQuery.TpmsQuery} page={1} limit={150}>
                        {({ data, isLoading }) => (
                            <AlertsList
                                {...props}
                                data={data?.items}
                                alertKey={'_alertVehicleEvent'}
                                testId='Vehicles-alerts-dashboard-alerts-list'
                                displayAlertActions={false}
                                typeAlert={typeAlert.VehicleEvent}
                                isLoading={isLoading}
                                showSolveButton={props.showSolveButton}
                                showValidateButton={false}
                                tabNumber={TabNumber.VehiclesAlerts}
                                displayBody={true}
                                gridNumber={3}
                            />
                        )}
                    </VehicleEventQuery>
                )
            });
        }

        return contentTab;
    }, [customerSettings, props.alertFilters]);

    return (
        <AlertsByVehicleContainer>
            <UiWidgetTab
                title={translate('t.alerts_by_vehicle')}
                $contentHeight={contentHeight}
                height={512}
                actionScroll={props.actionScroll}
                testid='TPMS-alerts-dashboard-widget'
                unit='px'
                setNumberTab={props.setNumberTab}
                tab={{
                    headers: tabHeaders,
                    constents: tabContent
                }}
                headerAction={
                    props.tabNumber === TabNumber.TpmsAlert ||
                    (customerSettings.vehicle_alerts_enabled && props.tabNumber === TabNumber.HistoricalLog) ||
                    (!customerSettings.vehicle_alerts_enabled && props.tabNumber === 2) ? (
                        <AlertsPopUpStateView
                            AddFilterAlertByLevel={props.AddFilterAlertByLevel}
                            setFilterByType={props.setFilterByType}
                        />
                    ) : (
                        <></>
                    )
                }
            />
            <AlertRefresh
                setEnabledQuery={props.setEnabledQuery}
                enabledQuery={props.enabledQuery}
                displayRefresh={!!props.displayRefresh}
            />
        </AlertsByVehicleContainer>
    );
};

import React from 'react';
import { useRecoilValue } from 'recoil';
import { AlertSettingsWizardButtonsViewContent } from './AlertSettingsWizardButtons.style';
import { AlertSettingsWizardButtonsProps } from './AlertSettingsWizardButtons.type';
import { useTranslation } from 'react-i18next';
import UiButton from 'components/Ui/Components/UiButton/UiButton';
import { TemperatureSliderValue } from '../AlertTemperatureSlider/atom';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { IsLoadingAlertSettings, PressureLevelsSelector } from '../AlertSettings/atom';
import { AlertConfigurationPosition } from 'components/Alert/AlertsConfigurationFilterInput/atom';
import { ALERT_ID } from '../AlertSettings/AlertSettings.type';
import { AppliedFilters } from '../AlertsFilterVehiclesBy/atom';
import { useMutation } from '@tanstack/react-query';
import RuleApi from 'api/Rule';
import { Success } from 'components/Popup/Popup';
import { RULE_TYPE, RuleModel, RuleModelPost } from 'models/Rule.type';
import useModal from 'components/CustomHooks/ModalHook';
import NotificationTireTresholdsLegend from '../NotificationTireTresholdsLegend/NotificationTireTresholdsLegend';

const MIN_ALERT_VALUE = 0;
const MAX_ALERT_VALUE = 200;

const Rule = new RuleApi();

const RulePriority = {
    [RULE_TYPE.CUSTOM]: 5,
    [RULE_TYPE.DEFAULT]: 15,
    [RULE_TYPE.WIZARD]: 10
};

const AlertSettingsWizardButtonsContent: React.FC<AlertSettingsWizardButtonsProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const temperatureSliderValue = useRecoilValue(TemperatureSliderValue);
    const { fromUserToServerUnit, convertType } = useConverter();
    const optimalColdPressure = useRecoilValue(PressureLevelsSelector(0));
    const configurationPosition = useRecoilValue(AlertConfigurationPosition);
    const coldPressureLow3 = useRecoilValue(PressureLevelsSelector(-3));
    const coldPressureLow2 = useRecoilValue(PressureLevelsSelector(-2));
    const coldPressureLow1 = useRecoilValue(PressureLevelsSelector(-1));
    const coldPressureHigh1 = useRecoilValue(PressureLevelsSelector(1));
    const coldPressureHigh2 = useRecoilValue(PressureLevelsSelector(2));
    const coldPressureHigh3 = useRecoilValue(PressureLevelsSelector(3));
    const hotPressureHigh1 = useRecoilValue(PressureLevelsSelector(11));
    const hotPressureHigh2 = useRecoilValue(PressureLevelsSelector(12));
    const hotPressureHigh3 = useRecoilValue(PressureLevelsSelector(13));
    const filters = useRecoilValue(AppliedFilters);
    const { setModalState } = useModal();
    const isLoadingAlertSettings = useRecoilValue(IsLoadingAlertSettings);

    const invalidValues = () => {
        return (
            coldPressureLow3.value === '' ||
            coldPressureLow3.value === '' ||
            coldPressureLow2.value === '' ||
            coldPressureLow1.value === '' ||
            coldPressureHigh3.value === '' ||
            coldPressureHigh2.value === '' ||
            coldPressureHigh1.value === '' ||
            hotPressureHigh3.value === '' ||
            hotPressureHigh2.value === '' ||
            hotPressureHigh1.value === '' ||
            optimalColdPressure.value === ''
        );
    };

    const getRuleLimits = () => {
        const temperature = temperatureSliderValue.map((temperatureValue) =>
            fromUserToServerUnit({ value: temperatureValue, type: convertType.temperature })
        );
        return [
            {
                notification: ALERT_ID.COLD_PRESSURE_LOW_LEVEL_3,
                limitFrom: fromUserToServerUnit({ value: MIN_ALERT_VALUE, type: convertType.pressure, fixed: 1 }),
                limitTo: fromUserToServerUnit({ value: +coldPressureLow3.value, type: convertType.pressure, fixed: 1 })
            },
            {
                notification: ALERT_ID.COLD_PRESSURE_LOW_LEVEL_2,
                limitFrom: fromUserToServerUnit({
                    value: +coldPressureLow3.value,
                    type: convertType.pressure,
                    fixed: 1
                }),
                limitTo: fromUserToServerUnit({ value: +coldPressureLow2.value, type: convertType.pressure, fixed: 1 })
            },
            {
                notification: ALERT_ID.COLD_PRESSURE_LOW_LEVEL_1,
                limitFrom: fromUserToServerUnit({
                    value: +coldPressureLow2.value,
                    type: convertType.pressure,
                    fixed: 1
                }),
                limitTo: fromUserToServerUnit({ value: +coldPressureLow1.value, type: convertType.pressure, fixed: 1 })
            },
            {
                notification: ALERT_ID.COLD_PRESSURE_HIGH_LEVEL_3,
                limitFrom: fromUserToServerUnit({
                    value: +coldPressureHigh3.value,
                    type: convertType.pressure,
                    fixed: 1
                }),
                limitTo: MAX_ALERT_VALUE
            },
            {
                notification: ALERT_ID.COLD_PRESSURE_HIGH_LEVEL_2,
                limitFrom: fromUserToServerUnit({
                    value: +coldPressureHigh2.value,
                    type: convertType.pressure,
                    fixed: 1
                }),
                limitTo: fromUserToServerUnit({ value: +coldPressureHigh3.value, type: convertType.pressure, fixed: 1 })
            },
            {
                notification: ALERT_ID.COLD_PRESSURE_HIGH_LEVEL_1,
                limitFrom: fromUserToServerUnit({
                    value: +coldPressureHigh1.value,
                    type: convertType.pressure,
                    fixed: 1
                }),
                limitTo: fromUserToServerUnit({ value: +coldPressureHigh2.value, type: convertType.pressure, fixed: 1 })
            },
            {
                notification: ALERT_ID.HOT_PRESSURE_HIGH_LEVEL_3,
                limitFrom: fromUserToServerUnit({
                    value: +hotPressureHigh3.value,
                    type: convertType.pressure,
                    fixed: 1
                }),
                limitTo: MAX_ALERT_VALUE
            },
            {
                notification: ALERT_ID.HOT_PRESSURE_HIGH_LEVEL_2,
                limitFrom: fromUserToServerUnit({
                    value: +hotPressureHigh2.value,
                    type: convertType.pressure,
                    fixed: 1
                }),
                limitTo: fromUserToServerUnit({ value: +hotPressureHigh3.value, type: convertType.pressure, fixed: 1 })
            },
            {
                notification: ALERT_ID.HOT_PRESSURE_HIGH_LEVEL_1,
                limitFrom: fromUserToServerUnit({
                    value: +hotPressureHigh1.value,
                    type: convertType.pressure,
                    fixed: 1
                }),
                limitTo: fromUserToServerUnit({ value: +hotPressureHigh2.value, type: convertType.pressure, fixed: 1 })
            },
            {
                notification: ALERT_ID.TEMPERATURE_LEVEL_3,
                limitFrom: temperature[2],
                limitTo: 999
            },
            {
                notification: ALERT_ID.TEMPERATURE_LEVEL_2,
                limitFrom: temperature[1],
                limitTo: temperature[2]
            },
            {
                notification: ALERT_ID.TEMPERATURE_LEVEL_1,
                limitFrom: temperature[0],
                limitTo: temperature[1]
            }
        ];
    };

    const prepareWizardData = (): RuleModelPost => {
        let data: RuleModelPost = {};
        data.ruleType = props.ruleType;

        if (optimalColdPressure.value) {
            data.optimalColdPressure = fromUserToServerUnit({
                value: +optimalColdPressure.value,
                type: convertType.pressure,
                fixed: 1
            });
        }
        if (props.wheelId) {
            data.position = props.position;
            data.wheel = props.wheelId;
        }
        if (filters.vehicle) {
            data.vehicle = filters.vehicle.id;
            data.position = configurationPosition.position.length ? configurationPosition.position : null;
        }

        if (filters.vehicleModelV2) {
            data.vehicleModelId = filters.vehicleModelV2.id;
            data.position = configurationPosition.position.length ? configurationPosition.position : null;
        }

        if (filters.vehicleGroupName) {
            data.vehicleGroupId = filters.vehicleGroupName.id;
        }

        if (filters.tyreModel) {
            data.tyreModelId = filters.tyreModel.id;
            data.position = null;
        }

        data.ruleLimit = getRuleLimits();

        data = { ...data, ...getVehicleParams() };

        if (
            Array.isArray(data.position) &&
            (data.position.length === filters.vehicle?.vehicleConfigurationV2.wheels ||
                data.position.length === filters.vehicleModelV2?.vehicleConfiguration.wheels)
        ) {
            data.position = null;
        }

        data.priority = RulePriority[props.ruleType];
        return data;
    };

    const saveAlerts = () => {
        mutationSave.mutate();
    };

    const mutationSave = useMutation<unknown, unknown, void>({
        mutationFn: () => Rule.post<RuleModel, RuleModelPost>({ data: prepareWizardData() }),
        onSuccess: () => {
            Success({
                text: `${translate('t.alerts_settings_has_been_updated')}`
            });
            setModalState({
                isOpen: false,
                buttons: undefined
            });
        }
    });

    const getVehicleParams = () => {
        let params: any = {};
        if (filters.vehicleConfiguration) {
            params.vehicleConfigurationId = filters.vehicleConfiguration.id;
            params.position = configurationPosition.position.length ? configurationPosition.position : null;
        }

        if (filters.vehicleModelV2) {
            params.vehicleModelId = filters.vehicleModelV2.id;
            if (params['vehicleConfigurationId']) {
                delete params['vehicleConfigurationId'];
            }
        }

        if (filters.vehicleManufacturerV2) {
            params.tyreManufacturer = filters.vehicleManufacturerV2.id;
        }
        return params;
    };

    return (
        <>
            <AlertSettingsWizardButtonsViewContent
                data-testid='AlertSettingsWizardButtonsContentLeft'
                display='flex'
                justifyContent='left'
                sx={{ width: '-webkit-fill-available' }}
            >
                <NotificationTireTresholdsLegend />
            </AlertSettingsWizardButtonsViewContent>

            <AlertSettingsWizardButtonsViewContent
                data-testid='AlertSettingsWizardButtonsContent'
                display='flex'
                justifyContent='right'
            >
                {!props.hidden && (
                    <UiButton
                        disabled={isLoadingAlertSettings || invalidValues() || props.disabled}
                        //loading={mutationSave.isLoading}
                        loading={false}
                        data-testid='AlertsWizardSaveButton'
                        variant='contained'
                        onClick={saveAlerts}
                    >
                        {translate('t.save')}
                    </UiButton>
                )}
            </AlertSettingsWizardButtonsViewContent>
        </>
    );
};

export default AlertSettingsWizardButtonsContent;

import React from 'react';
import {
    PopUpFilterContainer,
    GroupOption,
    CustomIconButton,
    FilterContent,
    FilterLabel,
    FilterCover
} from './SystemAlertsListTabManager.style';
import { PopUpalert, typeFilter } from './SystemAlertsListTabManager.type';
import { ChipActionsFilter } from '../AlertsList/AlertsList.style';
import { useTranslation } from 'react-i18next';
import { MoreVert } from '@mui/icons-material';
import UiLegend from 'components/Ui/Components/UiLegend/UiLegend';
import { applyStyleByMode } from 'helpers';

//todo: level filter disabled until BE implements completetly the feature

const AlertsPopUpStateView: React.FC<PopUpalert> = (props): JSX.Element => {
    const { t: translate } = useTranslation();

    return (
        <div>
            <UiLegend
                minWidth={265}
                width={300}
                title={applyStyleByMode({ styleJade: translate('t.filters') })}
                content={
                    <PopUpFilterContainer>
                        {/* <style.GroupOption>
                            <style.FilterCover>
                                <style.FilterLabel>
                                    {translate('t.level')} {translate('t.filters')}
                                </style.FilterLabel>
                                <style.FilterContent>
                                    <styleAlertCustom.ChipLevel
                                        size='small'
                                        data-testid='System-Alerts-filter-level1-button'
                                        level={1}
                                        label={`${translate('t.level')} 1`}
                                        clickable
                                        onClick={() => props.AddFilterAlertByLevel(1)}
                                    />

                                    <styleAlertCustom.ChipLevel
                                        size='small'
                                        data-testid='System-Alerts-filter-level2-button'
                                        level={2}
                                        label={`${translate('t.level')} 2`}
                                        clickable
                                        onClick={() => props.AddFilterAlertByLevel(2)}
                                    />

                                    <styleAlertCustom.ChipLevel
                                        size='small'
                                        data-testid='System-Alerts-filter-level3-button'
                                        level={3}
                                        label={`${translate('t.level')} 3`}
                                        clickable
                                        onClick={() => props.AddFilterAlertByLevel(3)}
                                    />
                                </style.FilterContent>
                            </style.FilterCover>
                        </style.GroupOption> */}

                        <GroupOption>
                            <FilterCover>
                                <FilterLabel>
                                    {translate('t.type')} {translate('t.filters')}
                                </FilterLabel>
                                <FilterContent>
                                    <ChipActionsFilter
                                        size='small'
                                        data-testid='System-Alerts-filter-antenna-button'
                                        label={`${translate('t.antenna')}`}
                                        clickable
                                        onClick={() => props.setFilterByType(typeFilter.ANTENNA)}
                                    />

                                    <ChipActionsFilter
                                        size='small'
                                        data-testid='System-Alerts-filter-hot-battery-button'
                                        label={`${translate('t.batteryVoltage')}`}
                                        clickable
                                        onClick={() => props.setFilterByType(typeFilter.BATTERY)}
                                    />
                                </FilterContent>
                                <FilterContent>
                                    <ChipActionsFilter
                                        size='small'
                                        data-testid='System-Alerts-filter-emmc-button'
                                        label={`EMMC`}
                                        clickable
                                        onClick={() => props.setFilterByType(typeFilter.EMMC)}
                                    />

                                    <ChipActionsFilter
                                        size='small'
                                        data-testid='System-Alerts-filter-inactivehub-button'
                                        label={`${translate('t.inactiveHub')}`}
                                        clickable
                                        onClick={() => props.setFilterByType(typeFilter.INACTIVE_HUB)}
                                    />
                                </FilterContent>
                            </FilterCover>
                        </GroupOption>
                    </PopUpFilterContainer>
                }
                root={
                    <CustomIconButton testid='Widget-System-Alerts-filter-button' size='small'>
                        <MoreVert />
                    </CustomIconButton>
                }
            />
        </div>
    );
};

export default AlertsPopUpStateView;

import { atom } from 'recoil';

export const FilterPeriod = atom<number | undefined>({
    key: 'FilterPeriod',
    default: undefined
});

export const SearchVehicle = atom<string>({
    key: 'SearchVehicle',
    default: ''
});

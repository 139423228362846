export const PRIMARY = '#3a3633';
export const PRIMARY_DARK = '#191919';
export const PRIMARY_SEMI_LIGHT = '#464544';
export const PRIMARY_LIGHT = '#5a5a5a';
export const SECONDARY = '#cdc9c6';
export const ERROR = '#a90329';
export const ERROR_DARK = '#860220';
export const SUCCESS = '#5f945b';
export const SUCCESSDARK = '#4f714f';
export const LINK = '#0071c7';
export const LINK_DARK = '#004880';
export const BACKGROUND_LIGHT = '#fff';
export const BACKGROUND = '#fdfdfd';
export const BACKGROUND_DARK = '#ebebeb';
export const GRAY = '#4a4a4a';
export const GRAY_LIGHT = '#f1f1f1';
export const THIRD = '#5AC38C';
export const THIRD_DARK = '#4ead7b';
export const BLUE = '#0091d9';
export const BLUE_BRIDGESTONE = '#00ceff';
// export const TEXT_BLACK_OLD = '#000000de';
export const BRANDIES_BLUE = '#0070ff';
export const LINKS_BLUE = '#0068EA';

// Color Jade
export const BLACK = '#000000';
export const WHITE = '#FFFFFF';
export const BACKGROUND_GREY = '#F8F8F8';
export const BACKGROUND_GREEN = '#0B9444';
export const BACKGROUND_BLUE = '#009BD5';
export const BRIGHT_SKY_BLUE = '#4CBDEC';
export const DARK_STEEL_GREY = '#01587D';
export const DARK_SLATE_GREY = '#57585A';
export const LEAD_GREY = '#686C74';
export const BACKGROUND_BLACK = '#181818';
export const BACKGROUND_RED = '#9d1013';
export const BACKGROUND_GREEN_DARK = '#0B9444';
export const BACKGROUND_RED_DARK = '#ed2124';
export const COLOR_GREY = '#232323';
export const COLOR_GREY_2 = '#989898';
export const COLOR_GREY_3 = '#b7b7b7';
export const COLOR_GREY_4 = '#d2d2d2';
export const COLOR_GREY_6 = '#F2F2F2';
export const COLOR_GREY_DARK = '#6e6e6e';
export const BACKGROUND_TABLE = '#F2F2F2';
export const BLUE_JADE = '#2196F3';
export const ORANGE = '#f39c12';

export const BACKGROUND_DARK_MODE = '#161c24';
export const BACKGROUND_PAPER_DARK_MODE = '#1d2630';
export const PRIMARY_DARK_MODE = '#0B9444';
export const BACKGROUND_TABLE_DARK = '#485562';
export const BACKGROUND_TABLE_DARK_HOVER = '#304050';
export const SECONDARY_DARK_MODE = '#787878';

export const ALERT_OPTIMAL_PRESSURE = '#c5ffc5';
export const ALERT_LEVEL_0 = '#ffffff';
export const ALERT_LEVEL_0_CARD = '#f1f1f1';
export const ALERT_LEVEL_1 = '#fff4c5';
export const ALERT_LEVEL_2 = '#ffe8d1';
export const ALERT_LEVEL_3 = '#ffcccc';

export const ALERT_OPTIMAL_PRESSURE_DARK = '#338a33';
export const ALERT_LEVEL_0_DARK = '#3a414a';
export const ALERT_LEVEL_1_DARK = '#a39300';
export const ALERT_LEVEL_2_DARK = '#ed841b';
export const ALERT_LEVEL_3_DARK = '#c12424';

export const TEXT_ALERT_LEVEL_1 = '#816F19';
export const TEXT_ALERT_LEVEL_2 = '#B04E02';
export const TEXT_ALERT_LEVEL_3 = '#C00808';

export const ALERT_LEVEL_0_HOVER = '#ededed';
export const ALERT_LEVEL_1_HOVER = '#ffed9e';
export const ALERT_LEVEL_2_HOVER = '#ffd0a3';
export const ALERT_LEVEL_3_HOVER = '#ff9f9f';

export const ALERT_LEVEL_0_DARK_HOVER = '#4d5662';
export const ALERT_LEVEL_1_DARK_HOVER = '#d7b50e';
export const ALERT_LEVEL_2_DARK_HOVER = '#ed9944';
export const ALERT_LEVEL_3_DARK_HOVER = '#e35a5a';

export const ALERT_LEVEL_1_TEMP_AND_PRESS = '#C6C71F';
export const ALERT_LEVEL_2_TEMP_AND_PRESS = '#FFA559';
export const ALERT_LEVEL_3_TEMP_AND_PRESS = '#FA000C';

export const MAP_CURRENT_VEHICLE = '#304ffe';
export const BORDER_MAP_CURRENT_VEHICLE = '#0127fd';
export const MAP_OTHER_VEHICLE = '#ffea00';
export const BORDER_MAP_OTHER_VEHICLE = '#e1cf00';

// Color Old Portal
export const PRIMARY_OLD_PORTAL = '#3f51b5';

export const HexToRGB = (color: string, alpha: string) => {
    const R = parseInt(color.substring(1, 3), 16);
    const G = parseInt(color.substring(3, 5), 16);
    const B = parseInt(color.substring(5, 7), 16);
    return `rgba(${R},${G},${B},${alpha})`;
};

export const RANKNA = '#888787'; // scrap
export const RANKNA2 = '#a8a8a8'; // repair
export const RANK0 = '#029BE0';
export const RANK1 = '#E5AC02';
export const RANK2 = '#EE821E';
export const RANK3 = '#BD1620';
export const RANK4 = '#00f'; // initial check
export const EXPOSURE_1 = '#e0e0eb'; // gray
export const EXPOSURE_2 = '#cfffd2'; // pale green
export const EXPOSURE_3 = '#7bd575'; // dark green
export const EXPOSURE_4 = '#ffffb0'; // yellow
export const EXPOSURE_5 = '#ffd99f'; // orange
export const EXPOSURE_6 = '#ff9c9c'; // red
export const EXPOSURE_7 = '#d51111'; // dark red

//Predicted temperature alert level colors
export const ALERT_LEVEL_0_PRED_TEMP = '#3DFF2B';
export const ALERT_LEVEL_1_PRED_TEMP = '#FFA559';
export const ALERT_LEVEL_2_PRED_TEMP = '#FA000C';
export const ALERT_LEVEL_1_PRED_TEMP_DARK = '#ED841B';
export const ALERT_LEVEL_2_PRED_TEMP_DARK = '#C12424';

export const RED_ICON = '#FF1616';

export const INACTIVE_LIST = '#b3b0b0';

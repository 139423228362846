import React from 'react';
import * as style from './Dashboard.style';
import Grid from '@mui/material/Grid';
import TPMSAlertsListTabManager from '../../components/AlertNotification/TPMSAlertsListTabManager/TPMSAlertsListTabManager';
import SystemTotals from '../../components/Mixs/SystemTotals/SystemTotals';
import VehicleStats from '../../components/Vehicle/VehicleStats/VehicleStats';
import AlertsBreakdown from '../../components/Alert/AlertsBreakdown/AlertsBreakdown';
import Map from '../../components/DashboardMap';
import { useRecoilValue } from 'recoil';
import { User } from '../../states/global/User';
import Hidden from '@mui/material/Hidden';
import BesRankOverview from '../../components/Mixs/BesRankOverview';
import { CustomerSettings } from 'states/global/CustomerSettings';
import SystemAlertsListTabManager from 'components/AlertNotification/SystemAlertsListTabManager/SystemAlertsListTabManager';

export const DashboardContent: React.FC = (): JSX.Element => {
    const user = useRecoilValue(User);
    const customerSettings = useRecoilValue(CustomerSettings);

    const getSystemTotalsStage = (systemTotalsStage: number): number => {
        switch (systemTotalsStage) {
            case 0:
                return 470;
            case 1:
                return 500;
            case 2:
                return 664;
            default:
                return 470;
        }
    };

    return (
        <style.DashboardContent data-testid='DashboardContent'>
            <Grid spacing={2} container style={{ paddingBottom: '1.5%' }}>
                <Hidden only={['sm', 'xs']}>
                    <Grid item xs={12} sm={5} md={3}>
                        <TPMSAlertsListTabManager showSolveButton={true} showValidateButton={true} />
                    </Grid>
                    <Grid item xs={12} sm={5} md={3}>
                        <SystemAlertsListTabManager />
                    </Grid>
                </Hidden>
                <Hidden only={['sm', 'xs']}>
                    <Grid item xs={12} sm={7} md={6}>
                        <VehicleStats />
                    </Grid>
                </Hidden>
                <Hidden only={['md', 'lg', 'xl']}>
                    <Grid item xs={12} sm={5} md={4}>
                        <TPMSAlertsListTabManager showSolveButton={true} showValidateButton={true} />
                    </Grid>
                </Hidden>
                <Hidden only={['md', 'lg', 'xl']}>
                    <Grid item xs={12} sm={5} md={4}>
                        <SystemAlertsListTabManager />
                    </Grid>
                </Hidden>
                <Grid item xs={12} sm={6} md={6}>
                    <Grid item md={12} style={{ paddingBottom: '2.5%' }}>
                        <SystemTotals />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} style={{ paddingBottom: '2.5%' }}>
                        <AlertsBreakdown />
                    </Grid>
                    {customerSettings.mdd_bes && (
                        <Grid item xs={12} md={12} lg={12} style={{ paddingBottom: '2.5%' }}>
                            <BesRankOverview />
                        </Grid>
                    )}
                </Grid>
                <Hidden only={['md', 'lg', 'xl']}>
                    <Grid item xs={12} md={8}>
                        <VehicleStats />
                    </Grid>
                </Hidden>
                <Grid item xs={12} md={6}>
                    <Map
                        enableDrawing={true}
                        width={100}
                        height={user ? getSystemTotalsStage(user?.userSetting?.systemTotalsStage) : 470}
                    />
                </Grid>
            </Grid>
        </style.DashboardContent>
    );
};

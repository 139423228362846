import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { Form, Formik, FormikProps } from 'formik';
import { Grid, TextField, DialogActions } from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import UiButton from 'components/Ui/Components/UiButton/UiButton';
import UiDatePicker from 'components/Ui/Components/UiDatePicker/UiDatePicker';
import { DateTime } from 'luxon';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { InitialValuesType, ShiftTimeCalendarFormProps } from './ShiftTimeCalendarAction.type';

export const ShiftTimeCalendarForm: React.FC<ShiftTimeCalendarFormProps> = ({
    handleSubmit,
    inicialvaluesFormik,
    scheduler,
    teamShiftTimeData,
    loading
}): JSX.Element => {
    const { t: translate } = useTranslation();
    const { fromUTCtoUserTimezone } = useConverter();

    const schemaValidateForm = Yup.object().shape({
        start: Yup.string(),
        shiftTime: Yup.object()
            .shape({
                id: Yup.number().required('p.this_field_is_required'),
                startTime: Yup.string().required('p.this_field_is_required'),
                endTime: Yup.string().required('p.this_field_is_required')
            })
            .required('p.this_field_is_required')
    });

    return (
        <Formik
            initialValues={inicialvaluesFormik}
            onSubmit={(values: InitialValuesType) => {
                handleSubmit(values);
            }}
            validationSchema={schemaValidateForm}
        >
            {(propsFormik: FormikProps<InitialValuesType>) => {
                const { errors, setFieldValue, values } = propsFormik;

                const setActionData = (newData: DateTime | null) => {
                    setFieldValue('start', newData);
                };

                return (
                    <Form>
                        <Grid container justify-content='flex-start' direction='row'>
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <UiDatePicker
                                    label={translate('t.date')}
                                    onlyDate
                                    value={values?.start}
                                    onDateChange={setActionData}
                                    testId='TeamShiftTIme-uiDatePicker-date'
                                />
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <Autocomplete
                                    options={teamShiftTimeData}
                                    getOptionLabel={(time) => {
                                        return `${fromUTCtoUserTimezone({
                                            date: time.startTime?.replace(
                                                '1970-01-01',
                                                values?.start?.toFormat('yyyy-MM-dd')
                                            ),
                                            format: 'time'
                                        })} - ${fromUTCtoUserTimezone({
                                            date: time.endTime?.replace(
                                                '1970-01-01',
                                                values?.start?.toFormat('yyyy-MM-dd')
                                            ),
                                            format: 'time'
                                        })} | ${time?.teamShiftName?.name}`;
                                    }}
                                    onChange={(e, time) => {
                                        setFieldValue('shiftTime', time ? time : null);
                                    }}
                                    defaultValue={
                                        teamShiftTimeData.length
                                            ? teamShiftTimeData.find((time) => time.id === values.shiftTime?.id || '')
                                            : undefined
                                    }
                                    size='small'
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={translate('t.shift_time')}
                                            variant='outlined'
                                            name='shiftTime'
                                            value={values.shiftTime}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            helperText={errors.shiftTime && translate('p.this_field_is_required')}
                                            error={!!errors.shiftTime}
                                            data-testid='shiftTime-autocomplete'
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <DialogActions>
                                    <UiButton
                                        testid='Cancel-Form-Button-SchedulerActions'
                                        variant='outlined'
                                        type='button'
                                        onClick={scheduler.close}
                                    >
                                        {translate('t.cancel')}
                                    </UiButton>
                                    <UiButton
                                        testid='Save-Form-Button-SchedulerActions'
                                        variant='contained'
                                        type='submit'
                                        skin='success'
                                        disabled={loading}
                                        loading={loading}
                                    >
                                        {translate('t.save')}
                                    </UiButton>
                                </DialogActions>
                            </Grid>
                        </Grid>
                    </Form>
                );
            }}
        </Formik>
    );
};

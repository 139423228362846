import { ColorLens } from '@mui/icons-material';
import React from 'react';
import { MenuOption } from './MenuOption.type';
import Dashboard from 'pages/Dashboard';
import TabletActivation from 'pages/TabletActivation';
import FaultySensorReports from 'pages/FaultySensorReports';
import HubNotify from 'pages/HubNotify/HubNotify';
import TempPressAlertLevels from 'pages/TempPressAlertLevels';
import Announcements from 'pages/Announcements';
import WheelTPMS from 'pages/WheelTPMS/WheelTPMS';
import NotificationSettings from 'pages/NotificationSettings/NotificationSettings';
import Tyre from 'pages/TrackingMap';
import Vehicle from 'pages/Vehicle';
import VehicleTypes from 'pages/VehicleTypes';
import Profile from 'pages/Profile/Profile';
import TrackLog from 'pages/TrackLog/TrackLog';
import UsersAdministration from 'pages/UsersAdministration';
import Permission from 'pages/Permission';
import Areas from 'pages/Areas';
import ExportedReports from 'pages/ExportedReports/ExportedReports';
import HubsAdministration from 'pages/HubsAdministration/HubsAdministration';
import TyrePage from 'pages/Tyre';
import CustomerReports from 'pages/CustomerReports';
import CustomersGlobalAdmin from 'pages/CustomersGlobalAdmin/CustomersGlobalAdmin';
import SpeedIncidents from 'pages/SpeedIncidents/SpeedIncidents';
import NewHubs from 'pages/NewHubs';
import UserAction from 'pages/UserAction';
import TemperaturePressure from 'pages/TemperaturePressure/TemperaturePressure';
import ExcessiveAcc from 'pages/ExcessiveAcc/ExcessiveAcc';
import Routes from 'pages/Routes/Routes';
import TemperaturePredictionExport from 'pages/TemperaturePredictionExport';
import TyreManufacturerAndModel from 'pages/TyreManufacturerAndModel';
import VehicleManufacturerAndModel from 'pages/VehicleManufacturerAndModel';

import {
    faAbacus,
    faBriefcase,
    faBullhorn,
    faBullseye,
    faCarTilt,
    faCarWrench,
    faDesktop,
    faExclamationTriangle,
    faFireFlameSimple,
    faGaugeHigh,
    faGaugeSimpleMax,
    faHdd,
    faServer,
    faHouseChimney,
    faLightbulb,
    faList,
    faListAlt,
    faMap,
    faDatabase,
    faMapMarker,
    faRandom,
    faRoad,
    faShippingFast,
    faTabletAndroid,
    faTachometerFast,
    faTire,
    faTirePressureWarning,
    faTruck,
    faTruckFront,
    faTruckMoving,
    faUnlink,
    faUser,
    faUsers,
    faUsersCog,
    faWrench,
    faCarBus,
    faTruckContainer,
    faTemperatureHalf,
    faFileChartColumn,
    faChartPie,
    faBells,
    faTireRugged,
    faChartMixedUpCircleCurrency
} from '@fortawesome/pro-solid-svg-icons';
import SpeedHeatmap from 'pages/SpeedHeatmap';
import ElevationHeatmap from 'pages/ElevationHeatmap';
import GPSHeatmap from 'pages/GpsHeatmap';
import TemperatureHeatmap from 'pages/TemperatureHeatmap';
import AccelerationHeatmap from 'pages/AccelerationHeatmap';
import HeatmapPlayground from 'pages/HeatmapPlayground';
import VehicleStatus from 'pages/VehicleStatus';
import AccelerometerLog from 'pages/AccelerometerLog';
import Sensors from 'pages/Sensors/Sensors';
import AreaEditor from 'pages/AreaEditor';
import VibrationHeatmap from 'pages/VibrationHeatmap/VibrationHeatmap';
import AreaEntries from 'pages/AreaEntries';
import OverSpeeding from 'pages/OverSpeeding';
import TemperatureNotifications from 'pages/TemperatureNotifications';
import TempPressNotifications from 'pages/TempPressNotifications';
import CustomerReport from 'pages/CustomerReport/CustomerReport';
import VibrationZHeatmap from 'pages/VibrationZHeatmap/VibrationZHeatmap';
import HubsDevice from 'pages/HubsDevice/HubsDevice';
import CustomerSettings from 'pages/CustomerSettings/CustomerSettings';
import ReportPermissions from 'pages/ReportPermissions/ReportPermissions';
import Statistics from 'pages/Statistics/Statistics';
import { ROLES } from 'variables';
import TemperatureExposure from 'pages/TemperatureExposure/TemperatureExposure';
import BesSevereDriving from 'pages/BesSevereDriving/BesSevereDriving';
import AdminHubs from 'pages/AdminHubs/AdminHubs';
import TyreLifespanReport from 'pages/TyreLifespanReport/TyreLifespanReport';
import TyreLifespanComparison from 'pages/TyreLifespanComparison/TyreLifespanComparison';
import SeverityRank from 'pages/SeverityRank/SeverityRank';
import SetAlerts from 'pages/SetAlerts/SetAlerts';
import QuicksightReports from 'pages/QuicksightReports/QuicksightReports';
import AlertNotificationManagment from 'pages/AlertNotificationManagment/AlertNotificationManagment';
import HubsUpdater from 'pages/HubsUpdater/HubsUpdater';
import HubUpdaterBatches from 'pages/HubUpdaterBatches/HubUpdaterBatches';
import SlopeInclinationHeatmap from 'pages/SlopeInclinationHeatmap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/*
    Rules for adding Menu level 2 and 3:

    the level 2 menu key must have the following formula:

    key: '{MenuLevel1key}-menu2-{MenuLevel2key}'.

    the formula for the level 3 menu key:
    key: '{MenuLevel2key}-menu3-{MenuLevel3key}'.

    With this formula you can open by default the level 3 menus when refreshing the page. 

    Note: You can see an example inside the menu (key: 'data-exports')
*/

const Menu: MenuOption[] = [
    {
        path: '/dashboard',
        title: 't.dashboard',
        icon: <FontAwesomeIcon icon={faHouseChimney} />,
        requiredRoles: [
            ROLES.ROLE_SUPER_ADMIN,
            ROLES.ROLE_BASE_USER,
            ROLES.ROLE_ENGINEERING,
            ROLES.ROLE_TYRE_BAY_USER,
            ROLES.ROLE_TECHNICAL_USER,
            ROLES.ROLE_TECHNICAL_ADMIN,
            ROLES.ROLE_ADMIN
        ],
        key: 'dashboard',
        component: <Dashboard />,
        submenu: []
    },
    {
        path: '/vehicle-status',
        title: 't.vehicle_status',
        icon: <FontAwesomeIcon icon={faCarWrench} />,
        requiredRoles: [
            ROLES.ROLE_SUPER_ADMIN,
            ROLES.ROLE_BASE_USER,
            ROLES.ROLE_ENGINEERING,
            ROLES.ROLE_TYRE_BAY_USER,
            ROLES.ROLE_TECHNICAL_USER,
            ROLES.ROLE_TECHNICAL_ADMIN,
            ROLES.ROLE_ADMIN
        ],
        key: 'vehicle_status',
        component: <VehicleStatus />,
        submenu: []
    },
    {
        path: '/driving_assistance',
        title: 't.driving_assistance',
        icon: <FontAwesomeIcon icon={faTire} />,
        requiredRoles: [
            ROLES.ROLE_SUPER_ADMIN,
            ROLES.ROLE_BASE_USER,
            ROLES.ROLE_ENGINEERING,
            ROLES.ROLE_TYRE_BAY_USER,
            ROLES.ROLE_TECHNICAL_USER,
            ROLES.ROLE_TECHNICAL_ADMIN,
            ROLES.ROLE_ADMIN
        ],
        key: 'driving_assistance',
        component: <BesSevereDriving />,
        submenu: [],
        bes: true,
        besDrivingAssistance: true
    },
    {
        path: '/customer-reports',
        title: 't.customer-reports',
        icon: <FontAwesomeIcon icon={faLightbulb} />,
        requiredRoles: [ROLES.ROLE_SUPER_ADMIN],
        key: 'customer_reports',
        component: <CustomerReports />,
        submenu: [],
        hiddenMenu: true
    },
    {
        title: 't.heatmaps',
        icon: <FontAwesomeIcon icon={faFireFlameSimple} />,
        requiredRoles: [
            ROLES.ROLE_SUPER_ADMIN,
            ROLES.ROLE_BASE_USER,
            ROLES.ROLE_ENGINEERING,
            ROLES.ROLE_TYRE_BAY_USER,
            ROLES.ROLE_TECHNICAL_USER,
            ROLES.ROLE_TECHNICAL_ADMIN,
            ROLES.ROLE_ADMIN
        ],
        key: 'heatmaps',
        heatmap: true,
        submenu: [
            {
                path: '/speed',
                title: 't.speed',
                icon: <FontAwesomeIcon icon={faShippingFast} />,
                requiredRoles: [],
                key: 'speed',
                component: <SpeedHeatmap />,
                submenu: [],
                heatmapSpeed: true
            },
            {
                path: '/elevation-heatmap',
                title: 't.elevation',
                icon: <FontAwesomeIcon icon={['fas', 'mountains']} />,
                requiredRoles: [],
                key: 'elevation-heatmap',
                component: <ElevationHeatmap />,
                submenu: [],
                heatmapElevation: true
            },
            {
                path: '/gps-heatmap',
                title: 't.gps',
                icon: <FontAwesomeIcon icon={['fas', 'location-dot']} />,
                requiredRoles: [],
                key: 'gps-heatmap',
                component: <GPSHeatmap />,
                submenu: [],
                heatmapSatellite: true
            },
            {
                path: '/temperature',
                title: 't.temperature',
                icon: <FontAwesomeIcon icon={['fas', 'temperature-full']} />,
                requiredRoles: [],
                key: 'temperature-heatmap',
                component: <TemperatureHeatmap />,
                submenu: [],
                heatmapTemperature: true
            },
            {
                path: '/acceleration',
                title: 't.longitudinal_acceleration',
                icon: <FontAwesomeIcon icon={faGaugeSimpleMax} />,
                requiredRoles: [],
                key: 'acceleartion-heatmap',
                component: <AccelerationHeatmap />,
                submenu: [],
                heatmapLongitudinalAcceleration: true
            },
            {
                path: '/vibration-heatmap-total',
                title: 't.vibration_total',
                icon: <FontAwesomeIcon icon={faTruckMoving} />,
                requiredRoles: [],
                key: 'vibration-heatmap',
                component: <VibrationHeatmap />,
                submenu: [],
                heatmapTotalVibration: true
            },
            {
                path: '/vertical-vibration-z',
                title: 't.vertical_vibration',
                icon: <FontAwesomeIcon icon={faTruckFront} />,
                requiredRoles: [],
                key: 'vertical-vibration-z',
                component: <VibrationZHeatmap />,
                submenu: [],
                heatmapVerticalVibration: true
            },
            {
                path: '/slope-inclination',
                title: 't.slope_inclination',
                icon: <FontAwesomeIcon icon={faShippingFast} />,
                requiredRoles: [],
                key: 'speed',
                component: <SlopeInclinationHeatmap />,
                submenu: [],
                heatmapSlopeInclination: true
            },
            {
                path: '/speed-heatmap-playground',
                title: 't.heatmap_playground',
                icon: <FontAwesomeIcon icon={faShippingFast} />,
                requiredRoles: [ROLES.ROLE_SUPER_ADMIN],
                key: 'tracking-speed-heatmap',
                component: <HeatmapPlayground />,
                submenu: [],
                hiddenMenu: true
            }
        ]
    },
    {
        title: 't.tracking',
        icon: <FontAwesomeIcon icon={faMapMarker} />,
        requiredRoles: [
            ROLES.ROLE_SUPER_ADMIN,
            ROLES.ROLE_ENGINEERING,
            ROLES.ROLE_TYRE_BAY_USER,
            ROLES.ROLE_TECHNICAL_USER,
            ROLES.ROLE_TECHNICAL_ADMIN,
            ROLES.ROLE_ADMIN,
            ROLES.ROLE_BASE_USER
        ],
        open: false,
        key: 'tracking',
        submenu: [
            {
                path: '/map',
                title: 't.map',
                icon: <FontAwesomeIcon icon={faMapMarker} />,
                requiredRoles: [
                    ROLES.ROLE_SUPER_ADMIN,
                    ROLES.ROLE_ENGINEERING,
                    ROLES.ROLE_TYRE_BAY_USER,
                    ROLES.ROLE_TECHNICAL_USER,
                    ROLES.ROLE_TECHNICAL_ADMIN,
                    ROLES.ROLE_ADMIN,
                    ROLES.ROLE_BASE_USER
                ],
                key: 'tracking-map',
                component: <Tyre />,
                submenu: []
            },
            {
                path: '/areas',
                title: 't.areas',
                icon: <FontAwesomeIcon icon={faMap} />,
                requiredRoles: [
                    ROLES.ROLE_SUPER_ADMIN,
                    ROLES.ROLE_ENGINEERING,
                    ROLES.ROLE_TYRE_BAY_USER,
                    ROLES.ROLE_TECHNICAL_USER,
                    ROLES.ROLE_TECHNICAL_ADMIN,
                    ROLES.ROLE_ADMIN,
                    ROLES.ROLE_BASE_USER
                ],
                key: 'tracking-areas',
                component: <Areas />,
                submenu: []
            },
            {
                path: '/area-editor',
                title: 't.area_editor',
                icon: <FontAwesomeIcon icon={faExclamationTriangle} />,
                requiredRoles: [ROLES.ROLE_SUPER_ADMIN, ROLES.ROLE_ADMIN],
                key: 'area-editor',
                component: <AreaEditor />,
                submenu: [],
                hiddenMenu: true
            },
            {
                path: '/routes',
                title: 't.routes',
                icon: <FontAwesomeIcon icon={faRandom} />,
                requiredRoles: [ROLES.ROLE_SUPER_ADMIN, ROLES.ROLE_ADMIN, ROLES.ROLE_TECHNICAL_ADMIN],
                key: 'tracking-routes',
                component: <Routes />,
                submenu: []
            },
            {
                path: '/lateral-g-map',
                title: 't.lateral_g_map',
                icon: <FontAwesomeIcon icon={faBullseye} />,
                requiredRoles: [ROLES.ROLE_SUPER_ADMIN],
                key: 'tracking-lateral-g-map',
                component: <></>,
                submenu: [],
                hiddenMenu: true
            },
            {
                path: '/speed-incidents',
                title: 't.speed_incidents',
                icon: <FontAwesomeIcon icon={faExclamationTriangle} />,
                requiredRoles: [
                    ROLES.ROLE_SUPER_ADMIN,
                    ROLES.ROLE_ENGINEERING,
                    ROLES.ROLE_TYRE_BAY_USER,
                    ROLES.ROLE_TECHNICAL_USER,
                    ROLES.ROLE_TECHNICAL_ADMIN,
                    ROLES.ROLE_ADMIN,
                    ROLES.ROLE_BASE_USER
                ],
                key: 'tracking-speed-incidents',
                component: <SpeedIncidents />,
                submenu: [],
                hasDivider: true
            },
            {
                path: '/execessive-acceleration',
                title: 't.excessive_lateral_load',
                icon: <FontAwesomeIcon icon={faCarTilt} />,
                requiredRoles: [
                    ROLES.ROLE_SUPER_ADMIN,
                    ROLES.ROLE_ENGINEERING,
                    ROLES.ROLE_TYRE_BAY_USER,
                    ROLES.ROLE_TECHNICAL_USER,
                    ROLES.ROLE_TECHNICAL_ADMIN,
                    ROLES.ROLE_ADMIN,
                    ROLES.ROLE_BASE_USER
                ],
                key: 'tracking-excessive-acceleration',
                component: <ExcessiveAcc />,
                submenu: []
            }
        ]
    },
    {
        title: 't.tyres',
        icon: <FontAwesomeIcon icon={faTire} />,
        requiredRoles: [
            ROLES.ROLE_SUPER_ADMIN,
            ROLES.ROLE_BASE_USER,
            ROLES.ROLE_ENGINEERING,
            ROLES.ROLE_TYRE_BAY_USER,
            ROLES.ROLE_TECHNICAL_USER,
            ROLES.ROLE_TECHNICAL_ADMIN,
            ROLES.ROLE_ADMIN
        ],
        open: false,
        key: 'tyres',
        submenu: [
            {
                path: '/tyres',
                title: 't.tyres',
                icon: <FontAwesomeIcon icon={faTire} />,
                requiredRoles: [
                    ROLES.ROLE_SUPER_ADMIN,
                    ROLES.ROLE_BASE_USER,
                    ROLES.ROLE_ENGINEERING,
                    ROLES.ROLE_TYRE_BAY_USER,
                    ROLES.ROLE_TECHNICAL_USER,
                    ROLES.ROLE_TECHNICAL_ADMIN,
                    ROLES.ROLE_ADMIN
                ],
                key: 'tyres-tyres',
                component: <TyrePage />,
                submenu: []
            },
            {
                path: '/sensors',
                title: 't.sensors',
                icon: <FontAwesomeIcon icon={faUnlink} />,
                requiredRoles: [
                    ROLES.ROLE_SUPER_ADMIN,
                    ROLES.ROLE_BASE_USER,
                    ROLES.ROLE_ENGINEERING,
                    ROLES.ROLE_TYRE_BAY_USER,
                    ROLES.ROLE_TECHNICAL_USER,
                    ROLES.ROLE_TECHNICAL_ADMIN,
                    ROLES.ROLE_ADMIN
                ],
                key: 'tyres-sensors',
                component: <Sensors />,
                submenu: []
            },
            {
                path: '/pressure-temperature',
                title: 't.pressure_and_temperature',
                icon: <FontAwesomeIcon icon={faTirePressureWarning} />,
                requiredRoles: [
                    ROLES.ROLE_SUPER_ADMIN,
                    ROLES.ROLE_BASE_USER,
                    ROLES.ROLE_ENGINEERING,
                    ROLES.ROLE_TYRE_BAY_USER,
                    ROLES.ROLE_TECHNICAL_USER,
                    ROLES.ROLE_TECHNICAL_ADMIN,
                    ROLES.ROLE_ADMIN
                ],
                key: 'tyres-pressure-temperature',
                component: <TemperaturePressure />,
                submenu: []
            },
            {
                path: '/set-alerts',
                title: 't.set_alerts',
                icon: <FontAwesomeIcon icon={faExclamationTriangle} />,
                requiredRoles: [ROLES.ROLE_SUPER_ADMIN, ROLES.ROLE_ADMIN],
                key: 'setAlerts',
                component: <SetAlerts />,
                submenu: []
            },
            {
                path: '/lifespan-report',
                title: 't.tyre_lifespan_report',
                icon: <FontAwesomeIcon icon={faFileChartColumn} />,
                requiredRoles: [ROLES.ROLE_SUPER_ADMIN],
                key: 'tyres-lifespan-report',
                component: <TyreLifespanReport />,
                submenu: []
            },
            {
                path: '/lifespan-comparison',
                title: 't.tyre_lifespan_comparison',
                icon: <FontAwesomeIcon icon={faTireRugged} />,
                requiredRoles: [ROLES.ROLE_SUPER_ADMIN],
                key: 'tyres-lifespan-comparison',
                component: <TyreLifespanComparison />,
                submenu: []
            },
            {
                path: '/sensor-faulty-reports',
                title: 't.faulty_sensor_reports',
                icon: <FontAwesomeIcon icon={faUnlink} />,
                requiredRoles: [ROLES.ROLE_SUPER_ADMIN],
                key: 'tyres-sensor-faulty-reports',
                component: <FaultySensorReports />,
                submenu: []
            },
            {
                path: '/temperature-exposure',
                title: 't.wheel_temp_levels',
                icon: <FontAwesomeIcon icon={faTemperatureHalf} />,
                requiredRoles: [ROLES.ROLE_SUPER_ADMIN, ROLES.ROLE_ADMIN, ROLES.ROLE_TECHNICAL_ADMIN],
                key: 'tyres-temperature-exposure',
                component: <TemperatureExposure />,
                submenu: []
            }
        ]
    },
    {
        title: 't.vehicles',
        icon: <FontAwesomeIcon icon={faTruckContainer} />,
        requiredRoles: [
            ROLES.ROLE_SUPER_ADMIN,
            ROLES.ROLE_TECHNICAL_USER,
            ROLES.ROLE_TECHNICAL_ADMIN,
            ROLES.ROLE_ADMIN
        ],
        open: false,
        key: 'vehicles',
        submenu: [
            {
                path: '/vehicles',
                title: 't.vehicles',
                icon: <FontAwesomeIcon icon={faTruck} />,
                requiredRoles: [
                    ROLES.ROLE_SUPER_ADMIN,
                    ROLES.ROLE_TECHNICAL_USER,
                    ROLES.ROLE_TECHNICAL_ADMIN,
                    ROLES.ROLE_ADMIN
                ],
                key: 'vehicles-vehicles',
                component: <Vehicle />,
                submenu: []
            },
            {
                path: '/vehicle-group',
                title: 't.vehicle_group',
                icon: <FontAwesomeIcon icon={faCarBus} />,
                requiredRoles: [
                    ROLES.ROLE_SUPER_ADMIN,
                    ROLES.ROLE_TECHNICAL_USER,
                    ROLES.ROLE_TECHNICAL_ADMIN,
                    ROLES.ROLE_ADMIN
                ],
                key: 'vehicles-vehicle-group',
                component: <VehicleTypes />,
                submenu: []
            }
        ]
    },
    {
        path: '/quicksight-reports',
        title: 't.reports',
        icon: <FontAwesomeIcon icon={faChartPie} />,
        requiredRoles: [
            ROLES.ROLE_SUPER_ADMIN,
            ROLES.ROLE_BASE_USER,
            ROLES.ROLE_ENGINEERING,
            ROLES.ROLE_TYRE_BAY_USER,
            ROLES.ROLE_TECHNICAL_USER,
            ROLES.ROLE_TECHNICAL_ADMIN,
            ROLES.ROLE_ADMIN
        ],
        key: 'quicksight_reports',
        component: <QuicksightReports />,
        submenu: []
    },
    {
        title: 't.data_exports',
        icon: <FontAwesomeIcon icon={faBriefcase} />,
        requiredRoles: [
            ROLES.ROLE_SUPER_ADMIN,
            ROLES.ROLE_ENGINEERING,
            ROLES.ROLE_TECHNICAL_USER,
            ROLES.ROLE_TECHNICAL_ADMIN,
            ROLES.ROLE_ADMIN
        ],
        open: false,
        key: 'data-exports',
        submenu: [
            {
                path: '/wheels-tpms',
                title: 't.wheels_tpms',
                icon: <FontAwesomeIcon icon={faTruck} />,
                requiredRoles: [
                    ROLES.ROLE_SUPER_ADMIN,
                    ROLES.ROLE_ENGINEERING,
                    ROLES.ROLE_TECHNICAL_USER,
                    ROLES.ROLE_TECHNICAL_ADMIN,
                    ROLES.ROLE_ADMIN
                ],
                key: 'data-exports-wheels-tpms',
                component: <WheelTPMS />,
                submenu: []
            },
            {
                title: 't.notifications',
                icon: <FontAwesomeIcon icon={faBullhorn} />,
                requiredRoles: [
                    ROLES.ROLE_SUPER_ADMIN,
                    ROLES.ROLE_ENGINEERING,
                    ROLES.ROLE_TECHNICAL_USER,
                    ROLES.ROLE_TECHNICAL_ADMIN,
                    ROLES.ROLE_ADMIN
                ],
                open: false,
                key: 'data-exports-menu2-notifications',
                submenu: [
                    {
                        path: '/notification-temp-and-press',
                        title: 't.temp_pressure',
                        icon: <FontAwesomeIcon icon={faBullhorn} />,
                        requiredRoles: [
                            ROLES.ROLE_SUPER_ADMIN,
                            ROLES.ROLE_ENGINEERING,
                            ROLES.ROLE_TECHNICAL_USER,
                            ROLES.ROLE_TECHNICAL_ADMIN,
                            ROLES.ROLE_ADMIN
                        ],
                        key: 'data-exports-menu2-notifications-menu3-temp-and-press',
                        component: <TempPressNotifications />,
                        submenu: []
                    },
                    {
                        path: '/notification-temperature-prediction',
                        title: 't.temp_prediction',
                        icon: <FontAwesomeIcon icon={faChartMixedUpCircleCurrency} />,
                        requiredRoles: [ROLES.ROLE_SUPER_ADMIN],
                        key: 'data-exports-temperature-prediction-reports',
                        component: <TemperaturePredictionExport />,
                        submenu: [],
                        temperaturePrediction: true
                    },
                    {
                        path: '/notification-temperature',
                        title: 't.temperature',
                        icon: <FontAwesomeIcon icon={faBullhorn} />,
                        requiredRoles: [
                            ROLES.ROLE_SUPER_ADMIN,
                            ROLES.ROLE_ENGINEERING,
                            ROLES.ROLE_TECHNICAL_USER,
                            ROLES.ROLE_TECHNICAL_ADMIN,
                            ROLES.ROLE_ADMIN
                        ],
                        key: 'data-exports-menu2-notifications-menu3-temprature',
                        component: <TemperatureNotifications />,
                        submenu: []
                    }
                ]
            },
            {
                path: '/track-log',
                title: 't.track_log',
                icon: <FontAwesomeIcon icon={faRoad} />,
                requiredRoles: [
                    ROLES.ROLE_SUPER_ADMIN,
                    ROLES.ROLE_ENGINEERING,
                    ROLES.ROLE_TECHNICAL_USER,
                    ROLES.ROLE_TECHNICAL_ADMIN,
                    ROLES.ROLE_ADMIN
                ],
                key: 'data-exports-track-log',
                component: <TrackLog />,
                submenu: []
            },
            {
                path: '/over-speeding',
                title: 't.speed_in_curves',
                icon: <FontAwesomeIcon icon={faTachometerFast} />,
                requiredRoles: [
                    ROLES.ROLE_SUPER_ADMIN,
                    ROLES.ROLE_ENGINEERING,
                    ROLES.ROLE_TECHNICAL_USER,
                    ROLES.ROLE_TECHNICAL_ADMIN,
                    ROLES.ROLE_ADMIN
                ],
                key: 'data-exports-over-speeding',
                component: <OverSpeeding />,
                submenu: []
            },
            {
                path: '/area-entries',
                title: 't.area_entries',
                icon: <FontAwesomeIcon icon={faMap} />,
                requiredRoles: [
                    ROLES.ROLE_SUPER_ADMIN,
                    ROLES.ROLE_ENGINEERING,
                    ROLES.ROLE_TECHNICAL_USER,
                    ROLES.ROLE_TECHNICAL_ADMIN,
                    ROLES.ROLE_ADMIN
                ],
                key: 'data-exports-area-entries',
                component: <AreaEntries />,
                submenu: []
            },
            {
                path: '/temp-press-alerts-levels',
                title: 't.temp_press_alert_levels',
                icon: <FontAwesomeIcon icon={faAbacus} />,
                requiredRoles: [
                    ROLES.ROLE_SUPER_ADMIN,
                    ROLES.ROLE_ENGINEERING,
                    ROLES.ROLE_TECHNICAL_USER,
                    ROLES.ROLE_TECHNICAL_ADMIN,
                    ROLES.ROLE_ADMIN
                ],
                key: 'data-exports-exported-reports',
                component: <TempPressAlertLevels />,
                submenu: []
            },
            {
                path: '/exported-reports',
                title: 't.exported_reports',
                icon: <FontAwesomeIcon icon={faList} />,
                requiredRoles: [
                    ROLES.ROLE_SUPER_ADMIN,
                    ROLES.ROLE_ENGINEERING,
                    ROLES.ROLE_TECHNICAL_USER,
                    ROLES.ROLE_TECHNICAL_ADMIN,
                    ROLES.ROLE_ADMIN
                ],
                key: 'data-exports-exported-reports',
                component: <ExportedReports />,
                submenu: []
            },
            {
                path: '/accelerometer',
                title: 't.accelerometer',
                icon: <FontAwesomeIcon icon={faGaugeHigh} />,
                requiredRoles: [
                    ROLES.ROLE_SUPER_ADMIN,
                    ROLES.ROLE_ENGINEERING,
                    ROLES.ROLE_TECHNICAL_USER,
                    ROLES.ROLE_TECHNICAL_ADMIN,
                    ROLES.ROLE_ADMIN
                ],
                key: 'data-exports-accelerometer-reports',
                component: <AccelerometerLog />,
                submenu: []
            }
        ]
    },
    {
        title: 't.administration',
        icon: <FontAwesomeIcon icon={faWrench} />,
        requiredRoles: [
            ROLES.ROLE_SUPER_ADMIN,
            ROLES.ROLE_TECHNICAL_USER,
            ROLES.ROLE_TECHNICAL_ADMIN,
            ROLES.ROLE_ADMIN,
            ROLES.ROLE_TYRE_BAY_USER
        ],
        open: false,
        key: 'administration',
        submenu: [
            {
                path: '/users',
                title: 't.users',
                icon: <FontAwesomeIcon icon={faUser} />,
                requiredRoles: [ROLES.ROLE_SUPER_ADMIN, ROLES.ROLE_TECHNICAL_ADMIN, ROLES.ROLE_ADMIN],
                key: 'administration-users',
                component: <UsersAdministration />,
                submenu: []
            },
            {
                path: '/site',
                title: 't.site',
                icon: <FontAwesomeIcon icon={faListAlt} />,
                requiredRoles: [ROLES.ROLE_SUPER_ADMIN, ROLES.ROLE_TECHNICAL_ADMIN, ROLES.ROLE_ADMIN],
                key: 'administration-site',
                component: <CustomerSettings />,
                submenu: []
            },
            {
                path: '/notifications',
                title: 't.notifications',
                icon: <FontAwesomeIcon icon={faBullhorn} />,
                requiredRoles: [ROLES.ROLE_SUPER_ADMIN, ROLES.ROLE_TECHNICAL_ADMIN, ROLES.ROLE_ADMIN],
                key: 'administration-notifications',
                component: <NotificationSettings />,
                submenu: []
            },
            {
                path: '/hubs',
                title: 't.hubs',
                icon: <FontAwesomeIcon icon={faHdd} />,
                requiredRoles: [
                    ROLES.ROLE_SUPER_ADMIN,
                    ROLES.ROLE_TECHNICAL_USER,
                    ROLES.ROLE_TECHNICAL_ADMIN,
                    ROLES.ROLE_ADMIN
                ],
                key: 'administration-hubs',
                component: <HubsAdministration />,
                submenu: []
            },
            {
                path: '/device/:id',
                title: 't.hubs',
                icon: <></>,
                requiredRoles: [
                    ROLES.ROLE_SUPER_ADMIN,
                    ROLES.ROLE_TECHNICAL_ADMIN,
                    ROLES.ROLE_ADMIN,
                    ROLES.ROLE_TECHNICAL_USER
                ],
                key: 'device-hubs',
                component: <HubsDevice />,
                submenu: [],
                hiddenMenu: true
            },
            {
                path: '/report-permissions',
                title: 't.report_permissions',
                icon: <FontAwesomeIcon icon={['fas', 'shield-check']} />,
                requiredRoles: [ROLES.ROLE_SUPER_ADMIN],
                key: 'administration-report-permissions',
                component: <ReportPermissions />,
                submenu: []
            },
            {
                path: '/tablet-activation',
                title: 't.tablet_activation',
                icon: <FontAwesomeIcon icon={faTabletAndroid} />,
                requiredRoles: [
                    ROLES.ROLE_SUPER_ADMIN,
                    ROLES.ROLE_ADMIN,
                    ROLES.ROLE_TECHNICAL_ADMIN,
                    ROLES.ROLE_TYRE_BAY_USER
                ],
                key: 'tablet-activation',
                component: <TabletActivation />,
                submenu: [],
                tableActivation: true
            },
            {
                path: '/users/add',
                title: 't.add_user',
                icon: <></>,
                requiredRoles: [ROLES.ROLE_SUPER_ADMIN, ROLES.ROLE_ADMIN],
                key: 'users-add',
                component: <Profile />,
                submenu: [],
                hiddenMenu: true
            }
        ]
    },
    {
        title: 't.global_admin',
        icon: <FontAwesomeIcon icon={faWrench} />,
        requiredRoles: [ROLES.ROLE_SUPER_ADMIN],
        open: false,
        key: 'global-admin',
        submenu: [
            {
                path: '/admin-dashboard',
                title: 't.dashboard',
                icon: <FontAwesomeIcon icon={faBriefcase} />,
                requiredRoles: [ROLES.ROLE_SUPER_ADMIN],
                key: 'global-admin-dashboard',
                component: <></>,
                submenu: [],
                hiddenMenu: true
            },
            {
                path: '/admin-customers',
                title: 't.customers',
                icon: <FontAwesomeIcon icon={faUsers} />,
                requiredRoles: [ROLES.ROLE_SUPER_ADMIN],
                key: 'global-admin-customers',
                component: <CustomersGlobalAdmin />,
                submenu: []
            },
            {
                path: '/user-action',
                title: 't.user_action',
                icon: <FontAwesomeIcon icon={faLightbulb} />,
                requiredRoles: [ROLES.ROLE_SUPER_ADMIN],
                key: 'administration-user-action',
                component: <UserAction />,
                submenu: []
            },
            {
                path: '/permissions',
                title: 't.permissions',
                icon: <FontAwesomeIcon icon={faUsersCog} />,
                requiredRoles: [ROLES.ROLE_SUPER_ADMIN],
                key: 'global-admin-permissions',
                component: <Permission />,
                submenu: []
            },
            {
                path: '/permissions/user/:id',
                title: 't.edit_user',
                icon: <FontAwesomeIcon icon={faUsersCog} />,
                requiredRoles: [ROLES.ROLE_SUPER_ADMIN],
                key: 'global-admin-permissions-user',
                component: <Profile />,
                hiddenMenu: true,
                submenu: []
            },
            {
                path: '/admin-hub-notify',
                title: 't.hub_notify',
                icon: <FontAwesomeIcon icon={faBullhorn} />,
                requiredRoles: [ROLES.ROLE_SUPER_ADMIN],
                key: 'global-admin-hub-notify',
                component: <HubNotify />,
                submenu: []
            },
            {
                path: '/admin-hubs',
                title: 't.hubs',
                icon: <FontAwesomeIcon icon={faHdd} />,
                requiredRoles: [ROLES.ROLE_SUPER_ADMIN],
                key: 'global-admin-hubs',
                component: <AdminHubs />,
                submenu: []
            },
            {
                path: '/admin-new-hubs',
                title: 't.new_hubs',
                icon: <FontAwesomeIcon icon={faDesktop} />,
                requiredRoles: [ROLES.ROLE_SUPER_ADMIN],
                key: 'global-admin-new-hubs',
                component: <NewHubs />,
                submenu: []
            },
            {
                title: 't.hub_updater',
                icon: <FontAwesomeIcon icon={faServer} />,
                requiredRoles: [ROLES.ROLE_SUPER_ADMIN],
                open: false,
                key: 'global-admin-menu2-hub-updater',
                submenu: [
                    {
                        path: '/admin-updater-hubs',
                        title: 't.hubs_for_update',
                        icon: <FontAwesomeIcon icon={faHdd} />,
                        requiredRoles: [ROLES.ROLE_SUPER_ADMIN],
                        key: 'global-admin-menu2-hub-updater-menu3-hubs',
                        component: <HubsUpdater />,
                        submenu: []
                    },
                    {
                        path: '/admin-updater-batches',
                        title: 't.batches',
                        icon: <FontAwesomeIcon icon={faDatabase} />,
                        requiredRoles: [ROLES.ROLE_SUPER_ADMIN],
                        key: 'global-admin-menu2-hub-updater-menu3-batches',
                        component: <HubUpdaterBatches />,
                        submenu: []
                    }
                ]
            },
            {
                path: '/admin-customer-report',
                title: 't.customer_report',
                icon: <FontAwesomeIcon icon={faList} />,
                requiredRoles: [ROLES.ROLE_SUPER_ADMIN],
                open: false,
                key: 'global-admin-customer-report',
                component: <CustomerReport />,
                submenu: []
            },
            {
                path: '/announcements',
                title: 't.announcements',
                icon: <FontAwesomeIcon icon={faTruck} />,
                requiredRoles: [ROLES.ROLE_SUPER_ADMIN],
                key: 'global-admin-announcements',
                component: <Announcements />,
                submenu: []
            },
            {
                path: '/alert-notification-managment',
                title: 't.alert_notification_management',
                icon: <FontAwesomeIcon icon={faBells} />,
                requiredRoles: [ROLES.ROLE_SUPER_ADMIN],
                key: 'global-admin-alert-notification-managment',
                component: <AlertNotificationManagment />,
                submenu: []
            }
            /*{
                path: '/tyre-manufacturer-model',
                title: 't.tyre_manufacturer_model',
                icon: <FontAwesomeIcon icon={faTire} />,
                requiredRoles: [ROLES.ROLE_SUPER_ADMIN],
                key: 'tyre-manufacturer-model',
                component: <TyreManufacturerAndModel />,
                submenu: []
            },
            {
                path: '/vehicle-manufacturer-model',
                title: 't.vehicle_manufacturer_model',
                icon: <FontAwesomeIcon icon={faTruck} />,
                requiredRoles: [ROLES.ROLE_SUPER_ADMIN],
                key: 'vehicle-manufacturer-model',
                component: <VehicleManufacturerAndModel />,
                submenu: []
            }*/
        ]
    },
    {
        path: '/vehicle/:id/statistics',
        title: 't.statistics',
        icon: <ColorLens />,
        requiredRoles: [
            ROLES.ROLE_SUPER_ADMIN,
            ROLES.ROLE_BASE_USER,
            ROLES.ROLE_ENGINEERING,
            ROLES.ROLE_TYRE_BAY_USER,
            ROLES.ROLE_TECHNICAL_USER,
            ROLES.ROLE_TECHNICAL_ADMIN,
            ROLES.ROLE_ADMIN
        ],
        open: false,
        key: 'guideline',
        component: <Statistics />,
        submenu: [],
        hiddenMenu: true,
        keepDefaultTitle: true
    },
    {
        path: '/profile',
        title: 't.profile',
        icon: <></>,
        requiredRoles: [
            ROLES.ROLE_SUPER_ADMIN,
            ROLES.ROLE_BASE_USER,
            ROLES.ROLE_ENGINEERING,
            ROLES.ROLE_TYRE_BAY_USER,
            ROLES.ROLE_TECHNICAL_USER,
            ROLES.ROLE_TECHNICAL_ADMIN,
            ROLES.ROLE_ADMIN
        ],
        key: 'profile-external',
        component: <Profile />,
        submenu: [],
        hiddenMenu: true
    },
    {
        path: '/profile/:id',
        title: 't.profile',
        icon: <></>,
        requiredRoles: [ROLES.ROLE_SUPER_ADMIN, ROLES.ROLE_ADMIN],
        key: 'profile-external-id',
        component: <Profile />,
        submenu: [],
        hiddenMenu: true
    },
    {
        path: '/severity-rank/:id',
        title: 't.severity_rank',
        icon: <ColorLens />,
        requiredRoles: [
            ROLES.ROLE_SUPER_ADMIN,
            ROLES.ROLE_BASE_USER,
            ROLES.ROLE_ENGINEERING,
            ROLES.ROLE_TYRE_BAY_USER,
            ROLES.ROLE_TECHNICAL_USER,
            ROLES.ROLE_TECHNICAL_ADMIN,
            ROLES.ROLE_ADMIN
        ],
        open: false,
        key: 'guideline',
        component: <SeverityRank />,
        submenu: [],
        hiddenMenu: true,
        bes: true
    }
];

const ON_SITE_SERVER_ENABLED = process.env.REACT_APP_ONSITE_SERVER === 'TRUE';
//Specify the keys of the menus to hide in onSiteServer
const optionsToRemoveByKey = ['data-exports', 'heatmaps', 'quicksight_reports', 'tablet-activation'];

function containsOptionsToRemove(option: MenuOption): boolean {
    return optionsToRemoveByKey.includes(option.key);
}

function setOnSiteServerMenu(options: MenuOption[]): MenuOption[] {
    return options.filter((option) => {
        if (option.submenu.length) {
            option.submenu = setOnSiteServerMenu(option.submenu);
        }
        return !containsOptionsToRemove(option);
    });
}

export const MenuRoutes = () => {
    type menuRoute = {
        path?: string;
        key: string;
        element?: JSX.Element;
    } & Pick<
        MenuOption,
        'bes' | 'besDrivingAssistance' | 'requiredRoles' | 'tableActivation' | 'temperaturePrediction'
    >;

    let menuRoutes: menuRoute[] = [];
    const parseMenuOptions = (source: MenuOption[]) => {
        if (!source.length) {
            return [];
        }

        source.forEach((item) => {
            if (item.submenu.length) {
                parseMenuOptions(item.submenu);
            } else {
                menuRoutes.push({
                    path: item.path as string,
                    key: item.key,
                    element: item.component as JSX.Element,
                    requiredRoles: item.requiredRoles,
                    bes: item.bes,
                    besDrivingAssistance: item.besDrivingAssistance,
                    tableActivation: item.tableActivation,
                    temperaturePrediction: item.temperaturePrediction
                });
            }
        });
    };
    parseMenuOptions(ON_SITE_SERVER_ENABLED ? setOnSiteServerMenu(Menu) : Menu);
    return menuRoutes;
};

//OnSiteServer displays less menu options due to compatibility issues.
export default ON_SITE_SERVER_ENABLED ? setOnSiteServerMenu(Menu) : Menu;

import React, { useEffect, useMemo, useState } from 'react';
import * as view from './ProfileTabManager.view';
import { checkRole } from 'helpers/authentication';
import AccountSettingsForm from '../AccountSettingsForm/AccountSettingsForm';
import GeneralSettingsForm from '../GeneralSettingsForm/GeneralSettingsForm';
import TrackingSettingsForm from '../TrackingSettingsForm/TrackingSettingsForm';
import AlertsSettingsForm from '../AlertsSettingsForm/AlertsSettingsForm';
import NotificationsSettingsForm from '../NotificationsSettingsForm/NotificationsSettingsForm';
import ChangePasswordForm from '../ChangePasswordForm/ChangePasswordForm';
import { User, UserInfo } from 'states/global/User';
import UserApi from 'api/User';
import { AccountModelResponse, UserModel } from 'models/User.type';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
    ErrorOutline,
    SettingsOutlined,
    AccountCircleOutlined,
    MyLocation,
    MarkChatUnreadOutlined,
    Security,
    ManageAccounts
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import AddUserForm from '../AddUserForm/AddUserForm';
import { ROLES } from 'variables';
import AccountApi, { parseDateTime } from 'api/Account';
import UiLoadingPage from '../../Ui/Components/UiLoadingPage/UiLoadingPage';
import { ListTabsBody, ListTabsMenu, ProfileTabManagerProps } from './ProfileTabManager.type';
import { useLocation } from 'react-router-dom';
import MinesPermissions from '../MinesPermissions/MinesPermissions';
import { SelectedUser } from '../MinesPermissions/Permissions.atom';
import { ShowPermissionModal } from 'states/global/Modal';
import { Wrapper } from 'helpers/wrapper';
import { IsChangingCustomer } from 'components/Customer/CustomerSelectorAtom';

const userApi = new UserApi();
const accountApi = new AccountApi();

const getProfileUserId = (): number => {
    let destructUrl: string[];
    let positionId: number;

    destructUrl = document.location.pathname.split('/');
    positionId = destructUrl.length - 1;

    if (destructUrl.length > 0) {
        return +destructUrl[positionId];
    }

    return 0;
};

const ProfileTabManager: React.FC<ProfileTabManagerProps> = (): JSX.Element => {
    const location = useLocation();
    const { t: translate } = useTranslation();
    const UserState = useRecoilValue(UserInfo);
    const urlPathName: string = document.location.pathname;
    const [idTabActive, setIdTabActive] = React.useState(0);
    const setPermissionAtom = useSetRecoilState(SelectedUser);
    const [reloadPage, setReloadPage] = useState<boolean>(false);
    const [isCurrentUser, setIsCurrentUser] = useState<boolean>(true);
    const setPermissionModal = useSetRecoilState(ShowPermissionModal);
    const [userData, setUserData] = useState<{ user: UserModel | null }>();
    const isCustomerChanging = useRecoilValue(IsChangingCustomer);
    const setUserState = useSetRecoilState(User);

    const refreshUserInfo = async () => {
        try {
            const dataU: UserModel = await accountApi.getParsed();
            if (Object.keys(dataU).length) {
                setUserState(dataU);
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.warn('Get user ', error);
        }
    };

    const checkAddUser = useMemo((): boolean => {
        return urlPathName.includes('/users/add');
    }, [urlPathName]);

    const checkPermissionsUser = useMemo((): boolean => {
        return urlPathName.includes('/permissions/user');
    }, [urlPathName]);

    const handleChangeTab = (e: React.ChangeEvent<{}>, idTabActive: number): void => {
        setIdTabActive(idTabActive);
    };

    let listTabsMenu: ListTabsMenu[] = [
        {
            idTab: 1,
            title: translate('t.account_settings'),
            subTitle: translate('p.conf_spanersonal_data_account'),
            icon: <AccountCircleOutlined />,
            disabled: false,
            testId: 'Account-Settings-Tab'
        },
        {
            idTab: 2,
            title: translate('t.general_settings'),
            subTitle: translate('p.lan_and_time_zones_settings'),
            icon: <SettingsOutlined />,
            disabled: checkAddUser,
            testId: 'General-Settings-Tab'
        },
        {
            idTab: 3,
            title: translate('t.tracking_settings'),
            subTitle: translate('p.vehicle_tracking_conf'),
            icon: <MyLocation />,
            disabled: checkAddUser,
            testId: 'Tracking-Settings-Tab'
        },
        {
            idTab: 4,
            title: translate('t.alert_reminder'),
            subTitle: translate('p.conf_alerts_by_vehicle'),
            icon: <ErrorOutline />,
            disabled: checkAddUser,
            testId: 'Alert-Reminder-Tab'
        },
        {
            idTab: 5,
            title: translate('t.notification_settings'),
            subTitle: translate('t.notification_settings'),
            icon: <MarkChatUnreadOutlined />,
            disabled: checkAddUser,
            testId: 'Notification-Settings-Tab'
        }
    ];

    const changePswdTab: ListTabsMenu[] = [
        {
            idTab: 6,
            title: translate('t.change_password'),
            subTitle: translate('t.change_password'),
            icon: <Security />,
            disabled: checkAddUser,
            testId: 'Change-Password-Tab'
        }
    ];

    const permissionUserTab: ListTabsMenu = {
        idTab: 0,
        title: translate('t.assigned_customers'),
        subTitle: translate('t.assign_or_unassign_customer'),
        icon: <ManageAccounts />,
        disabled: false,
        testId: 'Assigned-Settings-Tab'
    };

    const listTabsBody: ListTabsBody[] = [
        {
            idTab: 0,
            body: (
                <MinesPermissions
                    queryKey='permissionsUsersAdministrations'
                    data-testid={`UsersAdministrationContent-MinesPermissions-User`}
                    noRenderModal
                />
            )
        },
        {
            idTab: 1,
            body: checkAddUser ? (
                <AddUserForm />
            ) : (
                <AccountSettingsForm
                    userData={userData?.user || null}
                    isCurrentUser={isCurrentUser}
                    userId={userData?.user?.id}
                    setReloadPage={setReloadPage}
                />
            )
        },
        {
            idTab: 2,
            body: reloadPage ? (
                <UiLoadingPage size='30px' />
            ) : (
                <GeneralSettingsForm
                    userDataSetting={userData?.user?.userSetting || null}
                    isCurrentUser={isCurrentUser}
                    userId={userData?.user?.id}
                    setReloadPage={setReloadPage}
                />
            )
        },
        {
            idTab: 3,
            body: reloadPage ? (
                <UiLoadingPage size='30px' />
            ) : (
                <TrackingSettingsForm
                    userDataSetting={userData?.user?.userSetting || null}
                    isCurrentUser={isCurrentUser}
                    userId={userData?.user?.id}
                    setReloadPage={setReloadPage}
                    refreshUserInfo={refreshUserInfo}
                />
            )
        },
        {
            idTab: 4,
            body: reloadPage ? (
                <UiLoadingPage size='30px' />
            ) : (
                <AlertsSettingsForm
                    userDataSetting={userData?.user?.userSetting || null}
                    userEmail={userData?.user?.email}
                    isCurrentUser={isCurrentUser}
                    userId={userData?.user?.id}
                    setReloadPage={setReloadPage}
                />
            )
        },
        {
            idTab: 5,
            body: reloadPage ? (
                <UiLoadingPage size='30px' />
            ) : (
                <NotificationsSettingsForm
                    isCurrentUser={isCurrentUser}
                    userId={userData?.user?.id}
                    userData={userData?.user || null}
                    setReloadPage={setReloadPage}
                    checkPermissionsUser={checkPermissionsUser}
                />
            )
        },
        {
            idTab: 6,
            body: (
                <ChangePasswordForm
                    isCurrentUser={isCurrentUser}
                    userId={userData?.user?.id}
                    handleChangeTab={setIdTabActive}
                />
            )
        }
    ];

    const getUser = async (userId: number): Promise<{ user: UserModel }> => {
        const { user: newUserData }: AccountModelResponse = await userApi.getUserById(userId);

        return {
            user: newUserData
        };
    };

    const setUser = async (): Promise<void> => {
        const userId: number = getProfileUserId();

        if (!userId) {
            if (UserState.user?.userSetting && UserState.user?.customer) {
                setUserData(parseDateTime(UserState as { user: UserModel }));
            }
            setIsCurrentUser(checkPermissionsUser ? false : true);
        } else {
            const user = await getUser(userId);
            setUserData(parseDateTime(user));
            setIsCurrentUser(userId === UserState.user?.id);
        }
    };
    let urlParams = new URLSearchParams(location.search);
    let tabParam = urlParams.get('tab');

    useEffect(() => {
        if (tabParam && idTabActive != parseInt(tabParam)) {
            setIdTabActive(parseInt(tabParam));
        }
    }, [tabParam]);

    useEffect(() => {
        !isCustomerChanging && setUser();
        return () => {
            setUserData({ user: null });
        };
    }, [UserState, urlPathName]);

    useEffect(() => {
        if (!isCurrentUser) {
            !isCustomerChanging && reloadPage && setUser();
        }

        setTimeout(() => {
            reloadPage && setReloadPage(false);
        }, 3000);
    }, [reloadPage]);

    if (isCurrentUser || checkRole(UserState.user, [ROLES.ROLE_SUPER_ADMIN, ROLES.ROLE_ADMIN])) {
        listTabsMenu = listTabsMenu.concat(changePswdTab);
    }

    if (checkPermissionsUser && checkRole(UserState.user, [ROLES.ROLE_SUPER_ADMIN])) {
        listTabsMenu.unshift(permissionUserTab);
        if (userData?.user) {
            setPermissionAtom(userData?.user);
            setPermissionModal(true);
        }
    }

    return (
        <view.ProfileTabManagerContent
            data-testid='ProfileTabs-testid'
            handleChangeTab={handleChangeTab}
            idTabActive={idTabActive}
            listTabsMenu={listTabsMenu}
            listTabsBody={listTabsBody}
            checkPermissionsUser={checkPermissionsUser}
        />
    );
};

export default Wrapper(ProfileTabManager);

import {
    AlertListActions,
    TabNumber,
    filtersActionType,
    genericActionType,
    modalActionType,
    queryActionType,
    scrollRefreshActionType,
    stateAlertList
} from './TPMSAlertsListTabManager.type';

export const inicialStateAlertList: stateAlertList = {
    tabNumber: TabNumber.TpmsAlert,
    alertIdToSolve: null,
    alertFilters: {
        level: null,
        type: null
    },
    hasMore: true,
    currentPage: 1,
    enabledQuery: true,
    displayRefresh: false
};

export const AlertsListReducer = (
    state: stateAlertList,
    action: genericActionType | modalActionType | filtersActionType | queryActionType | scrollRefreshActionType
) => {
    switch (action.type) {
        case AlertListActions.SET_NUMBER_TAB:
            return {
                ...state,
                tabNumber: action.payload
            };
        case AlertListActions.SET_ALERT_ID:
            return {
                ...state,
                alertIdToSolve: action.payload
            };
        case AlertListActions.SET_HAS_MORE:
            return {
                ...state,
                hasMore: action.payload
            };
        case AlertListActions.SET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.payload
            };
        case AlertListActions.SET_ALERT_FILTER:
            return {
                ...state,
                alertFilters: action.payload
            };
        case AlertListActions.SET_ENABLED_QUERY:
            return {
                ...state,
                enabledQuery: action.payload
            };
        case AlertListActions.SET_DISPLAY_REFRESH:
            return {
                ...state,
                displayRefresh: action.payload
            };
        case AlertListActions.SET_SCROLL_REFRESH:
            return {
                ...state,
                displayRefresh: !state.displayRefresh,
                enabledQuery: !state.enabledQuery
            };
        default:
            return state;
    }
};

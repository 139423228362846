import { FormikErrors } from 'formik';
import DateTime from 'luxon';
import { UseConverterReturnType } from 'components/CustomHooks/Converter/Converter.type';

export type UiDatePickerProps = {
    dateFromLabel?: string;
    dateToLabel?: string;
    dateFrom?: DateTime | string | null;
    dateTo?: DateTime | string | null;
    error?: FormikErrors<DateTime | string | undefined>;
    inputFormat?: Parameters<UseConverterReturnType['dateTimeFormat']>[0];
    adjustToPeriod?: {
        period: number;
        periodType: 'seconds' | 'minutes' | 'hours' | 'days' | 'weeks' | 'months';
    };
    inputFormatFrom?: UiDatePickerProps['inputFormat'];
    inputFormatTo?: UiDatePickerProps['inputFormat'];
    testIdFrom?: string;
    testIdTo?: string;
    onlyDate?: boolean;
    validFutureDate?: boolean;
    onDateChange?: (newData: DateTime[] | null, invalid?: boolean) => void;
    onFocus?: () => void;
    onBlur?: () => void;
};

export enum PeriodTypeEnum {
    SECONDS = 'seconds',
    MINUTES = 'minutes',
    HOURS = 'hours',
    DAYS = 'days',
    WEEKS = 'weeks',
    MONTHS = 'months'
}

export type DateRangeData = {
    dateFrom?: DateTime | null;
    dateTo?: DateTime | null;
    dateFromUTC?: string;
    dateToUTC?: string;
    isValidRange?: boolean;
};

import React, { useCallback, useMemo, useState } from 'react';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';
import { Popover } from '@mui/material';
import { TooltipText } from './VehicleActions.style';
import UiIconButton from 'components/Ui/Components/UiIconButton/UiIconButton';
import { VehicleActionsInfoTooltipProps } from './VehicleActions.type';

const VehicleActionsInfoTooltip: React.FC<VehicleActionsInfoTooltipProps> = ({ textInfo, testid }): JSX.Element => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = useMemo(() => Boolean(anchorEl), [anchorEl]);

    const handleClick = useCallback(
        (event: React.MouseEvent<HTMLElement>): void => {
            setAnchorEl(anchorEl ? null : event.currentTarget);
        },
        [anchorEl]
    );

    return (
        <>
            <UiIconButton onClick={handleClick}>
                <UiIcon icon={['fas', 'circle-info']} />
            </UiIconButton>
            <Popover
                id={`${testid}-vehicle-actions-info-tooltip-id`}
                data-testid={`${testid}-vehicle-actions-info-tooltip-testid`}
                open={open}
                anchorEl={anchorEl}
                onClose={() => {
                    setAnchorEl(null);
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
            >
                <TooltipText>{textInfo}</TooltipText>
            </Popover>
        </>
    );
};

export default VehicleActionsInfoTooltip;

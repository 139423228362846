import PortalApi from 'helpers/api/PortalApiClient';
import { TableModelModel, VehicleModelType, VehicleModelUsed } from '../models/VehicleModel.type';
import { DefaultRequestPropsType } from 'helpers/api/type';
import { getParams } from '../helpers';
import { isVehicleTyreBayHub } from 'helpers';

export default class VehicleModel extends PortalApi<VehicleModelType> {
    route = 'vehicle-model';

    getUsed = (props: Omit<DefaultRequestPropsType, 'criteria'>): Promise<VehicleModelUsed> => {
        return this.get<VehicleModelUsed>({ extendUrl: 'used', ...props }).then((data) => {
            let d = { ...data };
            d.codeBook = d.codeBook.filter((data) => !isVehicleTyreBayHub(data.id));
            return d;
        });
    };

    getAll = (props): Promise<TableModelModel> => {
        return this.getByCriteria({ criteria: getParams(props), version: 'v2' });
    };
}

import { GridFieldFormik } from 'components/User/ProfileTabManager/ProfileTabManager.style';
import { Form, Formik, FormikProps } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { object, string } from 'yup';
import { CircularProgress, Grid, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { CurrentDateFormikType, InicialValuesForm } from './CurrentDate.type';
import { ButtonForm } from './CurrentDate.style';
import { getListDateFormat } from 'helpers';

export const CurrentDateFormik: React.FC<CurrentDateFormikType> = ({
    inicialValuesFormik,
    handleSubmit
}): JSX.Element => {
    const { t: translate } = useTranslation();

    const schemaValidate = object({
        dateFormat: string().required(translate('p.this_field_is_required'))
    });

    return (
        <Formik
            initialValues={inicialValuesFormik}
            onSubmit={(values: InicialValuesForm, actions) => {
                handleSubmit(
                    {
                        ...values,
                        dateFormat:
                            (getListDateFormat().find((dateFormat) => dateFormat?.luxonValue === values.dateFormat)
                                ?.value as string) ?? values.dateFormat
                    },
                    actions.setSubmitting
                );
            }}
            validationSchema={schemaValidate}
        >
            {(propsF: FormikProps<InicialValuesForm>) => {
                const { errors, setFieldValue, values, isSubmitting } = propsF;

                return (
                    <Form>
                        <Grid container justifyContent='flex-end' direction='row'>
                            <GridFieldFormik item>
                                <Autocomplete
                                    options={getListDateFormat()}
                                    getOptionLabel={(dateFormat) => dateFormat.name}
                                    onChange={(_event, dateFormat) => {
                                        setFieldValue('dateFormat', dateFormat ? dateFormat.value : null);
                                    }}
                                    defaultValue={getListDateFormat().find(
                                        (dateFormat) => dateFormat?.luxonValue === values.dateFormat ?? ''
                                    )}
                                    size='small'
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={translate('t.date_format')}
                                            variant='outlined'
                                            name='dateFormat'
                                            type='text'
                                            value={values.dateFormat}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            helperText={errors.dateFormat && translate('p.this_field_is_required')}
                                            error={!!errors.dateFormat}
                                        />
                                    )}
                                />
                            </GridFieldFormik>

                            <Grid item sx={{ marginRight: '5px' }}>
                                <ButtonForm
                                    size='small'
                                    variant='contained'
                                    type='submit'
                                    disabled={isSubmitting}
                                    skin='success'
                                >
                                    {isSubmitting ? <CircularProgress size={25} /> : translate('t.save')}
                                </ButtonForm>
                            </Grid>
                        </Grid>
                    </Form>
                );
            }}
        </Formik>
    );
};

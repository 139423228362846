import Fab from '@mui/material/Fab';
import styled from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { BACKGROUND_PAPER_DARK_MODE, PRIMARY, PRIMARY_LIGHT, SECONDARY, WHITE } from 'components/Ui/colors';
import { Box, Button } from '@mui/material';
import { useTheme } from '@mui/material';
import { applyStyleByMode } from 'helpers';
import { ThemeMode } from 'states/global/Theme';
import { CommonTypeTheme } from 'components/Ui/Theme';
import UiIconButton from 'components/Ui/Components/UiIconButton/UiIconButton';

export const AlertsByVehicleContainer = styled.div`
    padding: 0px !important;
    padding: 0px;
    width: 100%;
    margin: auto;
    & .MuiCardContent-root {
        padding: 0px !important;
    }
`;

export const SectionHeader = styled(Typography)`
    border-bottom: solid thin #ebebeb;
`;

export const CustomTable = styled.table`
    min-width: 100%;
    tr {
        td {
            width: 50%;
        }
        td:last-child {
            position: relative;
            padding-left: 1em;
        }
    }
    .copy-code {
        background: ${PRIMARY};
        color: ${SECONDARY};
        padding: 5px 10px;
        height: 51px;
        border-radius: 4px;
        :hover {
            cursor: pointer;
            &:after {
                right: 2em;
                top: 50%;
                padding: 0 10px;
                transform: translateY(-50%);
                position: absolute;
                content: 'Click to copy';
                background: ${PRIMARY};
            }
        }
        pre {
            line-height: 2em;
        }
    }
`;

export const GroupOption = styled.div`
    width: 100%;
    border: thin #bfbfbf solid;
    position: relative;
    margin-top: 17px;
    height: auto;
    min-height: 67px;
    ${applyStyleByMode({
        styleJade: `
            margin-bottom: 10px;
            border-radius: 4px;
            `
    })}
`;

export const AlertRefresh = styled.div`
    ${() => {
        return applyStyleByMode({
            theme: useTheme().palette.mode,
            light: 'background: #fdfdfd !important;',
            dark: `background: ${BACKGROUND_PAPER_DARK_MODE} !important;`
        });
    }}

    text-align: center;
    border-radius: 0px 0px 4px 4px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    ${applyStyleByMode({
        styleJade: `
            box-shadow: 6px 6px 20px #0000001a;
            padding: 0px !important;
            margin-top: -28px;
        `
    })}
`;

export const FabCustom = styled(Fab)`
    background-color: transparent !important;
    box-shadow: none !important;
`;

export const ButtonRefresh = styled(Button)`
    ${() => {
        return applyStyleByMode({
            theme: useTheme().palette.mode,
            light: `color: ${PRIMARY_LIGHT} !important;`,
            dark: `color: ${WHITE} !important;`
        });
    }}

    border-radius: none !important;
    padding: 2px 6px !important;
    ${() => {
        const common = useTheme().palette.common as CommonTypeTheme;
        return `font-size: ${common.textSize || '13px'} !important;`;
    }}
    svg {
        color: #0b9444 !important;
        margin-right: 4px !important;
    }
`;

export const ModalContent = styled.div`
    padding: 10px 0px;
    font-size: 13px;
    text-align: center;
`;

export const LoadingProgress = styled(CircularProgress)({
    color: `${PRIMARY} !important`
});

export const PopUpFilterContainer = styled.div`
    display: inline;
    width: 100px;
`;

export const FilterLabel = styled.span`
    position: absolute;
    margin-top: -11px;
    margin-left: 8px;
    padding: 0 5px;
    ${() => {
        const theme = useTheme();
        const common = useTheme().palette.common as CommonTypeTheme;
        return applyStyleByMode({
            styleJade: `
            background: ${theme.palette.mode === ThemeMode.light ? WHITE : '#37414c'};
            color: ${theme.palette.mode === ThemeMode.light ? 'rgba(0, 0, 0, 0.54)' : WHITE};
            font-size: ${common.textSize};
            `
        });
    }}
`;

export const FilterCover = styled(Box)`
    &.MuiBox-root {
        display: inline-block;
        width: 100%;
    }
`;

export const FilterContent = styled.div`
    margin: 10px;
    display: flex;
    justify-content: space-evenly;

    ${applyStyleByMode({
        styleJade: `
                padding-top: 10px;
            `
    })}
`;

export const CustomIconButton = styled(UiIconButton)``;

import React, { useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { SensorStateAtom } from '../SensorManagementRow/SensorManagementRow.atom';
import { SensorActionPopupProps } from './SensorManagementPopup.type';
import { useTranslation } from 'react-i18next';
import { UnmountReason } from 'variables';
import useModal from 'components/CustomHooks/ModalHook';
import UiButton from 'components/Ui/Components/UiButton';
import { useMutation } from '@tanstack/react-query';
import SensorsAPI from 'api/Sensors';
import { FetchVehicleDetailsData } from 'components/Vehicle/VehicleDetailsWidget/VehicleDetailsWidget.atom';
import RemoveSensorView from './RemoveSensor.view';
import { VARIANT_BUTTON } from 'components/Ui/variables';

const sensorsAPI = new SensorsAPI();

const RemoveSensor = ({ position }: SensorActionPopupProps): JSX.Element => {
    const { t: translate } = useTranslation();
    const { updateModalState } = useModal(1);
    const sensorState = useRecoilValue(SensorStateAtom(position));
    const [value, setValue] = useState<UnmountReason>(UnmountReason.REMOVED_TO_STOCK);
    const setFetchVehicleDetailsData = useSetRecoilState(FetchVehicleDetailsData);
    const [isSaving, setIsSaving] = useState<boolean>(false);

    const { mutate } = useMutation(
        ['PatchRemoveSensor'],
        () =>
            sensorsAPI.unmountSensor({
                unmountReason: sensorState.originalSensor?.isFaulty ? UnmountReason.REMOVING_FAULTY : value,
                sensorId: sensorState.originalSensor?.id || 0
            }),
        {
            onSettled: () => {
                setFetchVehicleDetailsData((prevState) => !prevState);
            }
        }
    );

    useEffect(() => {
        updateModalState({
            leftTitle: translate('t.sensor_removal'),
            rightTitle: `${translate('t.sensor')}: ${sensorState.originalSensor?.serialNumberHex}`,
            buttons: (
                <UiButton
                    id='remove-sensor'
                    skin='error'
                    color='error'
                    variant={VARIANT_BUTTON}
                    disabled={value === UnmountReason.REMOVING_FAULTY && !sensorState.originalSensor?.isFaulty}
                    onClick={() => {
                        mutate();
                        setIsSaving(true);
                    }}
                    loading={isSaving}
                >
                    {translate('t.remove')}
                </UiButton>
            )
        });
    }, [value]);

    return <RemoveSensorView value={value} setValue={setValue} position={position} />;
};

export default RemoveSensor;

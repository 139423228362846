import React, { useMemo } from 'react';
import { TeamShiftTimeContent } from './TeamShiftTime.view';
import { TeamShiftTimeProps } from './TeamShiftTime.type';
import { Wrapper } from 'helpers/wrapper';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import { ShowTeamShiftTimeActionsModal } from 'states/global/Modal';
import UiMenu from 'components/Ui/Components/UiMenu/UiMenu';
import { CRUD } from 'variables';
import UiButton from 'components/Ui/Components/UiButton/UiButton';
import SettingsIcon from '@mui/icons-material/Settings';
import { TeamShiftTimeActionsState } from '../TeamShiftTimeActions/TeamShiftTimeActions.atom';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { DateTime } from 'luxon';
import { firstCapital } from 'helpers/converter/text';

const TeamShiftTime: React.FC<TeamShiftTimeProps> = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const openShowTeamShiftTimeActionsModal = useSetRecoilState(ShowTeamShiftTimeActionsModal);
    const tamShiftTimeActionsState = useSetRecoilState(TeamShiftTimeActionsState);
    const { fromUTCtoUserTimezone } = useConverter();
    const currentDate: DateTime = DateTime.now();

    const columns = useMemo(
        () => [
            {
                Header: translate('t.from'),
                accessor: 'startTime',
                width: 100,
                height: 20,
                disableFilters: true,
                disableSortBy: true,
                Cell: (props) => {
                    return (
                        <>
                            {fromUTCtoUserTimezone({
                                date: props.value?.replace('1970-01-01', currentDate.toFormat('yyyy-MM-dd')),
                                format: 'time',
                                displaySeconds: true
                            })}
                        </>
                    );
                }
            },
            {
                Header: translate('t.to'),
                accessor: 'endTime',
                width: 100,
                height: 20,
                disableFilters: true,
                disableSortBy: true,
                Cell: (props) => {
                    return (
                        <>
                            {fromUTCtoUserTimezone({
                                date: props.value?.replace('1970-01-01', currentDate.toFormat('yyyy-MM-dd')),
                                format: 'time',
                                displaySeconds: true
                            })}
                        </>
                    );
                }
            },
            {
                Header: translate('t.team'),
                accessor: 'teamShiftName.name',
                width: 100,
                height: 20,
                disableFilters: true,
                disableSortBy: true,
                Cell: (props) => {
                    return <>{firstCapital(props.value)}</>;
                }
            },
            {
                Header: translate('t.actions'),
                accessor: 'id',
                width: 50,
                height: 20,
                disableFilters: true,
                disableSortBy: true,
                Cell: (props) => (
                    <UiMenu
                        data-testid='AreasTable-Menu-actions'
                        items={[
                            {
                                title: translate('t.edit'),
                                value: props.value,
                                action: () => {
                                    tamShiftTimeActionsState({
                                        action: CRUD.EDIT,
                                        id: props.value,
                                        values: {
                                            timeFrom: props.row.original.startTime,
                                            timeTo: props.row.original.endTime,
                                            teamShiftName: props.row.original.teamShiftName
                                        }
                                    });
                                    openShowTeamShiftTimeActionsModal(true);
                                }
                            }
                        ]}
                        rootEl={
                            <UiButton
                                data-testid={'TeamShifTime-Button-actions'}
                                aria-controls='simple-menu'
                                aria-haspopup='true'
                                sx={{ minWidth: '20px', maxWidth: '20px' }}
                            >
                                <SettingsIcon />
                            </UiButton>
                        }
                    />
                )
            }
        ],
        [translate]
    );

    return <TeamShiftTimeContent data-testid='TeamShiftTime-testid' columns={columns} />;
};

export default Wrapper(TeamShiftTime);

import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { SeverityRankTopbarViewContent } from './SeverityRankTopbar.style';
import { SeverityRankTopbarViewProps } from './SeverityRankTopbar.type';
import { useTranslation } from 'react-i18next';
import Button from '../../Ui/Components/UiButton/UiButton';
import { ButtonSkinType } from 'components/Ui/Components/UiButton/UiButton.type';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';
import { SelectedVehicle } from 'states/global/Statistics';
import { faLongArrowAltLeft } from '@fortawesome/pro-solid-svg-icons';
import { useNavigate, generatePath } from 'react-router-dom';
import ZoomButtons from './Components/RankZoomButtons/RankZoomButtons';
import ApplyButton from './Components/RankApplyButton/RankApplyButton';
import DateRangePickers from './Components/RankDateRangePickers/RankDateRangePickers';
import VehicleDropdown from './Components/RankVehicleDropdown/RankVehicleDropdown';

export const SeverityRankTopbarContent: React.FC<SeverityRankTopbarViewProps> = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const selectedVehicle = useRecoilValue(SelectedVehicle);
    const navigate = useNavigate();

    const goBackButton: JSX.Element = useMemo(
        () => (
            <Button
                startIcon={<UiIcon icon={faLongArrowAltLeft} />}
                onClick={() => {
                    navigate(selectedVehicle.id == 0 ? `/vehicle-status` : `/vehicle-status?id=${selectedVehicle.id}`);
                }}
                skin={ButtonSkinType.LINK}
            >
                {translate('t.go_back')}
            </Button>
        ),
        [selectedVehicle.id]
    );

    const dateTimePickers = useMemo(() => <DateRangePickers />, []);

    const vehicleDropdown = useMemo(() => <VehicleDropdown generatePath={generatePath} navigate={navigate} />, []);

    return (
        <SeverityRankTopbarViewContent data-testid='SeverityRankTopbarContent'>
            {goBackButton}
            {dateTimePickers}
            {vehicleDropdown}
            <ApplyButton />
            <ZoomButtons />
        </SeverityRankTopbarViewContent>
    );
};

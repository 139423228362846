import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { AllHubsReading, DateTimePicker, SelectedVehicle, Timeline } from '../../states/global/Statistics';
import VehicleApi from '../../api/Vehicle';
import { VehicleList } from '../../models/Vehicle.type';
import { calculateGranularity, createTimeline, deepCopyObj } from 'helpers';
import { DateTime } from 'luxon';
import { StatisticsTopBarProps, ZoomAction, ZoomType } from './StatisticsTopBar.type';
import { DatePickerAction } from '../../pages/SeverityRank/atom';
import { StatisticsTopBarContent } from './StatisticsTopBar.view';
import { RedirectParams } from 'pages/Redirect/Redirect.atom';
import { Wrapper } from 'helpers/wrapper';
import AccountApi from 'api/Account';
import { useMutation } from '@tanstack/react-query';

const Account = new AccountApi();

export const ZoomLevels = (state: ZoomType, action) => {
    switch (action.type) {
        case ZoomAction.ZOOM_IN:
            return { zoomLevels: [...state.zoomLevels, action.zoom], lastZoom: [] };
        case ZoomAction.PREVIOUS_ZOOM: {
            let a = deepCopyObj(state.zoomLevels);
            a.pop();
            return { zoomLevels: a, lastZoom: [] };
        }
        case ZoomAction.RESET_ZOOM:
            return { zoomLevels: [], lastZoom: [] };
        default:
            throw new Error();
    }
};

const StatisticsTopBar: React.FC<StatisticsTopBarProps> = (props): JSX.Element => {
    const [dateTimePicker, setDateTimePicker] = useRecoilState(DateTimePicker);
    const setTimeline = useSetRecoilState(Timeline);
    const selectedVehicle = useRecoilValue(SelectedVehicle);
    const [vehicleList, setVehicleList] = useState<VehicleList>([]);
    const Vehicle = new VehicleApi();
    const datePeriodIsValid = useMemo<boolean>(() => {
        const fromLowerTo: boolean =
            dateTimePicker.current.dateFrom?.valueOf() <= dateTimePicker.current.dateTo?.valueOf();
        const fromIsValid: boolean = dateTimePicker.current.dateFrom && dateTimePicker.current.dateFrom?.isValid;
        const toIsValid: boolean = dateTimePicker.current.dateTo && dateTimePicker.current.dateTo?.isValid;
        return fromIsValid && toIsValid && fromLowerTo;
    }, [dateTimePicker]);
    const [showWarningMsg, setShowWarningMsg] = useState<boolean>(false);
    const [zoom, setZoom] = useReducer(ZoomLevels, { zoomLevels: [], lastZoom: [] });
    const redirectParams = useRecoilValue(RedirectParams);
    const disableButton = useMemo(() => props.currentTab !== 0, [props.currentTab]);
    const setAllHubsReading = useSetRecoilState(AllHubsReading);

    const getVehicleList = async (): Promise<void> => {
        const vehicelListXHR = await Vehicle.getList();
        // Temporary Solution
        setVehicleList(vehicelListXHR.filter((vehicle) => vehicle.icon !== 'hub/48x48/tyre_bay_hub.png'));
    };

    const resetDateTime = (): void => {
        setDateTimePicker((currentPicker) => ({
            ...currentPicker,
            current: { ...currentPicker.original },
            action: DatePickerAction.APPLY
        }));
        setZoom({ type: ZoomAction.RESET_ZOOM, zoom: [] });
    };

    const previousZoom = (): void => {
        const getLastZoom: number[] = zoom.zoomLevels[zoom.zoomLevels.length - 2];
        if (getLastZoom) {
            setDateTimePicker((currentPicker) => ({
                ...currentPicker,
                current: { dateFrom: DateTime.fromMillis(getLastZoom[0]), dateTo: DateTime.fromMillis(getLastZoom[1]) },
                action: DatePickerAction.PREVIOUS_ZOOM
            }));
        } else {
            resetDateTime();
        }
    };

    const { mutate: accountPatchMutate } = useMutation(Account.patchAllHubsChecked, {});

    const updateAllHubsReading = (checked: boolean) => {
        setAllHubsReading(checked);
        setTimeout(() => {
            accountPatchMutate(checked);
        }, 500);
    };

    useEffect(() => {
        setDateTimePicker({
            current: {
                dateFrom: redirectParams.params?.leakDateFrom
                    ? DateTime.fromISO(redirectParams.params.leakDateFrom)
                    : DateTime.now().minus({ days: 1 }),
                dateTo: redirectParams.params?.leakDateTo
                    ? DateTime.fromISO(redirectParams.params.leakDateTo)
                    : DateTime.now()
            },
            original: {
                dateFrom: redirectParams.params?.leakDateFrom
                    ? DateTime.fromISO(redirectParams.params.leakDateFrom)
                    : DateTime.now().minus({ days: 1 }),
                dateTo: redirectParams.params?.leakDateTo
                    ? DateTime.fromISO(redirectParams.params.leakDateTo)
                    : DateTime.now()
            },
            action: DatePickerAction.APPLY
        });
        getVehicleList();
    }, [redirectParams.params?.leakDateFrom, redirectParams.params?.leakDateTo]);

    useEffect(() => {
        if (selectedVehicle.id) {
            const granularity = calculateGranularity(dateTimePicker.current.dateFrom, dateTimePicker.current.dateTo);
            setTimeline({
                timeline: createTimeline(granularity, dateTimePicker.current.dateFrom, dateTimePicker.current.dateTo),
                granularity
            });

            if (dateTimePicker.action === DatePickerAction.ZOOM_IN) {
                setZoom({
                    type: ZoomAction.ZOOM_IN,
                    zoom: [dateTimePicker.current.dateFrom.valueOf(), dateTimePicker.current.dateTo.valueOf()]
                });
            }
            if (dateTimePicker.action === DatePickerAction.PREVIOUS_ZOOM) {
                setZoom({ type: ZoomAction.PREVIOUS_ZOOM, zoom: [] });
            }
        }
    }, [selectedVehicle, dateTimePicker]);

    return (
        <StatisticsTopBarContent
            data-testid='StatisticsTopBar-testid'
            vehicleList={vehicleList}
            datePeriodIsValid={datePeriodIsValid}
            showWarningMsg={showWarningMsg}
            zoom={zoom}
            disableButton={disableButton}
            setShowWarningMsg={setShowWarningMsg}
            resetDateTime={resetDateTime}
            previousZoom={previousZoom}
            updateAllHubsReading={updateAllHubsReading}
            {...props}
        />
    );
};

export default Wrapper(StatisticsTopBar);

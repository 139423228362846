import React from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { AlertsFilterVehicleInputContainerViewContent, CloseButton } from './AlertsFilterVehicleInputContainer.style';
import { AlertsFilterVehicleInputContainerProps } from './AlertsFilterVehicleInputContainer.type';
import { useTranslation } from 'react-i18next';
import { Tooltip, useTheme } from '@mui/material';
import {
    AlertVehicleFilters,
    AlertVehicleFiltersSelector,
    AppliedFilters,
    ApplyFilterTimeout
} from '../AlertsFilterVehiclesBy/atom';
import { FilterIds, ProcessingFilterSelection, SelectedAvailableFilterIds } from '../AlertsAvailableFilters/atom';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { AlertConfigurationPosition } from '../AlertsConfigurationFilterInput/atom';
import { RefetchAffectedVehicles } from '../AlertsAffectedVehicles/atom';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';

const AlertsFilterVehicleInputContainerContent: React.FC<AlertsFilterVehicleInputContainerProps> = (
    props
): JSX.Element => {
    const { t: translate } = useTranslation();
    const [vehicleFilter, setVehicleFilter] = useRecoilState(AlertVehicleFilters);
    const setSelectedFilters = useSetRecoilState(SelectedAvailableFilterIds);
    const resetFilter = useResetRecoilState(AlertVehicleFiltersSelector(props.filter.tableId));
    const resetConfiguration = useResetRecoilState(AlertVehicleFiltersSelector('vehicleConfiguration'));
    const resetAlertConfigurationPosition = useResetRecoilState(AlertConfigurationPosition);
    const theme = useTheme();
    const setAppliedFilters = useSetRecoilState(AppliedFilters);
    const refetchAffectedVehicles = useRecoilValue(RefetchAffectedVehicles);
    const [filterTimeout, setFilterTimeout] = useRecoilState(ApplyFilterTimeout);
    const setProcessingSelection = useSetRecoilState(ProcessingFilterSelection);

    const onVehicleModelCancel = () => {
        resetAlertConfigurationPosition();
        resetFilter();
        resetConfiguration();
    };

    const onVehicleCancel = () => {
        resetFilter();
        resetAlertConfigurationPosition();
        resetConfiguration();
    };

    const onVehicleConfiguration = () => {
        resetAlertConfigurationPosition();
        resetConfiguration();
    };

    const onVehicleGroupCancel = () => {
        resetFilter();
    };

    const onTyreModelCancel = () => {
        resetFilter();
    };

    const actionOnFilterCancel = {
        [FilterIds.VEHICLE]: onVehicleCancel,
        [FilterIds.VEHICLE_MODEL]: onVehicleModelCancel,
        [FilterIds.VEHICLE_CONFIGURATION]: onVehicleConfiguration,
        [FilterIds.VEHICLE_GROUP]: onVehicleGroupCancel,
        [FilterIds.TYRE_MODEL]: onTyreModelCancel
    };

    const onFilterCancel = () => {
        setSelectedFilters((current) =>
            current.filter((id) => {
                return id !== props.filter.id && !props.filter.withFilterRemoveAlso.includes(id);
            })
        );

        actionOnFilterCancel[props.filter.id]?.();

        setVehicleFilter((current) => {
            let data = { ...current };
            if (props.filter?.tableId) {
                data = {};
                // NOTE: using only delete key from object is causing huge lag
                const keys = Object.keys(current).filter((filter) => {
                    return filter !== props.filter.tableId;
                });
                for (let i = 0; i < keys.length; i++) {
                    data[keys[i]] = current[keys[i]];
                }
                return { ...data };
            }
            return { ...data };
        });

        filterTimeout && clearTimeout(filterTimeout);

        setFilterTimeout(
            setTimeout(() => {
                setAppliedFilters(vehicleFilter);
                refetchAffectedVehicles.refetch && refetchAffectedVehicles.refetch();
            }, 2000)
        );

        setProcessingSelection(true);
    };

    return (
        <AlertsFilterVehicleInputContainerViewContent data-testid='AlertsFilterVehicleInputContainerContent'>
            {props.children}
            <Tooltip title={translate('t.remove_filter')} disableInteractive>
                <CloseButton aria-label='delete' size='small' onClick={onFilterCancel} theme={theme}>
                    <UiIcon icon={faTimes} size='lg' />
                </CloseButton>
            </Tooltip>
        </AlertsFilterVehicleInputContainerViewContent>
    );
};

export default AlertsFilterVehicleInputContainerContent;

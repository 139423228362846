import * as Device from './Device.type';
import * as VehicleConfiguration from './VehicleConfiguration.type';
import * as VehicleGroup from './VehicleGroup.type';
import * as VehicleInfo from './VehicleInfo.type';
import Table from './Table.type';
import { ConfigurationV2 } from './VehicleConfiguration.type';
import { AlertTypes } from 'variables';
import { ExternalSystemModel } from './ExternalSystem.type';
import { Paginator } from 'helpers/api/type';
import { Sensor } from './Sensors.type';
import WheelModel from './Wheel.type';
import { typeFilter } from 'components/AlertNotification/TPMSAlertsListTabManager/TPMSAlertsListTabManager.type';
import { RimSizeModel } from './RimSize.type';
import { TyreModel } from './Tyre.type';

export type VehicleActionType = {
    vehicle: VehicleModel;
};

export type VehicleModel = {
    id: number;
    name: string;
    licensePlate: string;
    startOdometer: string | number;
    vehicleModel: string;
    comment: string;
    createdAt: string;
    alertsCount: number;
    icon: string;
    outOfService: boolean;
    tpmsFromForeignHubs: boolean;
    maintenance: boolean;
    hidden: boolean;
    maxSpeed: number | null;
    xAccel?: number;
    yAccel?: number;
    zAccel?: number;
    customRimSize: RimSizeModel | null;
    customRimSizeId: Pick<RimSizeModel, 'id'> | null;
    rimSize: RimSizeModel | null;
    device: Device.DeviceModel | null;
    vehicleGroup?: VehicleGroup.DropdownModel;
    vehicleConfiguration: VehicleConfiguration.VehicleConfigurationModel;
    vehicleInfo: VehicleInfo.VehicleInfoModel;
    vehicleManufacturerV2: string;
    vehicleConfigurationV2: ConfigurationV2;
    vehicleModelId: number;
    vehicleModelV2: string;
    inheritMaxSpeed: boolean;
    temperatureBasedMaxSpeed: boolean;
    maxSpeedTemperatureL1: number | null;
    maxSpeedTemperatureL2: number | null;
    maxSpeedTemperatureL3: number | null;
    passingVehicles: string[];
    vehicleManufacturerId: number;
    externalSystemVehicle: {
        externalSystemId?: number;
        id?: number;
        value: string;
        externalSystem?: ExternalSystemModel;
    }[];
    accelerometerIsCalibrated: boolean;
    predictionTemperature?: boolean;
    genericTemperatureAlert?: boolean;
    predictionTemperatureCriticalThreshold?: number;
    predictionTemperatureThresholdLevel0?: number;
    predictionTemperatureThresholdLevel1?: number;
    predictionTemperatureThresholdLevel2?: number;
    deviceLastTransmissionFrom?: string;
    deviceLastTransmissionTo?: string;
    createdAtFrom?: string;
    createdAtTo?: string;
    measuredAtFrom?: string;
    measuredAtTo?: string;
    displayLastTransmissionFrom?: string;
    displayLastTransmissionTo?: string;
};

type ActiveAlert = {
    vehicleId: number;
    level: number;
    type: string;
    type2: string;
    measuredAt: string;
};

export type LastPosition = {
    activeAlert: ActiveAlert;
    level?: number;
    measuredAt: Date;
    type?: string;
    type2?: string;
    vehicleId: number;
    altitude: number;
    comment: string;
    heading: number;
    icon: string;
    licensePlate: null;
    measured_at: Date;
    name: string;
    odometer: number;
    speed: number;
    latitude: number;
    longitude: number;
    device: Device.DeviceIdDeviceInfo;
};

export type LastPositionV2 = {
    journey: JourneyData[];
    vehicle: VehicleJourney;
    trackLog: TrackLogJourney;
    notificationEvent: ActiveAlert;
};

export type JourneyData = {
    vehicleId?: number;
    longitude: number;
    latitude: number;
    measuredAt: string;
    timekey?: number;
    speed?: number;
    altitude?: number;
};

export type VehicleJourney = {
    id: number;
    name: string;
    licensePlate: string;
    comment: string;
    icon: string;
    device: {
        id: number;
        deviceInfo: {
            lastTransmission: string;
        };
    };
};

export type TrackLogJourney = {
    measuredAt: Date;
    speed: number;
    latitude: number;
    longitude: number;
    altitude: number;
    heading: number;
    odometer: number;
};

export type NotificationEventJourney = {
    level: string | null;
    type: string;
    type2: string;
    measuredAt: string;
};

export type Journey = {
    lastPosition: LastPositionV2;
    journey: JourneyData[][];
};

export type JourneyParams = {
    dateFrom?: string;
    dataTo?: string;
};

export type Scale = {
    tyreScale: {
        minColdPressure: number;
        maxColdPressure: number;
        minHotPressure: number;
        maxHotPressure: number;
        minTemperature: number;
        maxTemperature: number;
    };
};

export type VehicleForCodeBook = {
    id: number;
    name: string;
    icon: string;
    active: boolean;
};

export type VehicleList = VehicleForCodeBook[];

export type VehicleCodebook = {
    codeBook: VehicleList;
};

export type TrackingStatistics = {
    avgDaySpeed: number;
    avgSpeed: number;
    maxSpeed: number;
    breaking: number;
    acceleration: number;
    distance: number;
    duration?: number;
    durationTime: number;
    inMotion: boolean;
    operationTime: {
        operatingTime: number;
        idlingTime: number;
        batteryTime: number;
    };
};

export type TempPressReportNew = {
    customPosition: number;
    level: number;
    limitFrom: number;
    limitTo: number;
    notificationId: number;
    pressureOptimal: number;
    type: string;
    type2: string;
    vehicleId: VehicleModel['id'];
    vehicleName: VehicleModel['name'];
    wheelId: WheelModel['id'];
};

export type TempPressReport = {
    vehicleId: number;
    vehicleName: string;
    wheelId: number;
    customPosition: number;
    id: number;
    type: string;
    pressureOptimal: number;
    level1Low: number;
    level2Low: number;
    level3Low: number;
    level1High: number;
    level2High: number;
    level3High: number;
    defaultLimit: number;
};

//Temporal - Codebook should use same structure as vehicle
export type VehicleCodeBook = {
    id: number;
    name: string;
    icon: string;
    active: boolean;
};

export type VehicleCodeBookResponse = {
    codeBook: VehicleCodeBook[];
};

//Please use items from table entity
export interface TempPressReportResponse extends Table {
    tempPressLevelReport: TempPressReportTable;
}

export type TempPressReportTable = Paginator<TempPressReport> | Paginator<TempPressReportNew>;

export interface VehicleResponse extends Table {
    items: VehicleModel[];
}

export interface Vehicle {
    vehicle: VehicleModel;
}

export interface VehicleNotTransmittingResponse extends Table {
    items: VehicleIdName[];
}

export type VehicleIdName = {
    id: number;
    name: string;
};

type WheelDetail = {
    sensorByPeriod: {
        wheelId: number;
        customPosition: number;
        sensor: number[];
        sensorsInPeriod:
            | {
                  serialNumber: string | number;
                  serialNumberHex: string;
                  sensorType: number;
                  faulty: number;
              }[]
            | null;
        overlappingSensor?: Sensor[] | null;
        currentSensor: {
            id: number;
            serialNumber: string | number;
            serialNumberHex: string;
            sensorType: number;
            priority: number;
            faulty: number;
        }[];
        sensorCount: number;
        optimalPressure: number;
    };
    overLapping?: boolean;
    realSensor?: number;
};

type WheelData = {
    vehicleId: number | null;
    wheelId: number | null;
    hotPressure: number | null;
    coldPressure: number | null;
    temperature: number | null;
    sensorTemperature: number | null;
    temperatureStatus: number | null;
    pressureStatus: number | null;
    timeKey: number;
    measuredAtScale: string | null;
    sensorId: number | null;
    sensorTypeId: number | null;
    sensorName: string | null;
    vehicleName: string | null;
    showAlienData?: boolean;
    readByVehicleId: number | null;
    readByVehicleName: string | null;
    optimalPressure?: number | null;
};

type SpeedData = {
    measuredAtScale?: string | null;
    timeKey: number;
    speedMax?: number | null;
    speedMin?: number | null;
    speedAvg?: number | null;
    speed?: number | null;
};

export type SensorsByPeriod = {
    statistics: {
        wheels: {
            detail: WheelDetail;
            data: WheelData[];
        }[];
        speed: SpeedData[];
    };
};

export type speedHeatmapType = {
    longitude: number;
    latitude: number;
    vehicleId: number;
    speed: number | null;
};

export type VehicleStatus = {
    vehicle: VehicleStatusData[];
};

export type VehicleStatus2 = {
    vehicleStatus: VehicleStatus2Data[];
};

export type VehicleStatus2Data = {
    vehicle: {
        id: number;
        name: string;
        licencePlate: string;
        vehicleGrupId: number;
        vehicleConfigurationId: number;
        icon: string;
        outOfService: boolean;
        maintenance: boolean;
        vehicleInfo: VehicleInfo.VehicleInfoModel;
        deviceId?: number;
        vehicleModelId?: number;
        genericTemperatureAlert: boolean;
        predictionTemperature: boolean;
        lastTemperaturePredictedAt: null | string;
        wheels: Omit<WheelModel, 'vehicle'>[] | null;
    };
    device: {
        id: number;
        deviceInfo: {
            network: string;
            shutdown: string;
        };
    } | null;
    alert: AlertsOnVehicle;
    bes: {
        position: number;
        tyreSerialNumber: string;
        tyreId: number;
        createdAt: string;
        besSeverityRankTotal: number;
        besSeverityRank3dAvg: number;
        payload: number;
        ipHotDailyAvg: number;
        chamberTempDailyAvg: number;
        chamberTempTotalHistory: number;
        ipColDailyAvg: number;
    }[];
    highestBes: number;
    iconAlert: {
        type: typeFilter;
        type2: string;
        level: number;
    }[];
};

export type VehicleStatusData = {
    id: number;
    data: {
        id: number;
        name: string;
        licencePlate: string;
        vehicleGroupId: number;
        vehicleConfigurationId: number;
        icon: string;
        outOfService: number;
        maintenance: number;
        deviceId: null | number;
        measuredAt: string | null;
        battery: null | number;
        powerSource: number;
        display: string | null;
        displayUpdateTime: string | null;
        lastGpsMeasuredAt: string;
        parkingTime: number;
        shutdown: null | number;
        severityAlert: number;
        levelAlert: number;
        network: string;
    };
    alerts: AlertsOnVehicle;
};

export type AlertsOnVehicle = {
    levelAlert: number;
    levelType: AlertTypes;
}[];

export type GetExcessiveAccParams = {
    vehicle?: number;
    measuredFrom: string;
    measuredTo: string;
    loaded?: number;
    vehicleOperator?: number | null;
};

export type VehiclesWithPaginator = Paginator<VehicleModel>;

export enum VehicleQueryKeys {
    getLazyDropdown = 'vehicle-dropdown',
    getVehicleStatus = 'vehicle-getVehicleStatus',
    getCodeBook = 'vehicle-getCodeBook',
    getVehicleModel = 'vehicle-getVehicleModel',
    getVehicleManufacturer = 'vehicle-getVehicleManufacturer',
    getList = 'vehicle-loadVehicleListQuery-vehicleMultiSelect',
    getAffectedVehicles = 'vehicle-getAffectedVehicles',
    getVehicleById = 'vehicle-getVehicleById',
    getTrackingStatistics = 'vehicle-getTrackingStatistics',
    getByExtendUrlId = 'vehicle-getByExtendUrlId',
    getAllAsCodebook = 'vehicle-getAllAsCodebook',
    getLastPosition = 'vehicle-getLastPosition',
    downloadTempPressReportAsCSV = 'vehicle-downloadTempPressReportAsCSV',
    getAlerts = 'vehicle-getAlerts'
}

export type VehicleTyreModel = Pick<
    VehicleModel,
    'id' | 'name' | 'vehicleConfigurationV2' | 'vehicleManufacturerV2' | 'vehicleModelId'
> & {
    wheel: (Pick<WheelModel, 'customPosition' | 'id' | 'position'> & {
        tyre: Pick<TyreModel, 'id' | 'tyreManufacturerId' | 'tyreModelId' | 'tyreSerialNumber'>;
    })[];
};

export type VehicleTyreModelWithPaginator = Paginator<VehicleTyreModel>;

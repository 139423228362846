import React from 'react';
import { LazyAutocomplete, ReplaceSensorContainer } from './ReplaceSensor.style';
import { InitialValues, ReplaceSensorViewProps } from './ReplaceSensor.type';
import { useTranslation } from 'react-i18next';
import { Form, Formik, FormikProps } from 'formik';
import { CircularProgress, Grid, useMediaQuery, useTheme } from '@mui/material';
import DropDown from '../../Ui/Components/UiDropDown/UiDropDown';
import * as Yup from 'yup';
import Sensors from 'api/Sensors';
import { VARIANT_BUTTON } from '../../Ui/variables';
import UiButton from 'components/Ui/Components/UiButton/UiButton';
import { SensorTypes } from 'models/SensorType.type';
import { UnmountReason } from 'variables';
import MuiDialogContent from '@mui/material/DialogContent';
import { ModalActionTypesEnum } from 'components/CustomHooks/ModalHook/ModalHook.type';
import ReportSensor from '../ReportSensor/ReportSensor';
import { CancelButton, ContainerActions, CustomDialogActions } from 'components/StatedModal/Modal/Modal.style';
import { SensorQueryKeys } from 'models/Sensors.type';

export const ReplaceSensorContent: React.FC<ReplaceSensorViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const sensorsApi = new Sensors();
    const schemaValidateForm = Yup.object({
        unmountReason: Yup.string().trim().required(translate('p.this_field_is_required')),
        newSensorTypeId: Yup.number().required(translate('p.this_field_is_required'))
    });
    const theme = useTheme();
    const showXsSm = useMediaQuery(theme.breakpoints.between('xs', 'sm'));

    return (
        <ReplaceSensorContainer data-testid='ReplaceSensorContent'>
            <Formik
                initialValues={props.initialValues}
                enableReinitialize
                validationSchema={schemaValidateForm}
                onSubmit={(values: InitialValues) => {
                    props.handleSubmit(values);
                }}
            >
                {(propsFormik: FormikProps<InitialValues>) => {
                    const { errors, setFieldValue, values } = propsFormik;
                    const showSensorReport = !props.isFaulty && values.unmountReason === UnmountReason.REMOVING_FAULTY;

                    return (
                        <Form id='sensor-replaced-action-form'>
                            <MuiDialogContent sx={{ paddingTop: '20px' }}>
                                <Grid container justify-content='flex-start' direction='row' spacing={2}>
                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                        <LazyAutocomplete
                                            id='replace-sensor'
                                            label={`${translate('t.new')} ${translate('t.sensor_serial_number')}`}
                                            queryId={SensorQueryKeys.getLazyDropdown} //'sensor_serial_number_autocomplete'}
                                            query={sensorsApi.getTable}
                                            onValueChange={(option) => {
                                                setFieldValue('newSensorId', option);
                                            }}
                                            optionValue='id'
                                            optionKey='serialNumberHex'
                                            fullWidth
                                            defaultFilter={[
                                                { id: 'hasVehicle', value: { name: 'hasVehicle', value: '0' } }
                                            ]}
                                        />
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                        {props.sensorTypeLoading ? (
                                            <CircularProgress />
                                        ) : (
                                            <DropDown
                                                options={props.sensorType}
                                                inputName='new_sensor_type'
                                                label={translate('t.new_sensor_type')}
                                                value={values.newSensorTypeId ? values.newSensorTypeId : ' '}
                                                defaultValue={values.newSensorTypeId ?? null}
                                                error={errors.newSensorTypeId}
                                                disabled={false}
                                                getValue={(e) => {
                                                    setFieldValue('newSensorTypeId', e ?? null);
                                                }}
                                            />
                                        )}
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                        {props.unmountReasonLoading ? (
                                            <CircularProgress />
                                        ) : (
                                            <DropDown
                                                options={props.unmountReasonList}
                                                inputName='unmount_reason'
                                                label={translate('t.unmount_reason')}
                                                value={values.unmountReason ? values.unmountReason : ' '}
                                                error={errors.unmountReason}
                                                disabled={false}
                                                getValue={(e) => {
                                                    setFieldValue('unmountReason', e ?? null);
                                                }}
                                            />
                                        )}
                                    </Grid>
                                    <Grid md={12} marginTop={1}>
                                        {showSensorReport && (
                                            <ReportSensor
                                                vehicleId={props.sensor.wheel.vehicle?.id || 0}
                                                vehicleName={props.sensor.wheel.vehicle?.name || ''}
                                                sensorId={props.sensor.id || 0}
                                                sensorName={props.sensor.serialNumberHex || ''}
                                                showOnlyGraph={true}
                                                wheelPosition={props.sensor.wheel.position}
                                                internalOnVehicle={
                                                    props.sensor.sensorType?.name == SensorTypes.INTERNAL
                                                }
                                                isExternal={props.sensor.sensorType?.name == SensorTypes.EXTERNAL}
                                                allowReportLeakAlert={true}
                                                afterAction={() =>
                                                    props.queryTableFn.refetch instanceof Function &&
                                                    props.queryTableFn.refetch()
                                                }
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                            </MuiDialogContent>
                            <ContainerActions>
                                <CustomDialogActions>
                                    <UiButton
                                        form='sensor-replaced-action-form'
                                        color='primary'
                                        testid='Replace-sensor-button'
                                        variant={VARIANT_BUTTON}
                                        type='submit'
                                        loading={props.replaceSensorLoading}
                                        disabled={showSensorReport}
                                        onClick={() => {
                                            propsFormik.submitForm();
                                        }}
                                    >
                                        {translate('t.replace')}
                                    </UiButton>
                                    <CancelButton
                                        data-testid='Modal-Cancel-Button'
                                        onClick={() => {
                                            props.modalDispatch({
                                                type: ModalActionTypesEnum.CLOSE_MODAL
                                            });
                                        }}
                                        variant='outlined'
                                        color='primary'
                                        size='small'
                                    >
                                        {translate('t.cancel')}
                                    </CancelButton>
                                </CustomDialogActions>
                            </ContainerActions>
                        </Form>
                    );
                }}
            </Formik>
        </ReplaceSensorContainer>
    );
};

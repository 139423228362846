import React from 'react';
import { General } from '../../Popup/Popup';
import AlertCustom from '../AlertCustom/AlertCustom';
import ContentAlertByVehicle from '../AlertsList/ContentAlertsList.view';
import { FloatAlertNotificationsPropsView } from './FloatAlertNotifications.type';
import { typeAlert } from '../TPMSAlertsListTabManager/TPMSAlertsListTabManager.type';
import VisibilityIcon from '@mui/icons-material/Visibility';

export const FloatAlertNotifications = ({ notificationEvent, markAsRead }: FloatAlertNotificationsPropsView): void => {
    General({
        content: (
            <AlertCustom
                isTempPredAlert={notificationEvent.notification?.type === 'temperature_prediction'}
                level={notificationEvent.notification.level}
                chip={notificationEvent.notification.type == 'battery_voltage' ? false : true}
                content={
                    <ContentAlertByVehicle
                        alert={notificationEvent}
                        typeAlertContent={
                            notificationEvent.notification.type == 'battery_voltage' ? typeAlert.Sensor : typeAlert.TPMS
                        }
                        displayBody={true}
                    />
                }
                link={`/vehicle-status?vehicle=${notificationEvent.vehicle.id}`}
                key={`alert-float-${notificationEvent.id}`}
                keyProps={`alert-float-${notificationEvent.id}`}
                footer={notificationEvent.measuredAt}
                float={true}
                action={
                    <button onClick={(event) => markAsRead(event, notificationEvent.id)}>
                        <VisibilityIcon />
                    </button>
                }
                sizeAlert='sm'
            />
        ),
        customSetting: {
            toastId: `float-alert-${notificationEvent.id}`,
            closeButton: false
        }
    });
};

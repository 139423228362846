import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import styled from 'styled-components';
import { BACKGROUND_DARK_MODE, BACKGROUND_PAPER_DARK_MODE, PRIMARY, WHITE } from 'components/Ui/colors';
import Typography from '@mui/material/Typography';
import { Alert, CardHeader, Switch, Table, TableHead, useTheme } from '@mui/material';
import { applyStyleByMode } from 'helpers';
import { CommonTypeTheme } from 'components/Ui/Theme';

export const VehicleActionsViewContent = styled.div``;

export const CustomTextfield = styled(TextField)`
    &.MuiTextField-root {
        margin-bottom: 8px;
    }
`;

export const TempPredTextfield = styled(TextField)`
    &.MuiTextField-root {
        margin: 12px 5px 8px 5px;
    }
`;

export const VehicleLayoutCover = styled(Box)`
    &.MuiBox-root {
        display: inline-block;
        width: 100%;
    }
`;

export const Content = styled(Paper)<{ selected?: boolean }>`
    &.MuiPaper-root {
        padding: 6px;
        margin: 10px;
        text-align: center;
        border: thin solid #bfbfbf;
        display: inline-block;
        width: 122px;
        cursor: pointer;
        border-width: ${(props) => (props.selected ? '3px' : 'thin')};
        border-color: ${(props) => (props.selected ? PRIMARY : '#bfbfbf')};
        :hover {
            border-color: ${PRIMARY};
        }
    }
`;

export const VehicleLayoutLabel = styled.span`
    position: absolute;
    margin-left: 8px;
    padding: 0 5px;
    color: rgba(0, 0, 0, 0.54);
    ${() => {
        const common = useTheme().palette.common as CommonTypeTheme;
        return applyStyleByMode({
            theme: useTheme().palette.mode,
            styleJade: `
                margin-top: -14px;
                font-size: ${common.textSize};
            `,
            light: `background: ${WHITE};`,
            dark: `
                color: ${WHITE};
                background: ${BACKGROUND_PAPER_DARK_MODE};
            `
        });
    }}
`;

export const VehicleLayouContent = styled.div`
    width: 160px;
    height: 155px;
    border: thin #bfbfbf solid;
    border-radius: 4px;
    position: relative;
    padding-bottom: 20px;
    font-size: 14px;
`;

export const CustomAutocomplete = styled(Autocomplete)`
    &.MuiAutocomplete-root {
    }
`;

export const RequestProgress = styled(CircularProgress)({
    color: '#4f714f !important',
    width: '25px !important',
    height: '25px !important',
    margin: '6.5px',
    marginRight: '15px !important'
});

export const RemoveTextBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 50px;
    margin: 10px 0;
`;

export const Text = styled(Typography)``;

export const AlertInfo = styled(Alert)`
    margin-top: 10px;
`;

export const TextDiv = styled.div`
    margin: 5px 0;
`;

export const SingleVehicleConfig = styled.div`
    height: 160px;
    width: 160px;
    padding-top: 5px;
    padding-left: 12px;
`;

export const TooltipText = styled.div`
    margin: 5px;
    max-width: 200px;
`;

export const StyledTable = styled(Table)`
    border: thin solid #d1d1d1;
`;

export const StyledTableHead = styled(TableHead)`
    & .MuiTableHead-root {
        background-color: red !important;
    }
`;

export const CustomBox = styled(Box)<{ $noBorder?: boolean; $height: string }>`
    height: ${(props) => props.$height};
    ${({ $noBorder }) => ($noBorder ? `` : ` border: solid thin #bfbfbf;`)}

    border-radius: 4px;

    padding: 5px 10px;
    position: relative;
`;

export const HubBayContainer = styled.div`
    padding: 10px 0px;
    border: solid thin #bfbfbf;
    border-radius: 4px;
    width: 100px;
    cursor: not-allowed;
    margin-top: 5px;
    text-align: center;
    ${() => {
        const theme = useTheme();
        return applyStyleByMode({
            theme: theme.palette.mode,
            light: `background: ${WHITE};`,
            dark: `background: ${BACKGROUND_DARK_MODE};`
        });
    }}
`;

export const HubBayIconLock = styled.div`
    width: 100%;
    border-left: solid thin #bfbfbf;
    height: 40px;
    & svg {
        margin-top: 13px;
    }
`;

export const FormikSectionContainer = styled.div<{ noBorderBottom?: boolean; hide?: boolean }>`
    ${({ noBorderBottom }) => {
        return noBorderBottom
            ? `margin: 15px 8px 0px 8px;`
            : `margin: 15px 8px; 
               border-bottom: 1px solid rgba(0, 0, 0, 0.12);
               `;
    }}
    ${({ hide }) => {
        return hide ? `display: none` : ``;
    }}
`;

export const FormikSectionHeader = styled(CardHeader)`
    &.MuiCardHeader-root {
        padding: 5px 5px 5px 16px;
    }
`;

export const LabelContainer = styled.div`
    display: flex;
    margin-bottom: 6px;
    justify-content: space-between;
`;
export const ExternalSystemContainer = styled.div``;

export const AditionalConfgControl = styled(Switch)`
    &.MuiSwitch-root {
        margin-left: 25px;
    }
`;

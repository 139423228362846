import PortalApi from 'helpers/api/PortalApiClient';
import {
    Journey,
    JourneyParams,
    VehicleModel,
    SensorsByPeriod,
    TrackingStatistics,
    VehicleList,
    VehicleResponse,
    VehicleStatus2,
    LastPositionV2,
    VehicleTyreModelWithPaginator
} from '../models/Vehicle.type';
import * as type from '../models/Vehicle.type';
import { PaginatorParams } from '../models/Paginator.type';
import { getParams } from 'helpers';
import { DateTime } from 'luxon';
import { VehicleSettingsValues } from 'components/Vehicle/VehicleDetailsSettings/VehicleDetailsSettings.type';
import { Paginator } from 'helpers/api/type';

export default class Vehicle extends PortalApi<VehicleModel> {
    route = 'vehicle';

    getList = async (vehicleGroup?: number): Promise<VehicleList> => {
        let params = {
            vehicleGroup: vehicleGroup || undefined
        };

        return await this.getCodebook<VehicleList>({ criteria: params as Record<string, string | number | string[]> });
    };

    getLastPosition = async (vehicleId?: number): Promise<LastPositionV2[]> => {
        let params: { vehicleId: number } | {} = {};
        if (vehicleId) {
            params['vehicleId'] = vehicleId;
        }
        return this.getByCriteria({ version: 'v2', extendUrl: 'last-position', criteria: params });
    };

    getJourney = async (
        vehicleId: number,
        dateFrom?: string | DateTime,
        dateTo?: string | DateTime
    ): Promise<Journey> => {
        let params: JourneyParams = {};

        if (dateFrom) {
            params['measuredFrom'] =
                typeof dateFrom === 'string'
                    ? DateTime.fromSQL(dateFrom).toFormat('yyyy-MM-dd HH:mm:ss')
                    : dateFrom.toFormat('yyyy-MM-dd HH:mm:ss');
        }
        if (dateTo) {
            params['measuredTo'] =
                typeof dateTo === 'string'
                    ? DateTime.fromSQL(dateTo).toFormat('yyyy-MM-dd HH:mm:ss')
                    : dateTo.toFormat('yyyy-MM-dd HH:mm:ss');
        }
        return this.getByCriteria({ version: 'v2', extendUrl: `${vehicleId}/journey`, criteria: params });
    };

    getTrackingStatistics = async (
        vehicleId: number,
        dateFrom?: string,
        dateTo?: string
    ): Promise<TrackingStatistics> => {
        let params: JourneyParams = {};

        if (dateFrom) {
            params['measuredFrom'] = dateFrom;
        }
        if (dateTo) {
            params['measuredTo'] = dateTo;
        }
        return await this.getByCriteria({ extendUrl: `${vehicleId}/tracking-statistics`, criteria: params }).then(
            (d) => d['trackingStatistics']
        );
    };

    getVehicleTable = async (props): Promise<VehicleResponse> => {
        return this.getByCriteria({ criteria: getParams(props) });
    };

    getSensorsByPeriod = async (vehicleId, measuredFrom, measuredTo): Promise<SensorsByPeriod> => {
        let params: JourneyParams = {};
        if (measuredFrom) {
            params['measuredFrom'] = measuredFrom;
        }
        if (measuredTo) {
            params['measuredTo'] = measuredTo;
        }
        return this.getByCriteria({ extendUrl: `${vehicleId}/statistics`, criteria: params });
    };

    getTempPressReport = async (props) => {
        let criteria = {};

        if (props.additionalProps.vehicleId > 0) {
            criteria['vehicleId'] = props.additionalProps.vehicleId;
        }

        return this.getByCriteria<type.TempPressReportResponse>({ extendUrl: 'temp-press-report', criteria });
    };

    getAllAsCodebook = async () => {
        return await this.getCodebook<type.VehicleCodeBook>({});
    };

    downloadTempPressReportAsCSV = async (props: { vehicleId: number }) => {
        let criteria = {};

        if (props.vehicleId > 0) {
            criteria['vehicleId'] = props.vehicleId;
        }

        return this.getByCriteria<BlobPart>({ extendUrl: 'temp-press-report/export', criteria });
    };

    getNotTransmitting = async (page: number, limit: number): Promise<type.VehicleNotTransmittingResponse> => {
        let params: PaginatorParams = { page, limit };

        return this.getByCriteria({ extendUrl: 'not-transmitting', criteria: params });
    };

    getVehicleStatusData = async (
        filterBy: number,
        sortBy: string,
        filterByVehicleGroup?: number
    ): Promise<VehicleStatus2> => {
        let options = {};

        if (sortBy.includes('+')) {
            options['order'] = ['severity_alert:desc', 'name:asc'];
        } else {
            options['order'] = sortBy;
        }

        if (filterBy > 0) {
            options['level_alert'] = filterBy;
        }

        if (filterByVehicleGroup) {
            options['vehicleGroup'] = filterByVehicleGroup;
        }

        return this.getByCriteria({ extendUrl: 'status', criteria: options });
    };

    getExport = async (props): Promise<string> => {
        return this.getByCriteria({ extendUrl: 'export', criteria: getParams(props) });
    };

    getUniqVehicleName = async (vehicleName): Promise<VehicleModel> => {
        this.showError404 = false;
        return await this.get({ extendUrl: `name/${vehicleName}` });
    };

    setVehicleSettings = async (data: VehicleSettingsValues): Promise<VehicleModel> => {
        return await this.patchById({ id: data.id, data: data });
    };

    getVehicleLazyDropdown = async (props): Promise<Paginator<any, VehicleModel>> => {
        return await this.getTable(props);
    };

    getVehicleById = async (vehicleId: number): Promise<VehicleModel> => {
        return this.getById({ id: vehicleId });
    };

    postCounterAction = async (vehicleId: number, counterActionId: number[]): Promise<unknown> => {
        return await this.post({
            extendUrl: `${vehicleId}/counter-action`,
            data: {
                counterActionIds: counterActionId
            }
        });
    };

    getVehicleTyreModel = async (tyreModelId: number, criteria: any): Promise<VehicleTyreModelWithPaginator> => {
        return this.getTable({ extendUrl: `tyre-model/${tyreModelId}`, criteria });
    };
}

import React from 'react';
import { VehicleStatusViewProps } from './VehicleStatus.type';
import VehicleStatusWidget from 'components/Vehicle/VehicleStatusWidget';
import VehicleDetailsWidget from 'components/Vehicle/VehicleDetailsWidget';
import { VehicleStatusContainer } from './VehicleStatus.style';
import { Grid } from '@mui/material';

export const VehicleStatusContent: React.FC<VehicleStatusViewProps> = (props): JSX.Element => (
    <VehicleStatusContainer data-testid='VehicleStatusContent'>
        <Grid container spacing={2}>
            <Grid item xs={12} lg={5} md={12}>
                <VehicleStatusWidget {...props} />
            </Grid>
            <Grid item xs={12} lg={7} md={12}>
                <VehicleDetailsWidget {...props} />
            </Grid>
        </Grid>
    </VehicleStatusContainer>
);

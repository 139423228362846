import React from 'react';
import { useRecoilValue } from 'recoil';
import { WheelAlertsLogsViewContent } from './WheelAlertsLogs.style';
import { WheelAlertsLogsProps } from './WheelAlertsLogs.type';
import { useTranslation } from 'react-i18next';
import { LazyLoadingAlertsList } from 'components/Vehicle/VehicleDetails/LazyLoadingAlertsList';
import { typeAlert } from 'components/AlertNotification/TPMSAlertsListTabManager/TPMSAlertsListTabManager.type';
import { VehicleDetailsWidgetAtom } from 'components/Vehicle/VehicleDetailsWidget/VehicleDetailsWidget.atom';
import { Divider } from '@mui/material';
import { VehicleModel } from 'models/Vehicle.type';

const PAGE_COUNT = 20;

const WheelAlertsLogsContent: React.FC<WheelAlertsLogsProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();

    const vehicleDetailsWidgetAtom = useRecoilValue(VehicleDetailsWidgetAtom());

    return (
        <WheelAlertsLogsViewContent data-testid='WheelAlertsLogsContent'>
            <Divider>{translate('t.active_alerts')}</Divider>
            <LazyLoadingAlertsList
                queryId={'_alertCurrentLog'}
                limit={20}
                alertType={typeAlert.HistoricalLog}
                vehicleId={(vehicleDetailsWidgetAtom.vehicleDetails as VehicleModel)?.id}
                changeNumberOfAlerts={() => {}}
                tabNumber={2}
                displayAlertActions
                showSolveButton
                showValidateButton
                solved={false}
                wheelId={props.wheelId}
                tab='log'
            />
            <Divider>{translate('t.history_alerts')}</Divider>
            <LazyLoadingAlertsList
                queryId={'_alertHistoryLog'}
                limit={PAGE_COUNT}
                alertType={typeAlert.HistoricalLog}
                vehicleId={(vehicleDetailsWidgetAtom.vehicleDetails as VehicleModel)?.id}
                changeNumberOfAlerts={() => {}}
                tabNumber={2}
                displayAlertActions={false}
                solved={true}
                dense
                wheelId={props.wheelId}
                tab={'log'}
            />
        </WheelAlertsLogsViewContent>
    );
};

export default WheelAlertsLogsContent;

import React, { useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { AlertsAffectedVehiclesViewContent, ExpandIconButton } from './AlertsAffectedVehicles.style';
import { AlertsAffectedVehiclesProps } from './AlertsAffectedVehicles.type';
import { useTranslation } from 'react-i18next';
import { Collapse, Grid, Typography } from '@mui/material';
import AlertsAffectedVehiclesFilter from '../AlertsAffectedVehiclesFilter';
import AlertsAffectedVehiclesList from '../AlertsAffectedVehiclesList';
import { AffectedVehiclesCount } from './atom';
import { AlertInfo } from '../AlertsAffectedVehiclesList/AlertsAffectedVehiclesList.style';
import AlertsDefaultVehiclesList from '../AlertsDefaultVehiclesList/AlertsDefaultVehiclesList';
import { RULE_TYPE } from 'models/Rule.type';
import CircularProgress from '@mui/material/CircularProgress';
import { CollapseContent } from 'components/Ui/Components/UiWidget/UiWidget.style';
import UiButton from 'components/Ui/Components/UiButton/UiButton';
import { SettingSteps, SettingsStep } from '../AlertSettings/atom';
import { SetAlertsStepBadge } from 'pages/SetAlerts/SetAlerts.style';
import CheckIcon from '@mui/icons-material/Check';
import { AppliedFilters } from '../AlertsFilterVehiclesBy/atom';
import { ProcessingFilterSelection } from '../AlertsAvailableFilters/atom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const AlertsAffectedVehiclesContinueButton: React.FC<{ isOpen?: boolean }> = ({ isOpen }): JSX.Element => {
    const isProcessingSelection = useRecoilValue(ProcessingFilterSelection);
    const affectedVehiclesCount = useRecoilValue(AffectedVehiclesCount);
    const setSettingsStep = useSetRecoilState(SettingsStep);
    const { t: translate } = useTranslation();
    return (
        <Grid
            item
            xs={12}
            md={12}
            lg={12}
            justifyContent={'right'}
            display={'flex'}
            paddingTop={isOpen ? '10px' : '0'}
            width={'100%'}
        >
            <UiButton
                variant='contained'
                data-testid='ContinueSetAlertsButton'
                disabled={!affectedVehiclesCount.current || isProcessingSelection}
                onClick={() => setSettingsStep(SettingSteps.TEMPERATURE_PRESSURE_SETTING)}
            >
                {translate('t.continue')}
            </UiButton>
        </Grid>
    );
};

const AlertsAffectedVehiclesContent: React.FC<AlertsAffectedVehiclesProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const affectedVehiclesCount = useRecoilValue(AffectedVehiclesCount);
    const settingsStep = useRecoilValue(SettingsStep);
    const [open, setOpen] = useState<boolean>(false);

    return (
        <AlertsAffectedVehiclesViewContent
            data-testid='AlertsAffectedVehiclesContent'
            id='AlertsAffectedVehiclesContainer'
        >
            <Grid
                container
                minHeight={'45px'}
                xs
                md
                lg
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
            >
                <Grid item xs={12} md={4} lg={3} display={'flex'} alignItems={'center'} height={'35px'}>
                    <Typography variant='subtitle1'>
                        <SetAlertsStepBadge>
                            {SettingSteps.SELECT_FILTER < settingsStep ? (
                                <CheckIcon fontSize={'inherit'} />
                            ) : (
                                SettingSteps.SELECT_FILTER + 2
                            )}
                        </SetAlertsStepBadge>{' '}
                        {`${translate('t.affected_vehicles')}: `}
                        {affectedVehiclesCount.current === -1 ? (
                            <CircularProgress size={'13px'} />
                        ) : (
                            affectedVehiclesCount.current
                        )}{' '}
                        {translate('t.vehicles')}
                        {SettingSteps.SELECT_FILTER === settingsStep && (
                            <ExpandIconButton
                                expand={open}
                                size='small'
                                onClick={() => {
                                    setOpen((current) => !current);
                                }}
                            >
                                <ExpandMoreIcon />
                            </ExpandIconButton>
                        )}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={3} spacing={1} height={'35px'}>
                    {props.ruleType === RULE_TYPE.WIZARD && (
                        <AlertInfo severity='info'>
                            {!affectedVehiclesCount.current
                                ? translate('t.no_affected_vehicles')
                                : translate('t.following_vehicles_affected')}
                        </AlertInfo>
                    )}
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={4}
                    lg={6}
                    display={'flex'}
                    justifyContent={'right'}
                    alignItems={'center'}
                    height={'35px'}
                >
                    {!open && SettingSteps.SELECT_FILTER === settingsStep && <AlertsAffectedVehiclesContinueButton />}
                    <AlertsAffectedVehiclesFilter />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <Collapse in={settingsStep === SettingSteps.SELECT_FILTER && open} timeout='auto'>
                        <CollapseContent $paddingcontent='10px 0' style={{ paddingBottom: '5px' }}>
                            <Grid container>
                                <Grid item xs={12} md={12} lg={12}>
                                    {props.ruleType === RULE_TYPE.WIZARD ? (
                                        <AlertsAffectedVehiclesList />
                                    ) : (
                                        <AlertsDefaultVehiclesList />
                                    )}
                                </Grid>
                                {open && <AlertsAffectedVehiclesContinueButton isOpen />}
                            </Grid>
                        </CollapseContent>
                    </Collapse>
                </Grid>
            </Grid>
        </AlertsAffectedVehiclesViewContent>
    );
};

export default AlertsAffectedVehiclesContent;

import React from 'react';
import { useRecoilValue } from 'recoil';
import { SeverityRankViewContent } from './SeverityRank.style';
import { SeverityRankViewProps } from './SeverityRank.type';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import SeverityRankTopbar from 'components/Tyre/SeverityRankTopbar';
import SeverityRankGraphs from 'components/Tyre/SeverityRankGraphs';
import { faTruck } from '@fortawesome/pro-solid-svg-icons';
import VehicleSchema from 'components/Vehicle/NewVehicleSchema';
import Widget from 'components/Ui/Components/UiWidget';
import { applyStyleByMode } from 'helpers';
import { SelectedVehicle } from 'states/global/Statistics';
import UiCircularProgress from 'components/Ui/Components/UiCircularProgress';
import UiIcon from 'components/Ui/Components/UiIcon';
import { Theme } from 'states/global/Theme';
import { WHITE } from 'components/Ui/colors';
import { CustomerSettings } from 'states/global/CustomerSettings';

export const SeverityRankContent: React.FC<SeverityRankViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const selectedVehicle = useRecoilValue(SelectedVehicle);
    const customerSettings = useRecoilValue(CustomerSettings);
    const ThemeMode = useRecoilValue(Theme);
    return (
        <SeverityRankViewContent data-testid='SeverityRankContent'>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={1}>
                    <Grid container spacing={1} xs={12} md={12} lg={12}>
                        <Grid item xs={12} md={3} lg={2}></Grid>
                        <Grid item xs={8} md={9} lg={10}>
                            <SeverityRankTopbar />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} xs={12} md={12} lg={12}>
                        <Grid item xs={12} md={3} lg={2}>
                            <Widget
                                title={`${translate('t.vehicle')} ${selectedVehicle.name || props.vehicleName}`}
                                avatar={
                                    <UiIcon
                                        icon={faTruck}
                                        size='lg'
                                        fixedWidth
                                        color={applyStyleByMode({
                                            theme: ThemeMode?.mode,
                                            light: '#0000008a',
                                            dark: WHITE
                                        })}
                                    />
                                }
                            >
                                <Box style={{ height: '100%', paddingBottom: '30px' }}>
                                    {props.isLoadingVehicle ? (
                                        <UiCircularProgress />
                                    ) : (
                                        <VehicleSchema
                                            schema={
                                                (selectedVehicle.id
                                                    ? selectedVehicle.distribution
                                                    : props.distribution) || '2-4'
                                            }
                                            vehicleId={selectedVehicle.id}
                                            emptySchema={false}
                                        />
                                    )}
                                </Box>
                            </Widget>
                        </Grid>

                        <Grid item xs={8} md={9} lg={10}>
                            {customerSettings.mdd_bes && selectedVehicle.id ? <SeverityRankGraphs /> : <></>}
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </SeverityRankViewContent>
    );
};

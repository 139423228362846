import React, { useCallback } from 'react';
import { SelectFilterContent, SetAlertsFilterWidgetContainer } from './SetAlertsFilterWidget.style';
import { SetAlertsFilterWidgetProps } from './SetAlertsFilterWidget.type';
import { Collapse, Grid, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LeftCover, SetAlertsStepBadge } from 'pages/SetAlerts/SetAlerts.style';
import AlertsAvailableFilters from '../AlertsAvailableFilters/AlertsAvailableFilters';
import AlertsFilterVehiclesBy from '../AlertsFilterVehiclesBy/AlertsFilterVehiclesBy';
import { CollapseContent } from 'components/Ui/Components/UiWidget/UiWidget.style';
import { DefaultPressureLevel, PressureLevelsSelector, SettingsStep, SettingSteps } from '../AlertSettings/atom';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import CheckIcon from '@mui/icons-material/Check';
import UiButton from 'components/Ui/Components/UiButton';
import { AppliedFilters } from '../AlertsFilterVehiclesBy/atom';
import { TemperatureSliderValue } from '../AlertTemperatureSlider/atom';
import { SelectedAvailableFilterIds } from '../AlertsAvailableFilters/atom';
import { AlertVehicleFilters } from '../AlertsFilterVehiclesBy/atom';
import { AffectedVehiclesCount } from '../AlertsAffectedVehicles/atom';
import { AlertLevelEnum } from 'models/Alerts.type';

const SetAlertsFilterWidgetContent: React.FC<SetAlertsFilterWidgetProps> = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const [settingsStep, setSettingsStep] = useRecoilState(SettingsStep);
    const theme = useTheme();
    const setInitValuesLow3 = useSetRecoilState(PressureLevelsSelector(-3));
    const setInitValuesLow2 = useSetRecoilState(PressureLevelsSelector(-2));
    const setInitValuesLow1 = useSetRecoilState(PressureLevelsSelector(-1));
    const setInitValuesHigh1 = useSetRecoilState(PressureLevelsSelector(1));
    const setInitValuesHigh2 = useSetRecoilState(PressureLevelsSelector(2));
    const setInitValuesHigh3 = useSetRecoilState(PressureLevelsSelector(3));
    const setInitValuesHot1 = useSetRecoilState(PressureLevelsSelector(11));
    const setInitValuesHot2 = useSetRecoilState(PressureLevelsSelector(12));
    const setInitValuesHot3 = useSetRecoilState(PressureLevelsSelector(13));
    const setInitValuesOptimal = useSetRecoilState(PressureLevelsSelector(0));
    const resetTemperatureSliderValue = useResetRecoilState(TemperatureSliderValue);
    const resetFilters = useResetRecoilState(AppliedFilters);
    const resetAvailabledFilterIds = useResetRecoilState(SelectedAvailableFilterIds);
    const resetAllFilters = useResetRecoilState(AlertVehicleFilters);
    const resetAffectedVehicles = useResetRecoilState(AffectedVehiclesCount);
    const pressureDefaultValues = useRecoilValue(DefaultPressureLevel);

    const adjustPressureValue = useCallback((current, value) => {
        return {
            ...current,
            value,
            action: AlertLevelEnum.ADJUST_VALUE
        };
    }, []);

    const initPressureValue = useCallback((current, value) => {
        return {
            ...current,
            value,
            action: AlertLevelEnum.INIT_VALUES
        };
    }, []);

    const resetAll = () => {
        setInitValuesOptimal((current) => initPressureValue(current, pressureDefaultValues.optimal));
        setInitValuesLow3((current) => adjustPressureValue(current, pressureDefaultValues.low3));
        setInitValuesLow2((current) => adjustPressureValue(current, pressureDefaultValues.low2));
        setInitValuesLow1((current) => adjustPressureValue(current, pressureDefaultValues.low1));
        setInitValuesHigh3((current) => adjustPressureValue(current, pressureDefaultValues.high3));
        setInitValuesHigh2((current) => adjustPressureValue(current, pressureDefaultValues.high2));
        setInitValuesHigh1((current) => adjustPressureValue(current, pressureDefaultValues.high1));
        setInitValuesHot3((current) => initPressureValue(current, pressureDefaultValues.highHot3));
        setInitValuesHot2((current) => initPressureValue(current, pressureDefaultValues.highHot2));
        setInitValuesHot1((current) => initPressureValue(current, pressureDefaultValues.highHot1));
        resetTemperatureSliderValue();
        resetFilters();
        resetAvailabledFilterIds();
        resetAllFilters();
        resetAffectedVehicles();
    };

    return (
        <SetAlertsFilterWidgetContainer data-testid='SetAlertsFilterWidgetContent'>
            <Grid container>
                <Grid
                    item
                    xs={6}
                    md={6}
                    lg={6}
                    display={'flex'}
                    alignItems={'center'}
                    height={'45px'}
                    padding={'5px 10px'}
                >
                    <Typography variant='subtitle1'>
                        <SetAlertsStepBadge>
                            {SettingSteps.SELECT_FILTER < settingsStep ? (
                                <CheckIcon fontSize={'inherit'} />
                            ) : (
                                SettingSteps.SELECT_FILTER + 1
                            )}
                        </SetAlertsStepBadge>{' '}
                        {`${translate('t.select_filter')}: `}
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={6}
                    md={6}
                    lg={6}
                    display={'flex'}
                    alignItems={'center'}
                    height={'45px'}
                    padding={'5px 10px'}
                    direction='row-reverse'
                >
                    <UiButton
                        testid='Reset-Filters-Button'
                        variant='contained'
                        onClick={() => {
                            setSettingsStep(SettingSteps.SELECT_FILTER);
                            resetAll();
                        }}
                    >
                        {translate('t.reset_filters')}
                    </UiButton>
                </Grid>
            </Grid>
            <Collapse in={settingsStep === SettingSteps.SELECT_FILTER} timeout='auto'>
                <CollapseContent $paddingcontent='0 10px 10px 10px' style={{ paddingBottom: '10px' }}>
                    <Grid container>
                        <Grid item xs={12} md={12} lg={12}>
                            <SelectFilterContent container theme={theme}>
                                <LeftCover item xs={12} md={3} lg={3} theme={theme}>
                                    <AlertsAvailableFilters />
                                </LeftCover>
                                <Grid item xs={12} md lg>
                                    <AlertsFilterVehiclesBy />
                                </Grid>
                            </SelectFilterContent>
                        </Grid>
                    </Grid>
                </CollapseContent>
            </Collapse>
        </SetAlertsFilterWidgetContainer>
    );
};

export default SetAlertsFilterWidgetContent;

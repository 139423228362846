import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaultySensorReporterContent } from './FaultySensorReport.view';
import { FaultySensorReporterProps, LeakNotification } from './FaultySensorReport.type';
import SensorFaultyReportApi from 'api/SensorFaultyReport';
import { Success } from '../Popup/Popup';
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil';
import {
    DateTimePicker,
    FaultySensorReporterState,
    ProblemsSensorReporterState,
    Timeline
} from 'states/component/FaultySensorReporter';
import { ModalActionTypesEnum, ModalAtom, ModalDispatcher } from 'states/global/Modal';
import useConverter from '../CustomHooks/Converter/Converter';
import { DatePickerAction } from 'pages/SeverityRank/atom';
import { calculateGranularity, createTimeline } from 'helpers';
import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query';
import { SensorFaultyReportModel } from 'models/SensorFaultyReport.type';
import { QueryFn } from 'components/Ui/UiTable/TableAtom';
import { cacheTimeToMilliseconds } from 'helpers/cache';
import { SensorFaultyReportQueryKeys } from 'models/SensorFaultyReport.type';

const SensorFaultyReport = new SensorFaultyReportApi();

const FaultySensorReporter: React.FC<FaultySensorReporterProps> = (props): JSX.Element => {
    const [faultySensorReporterState, setFaultySensorReporterState] = useRecoilState(FaultySensorReporterState);
    const queryTableFn = useRecoilValue(QueryFn(`FaultySensorReports-QueryFn`));
    const [dateTimePicker, setDateTimePicker] = useRecoilState(DateTimePicker);
    const setTimeline = useSetRecoilState(Timeline);
    const [problemsSensorReporterState, setProblemsSensorReporterState] = useRecoilState(ProblemsSensorReporterState);
    const [modalAtom, setStateModalAtom] = useRecoilState(ModalAtom);
    const modalDispach = ModalDispatcher(modalAtom, setStateModalAtom);
    const { t: translate } = useTranslation();
    const { fromTimezoneToUTC } = useConverter();
    const queryClient = useQueryClient();
    const [leakNotification, setLeakNotification] = useState<LeakNotification>({
        show: false,
        type: '',
        id: 0
    });
    const [hideReportOptions, setHideReportOptions] = useState<boolean>(
        faultySensorReporterState.showOnlyGraph || false
    );
    const [verifyingAllowed, setVerifyingAllowed] = useState<{ status: boolean; data: {} }>({
        status: false,
        data: {}
    });

    const forceCloseModal = () => {
        modalDispach({ type: ModalActionTypesEnum.CLOSE_MODAL });
    };

    const resetAfterActions = () => {
        queryTableFn.refetch instanceof Function && queryTableFn.refetch();
        forceCloseModal();
        setProblemsSensorReporterState({});
    };

    const updateHideReportOptions = (value?: boolean) => {
        setHideReportOptions((current) => (value !== undefined ? value : !current));
    };

    const verifyReport = (): void => {
        verifyReportMutate({
            id: faultySensorReporterState.reportDetailId ?? NaN,
            data: {
                ...verifyingAllowed.data,
                dateFrom: dateTimePicker.current.dateFrom,
                dateTo: dateTimePicker.current.dateTo
            }
        });
    };

    const reopenReport = (): void => {
        reopenReportMutate(faultySensorReporterState.reportDetailId ?? NaN);
    };

    const createReport = async (): Promise<void> => {
        createReportMutate({
            sensorId: faultySensorReporterState.sensorId,
            problem: Object.keys(problemsSensorReporterState),
            disableTemperatureStatus: problemsSensorReporterState['faulty_temperature'] || false,
            disablePressureStatus: problemsSensorReporterState['faulty_pressure'] || false,
            dateFrom: fromTimezoneToUTC(dateTimePicker.current.dateFrom.valueOf()),
            dateTo: fromTimezoneToUTC(dateTimePicker.current.dateTo.valueOf())
        });
    };

    const { mutate: verifyReportMutate, isLoading: verifyReportLoading } = useMutation(
        SensorFaultyReport.verifyReport,
        {
            onSuccess: () => {
                Success({ text: translate('t.sensor_report_verified') });
                faultySensorReporterState.onVerified instanceof Function && faultySensorReporterState.onVerified();
                resetAfterActions();
            }
        }
    );

    const { refetch: refetchAfterReopen } = useQuery<SensorFaultyReportModel>(
        [SensorFaultyReportQueryKeys.getById, faultySensorReporterState.reportDetailId ?? 1],
        () => SensorFaultyReport.getById({ id: faultySensorReporterState.reportDetailId ?? 1 }),
        {
            enabled: false,
            staleTime: cacheTimeToMilliseconds(10, 'minutes'),
            cacheTime: cacheTimeToMilliseconds(10, 'minutes'),
            onSuccess: (data) => {
                setFaultySensorReporterState({
                    sensorId: data.sensor.id,
                    vehicleId: data.sensor?.wheel?.vehicle.id || 0,
                    sensorName: data.sensor?.serialNumberHex,
                    internalOnVehicle: true,
                    vehicleName: data.sensor?.wheel?.vehicle?.name,
                    isExternal: data.sensor?.sensorType?.id === 2,
                    reportDetailId: data.id,
                    reportDateFrom: data.dateFrom,
                    reportDateTo: data.dateTo,
                    showOnlyGraph: true,
                    notValidate: !data.verifiedAt,
                    displayAllHubs: true,
                    onVerified: false
                });
            }
        }
    );

    const { mutate: reopenReportMutate, isLoading: reopenReportLoading } = useMutation(
        SensorFaultyReport.reopenReport,
        {
            onSuccess: () => {
                Success({ text: translate('t.sensor_report_verified') });
                faultySensorReporterState.onVerified instanceof Function && faultySensorReporterState.onVerified();
                refetchAfterReopen();
            }
        }
    );

    const { mutate: createReportMutate, isLoading: createReportLoading } = useMutation(
        SensorFaultyReport.createReport,
        {
            onSuccess: () => {
                Success({ text: translate('t.sensor_reported') });
                resetAfterActions();

                if (props.afterAction) {
                    props.afterAction();
                }

                faultySensorReporterState.afterAction && faultySensorReporterState.afterAction();
            }
        }
    );

    const resetZoom = () => {
        setDateTimePicker({
            current: {
                dateFrom: dateTimePicker.original.dateFrom,
                dateTo: dateTimePicker.original.dateTo
            },
            original: {
                dateFrom: dateTimePicker.original.dateFrom,
                dateTo: dateTimePicker.original.dateTo
            },
            action: DatePickerAction.APPLY
        });
        zoomAction('zoomOut');
    };

    const zoomAction = (typeAction?: 'zoomIn' | 'zoomOut') => {
        let dateFrom = dateTimePicker.current.dateFrom;
        let dateTo = dateTimePicker.current.dateTo;

        if (typeAction === 'zoomOut') {
            dateFrom = dateTimePicker.original.dateFrom;
            dateTo = dateTimePicker.original.dateTo;
        }

        const granularity = calculateGranularity(dateFrom, dateTo);
        setTimeline({
            timeline: createTimeline(granularity, dateFrom, dateTo),
            granularity
        });
    };

    const zoomInAction = () => {
        zoomAction('zoomIn');
    };

    useEffect(() => {
        if (faultySensorReporterState.sensorId) {
            queryClient.invalidateQueries(['getAlertFaultySensorReport']);
            faultySensorReporterState.allowReportLeakAlert &&
                queryClient.invalidateQueries(['faultySensorReportNotificationCodebook']);
        }
    }, [faultySensorReporterState]);

    return (
        <FaultySensorReporterContent
            data-testid={'FaultySensorReporter-testid'}
            noSelectedIssues={Object.keys(problemsSensorReporterState).length === 0}
            isProcessing={createReportLoading || reopenReportLoading || verifyReportLoading}
            hideReportOptions={hideReportOptions}
            verifyingAllowed={verifyingAllowed}
            leakNotification={leakNotification}
            createReport={createReport}
            updateHideReportOptions={updateHideReportOptions}
            setVerifyingAllowed={setVerifyingAllowed}
            verifyReport={verifyReport}
            reopenReport={reopenReport}
            setLeakNotification={setLeakNotification}
            resetZoom={resetZoom}
            forceCloseModal={forceCloseModal}
            zoomInAction={zoomInAction}
            {...props}
        />
    );
};

export default FaultySensorReporter;

import React, { useState } from 'react';
import { UiDatePickerFilterProps } from './UiDatePickerFilter.type';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { UserInfo } from 'states/global/User';
import { TextField } from '@mui/material';
import { MaxYear, MinYear } from 'variables';
import useTableFilter from 'components/CustomHooks/TableFilterState/TableFilterState';
import UiDatePicker from 'components/Ui/Components/UiDatePicker/UiDatePicker';

const UiDatePickerFilter: React.FC<UiDatePickerFilterProps> = ({
    state,
    setFilter,
    testId,
    keyFilter,
    label,
    applyFiltersFlag,
    typeFilter = 'date'
}): JSX.Element => {
    const { dateTimeFormat, fromUserTimezonetoUTC } = useConverter();
    const { t: translate } = useTranslation();
    const userInfo = useRecoilValue(UserInfo);
    const [error, setError] = useState<string | null>(null);
    const { cellValue, setFilterValue } = useTableFilter({
        filterContent: {
            name: label,
            value: state.filters.filter((filter) => filter.id === keyFilter)[0]?.value.value,
            processedValue:
                state.filters.filter((filter) => filter.id === keyFilter)[0]?.value.processedValue ?? undefined,
            humanValue: state.filters
                .filter((filter) => filter.id === keyFilter)[0]
                ?.value?.value?.toFormat(dateTimeFormat(typeFilter, false))
        },
        setFilter: setFilter,
        applyFiltersFlag: applyFiltersFlag,
        filterId: keyFilter
    });

    const validateDate = (date: DateTime | null): string | null => {
        if (!date?.isValid || date?.year < MinYear || date?.year > MaxYear) {
            return translate('t.the_date_format_is_invalid');
        }
        return null;
    };

    const handleDateChange = (newDate: DateTime | null): void => {
        const validationError = validateDate(newDate);
        if (validationError) {
            setError(validationError);
        } else {
            let processedDate: string | undefined = undefined;
            if (newDate && typeFilter && typeFilter === 'dateTime') {
                processedDate = fromUserTimezonetoUTC({
                    date: newDate,
                    format: 'dateTime',
                    customFormat: 'yyyy-MM-dd HH:mm:ss'
                });
            }
            setError(null);
            setFilterValue({
                name: label,
                humanValue: newDate ? newDate.toFormat(dateTimeFormat(typeFilter, false)) : undefined,
                value: newDate || undefined,
                processedValue: processedDate
            });
        }
    };

    if (typeFilter === 'dateTime') {
        return (
            <UiDatePicker
                label={label}
                value={cellValue?.value || null}
                onDateChange={handleDateChange}
                testId={`UiDateTimePickerFilter-${testId}`}
                customMargin='4px 0px 9px 0px'
                fullWidth
                hideErrorMessage
                allowEmpty
            />
        );
    }

    return (
        <LocalizationProvider dateAdapter={AdapterLuxon} data-testid={`UiDatePickerFilter-Localization-${testId}`}>
            <DatePicker
                data-testid={`UiDatePickerFilter-${testId}`}
                format={dateTimeFormat('date', false)}
                label={label}
                value={cellValue?.value || null}
                onChange={handleDateChange}
                slots={{ textField: TextField }}
                slotProps={{
                    textField: (params) => ({
                        'data-testid': `UiDatePickerFilter-${testId}-textField`,
                        margin: 'normal',
                        id: `UiDatePickerFilter-updated-from-${testId}`,
                        size: 'small',
                        variant: 'outlined',
                        sx: { marginTop: '5px' },
                        error: !!error,
                        helperText: error ? translate('t.the_date_format_is_invalid') : '',
                        InputLabelProps: { shrink: true },
                        inputProps: {
                            ...params.inputProps,
                            placeholder: userInfo.user?.userSetting.dateFormat?.toLocaleUpperCase() || 'YYYY-MM-DD',
                            style: { padding: '10.5px' }
                        }
                    })
                }}
            />
        </LocalizationProvider>
    );
};

export default UiDatePickerFilter;

import { AvatarModel } from '../components/User/AccountSettingsForm/AccountSettingsForm.type';
import PortalApi from 'helpers/api/PortalApiClient';
import { getParams } from 'helpers';
import {
    AccountModelResponse,
    UserModel,
    UserAccount,
    UserAdd,
    UserAlerts,
    UserAlertsData,
    UserAvatar,
    UserData,
    UserGeneralSettings,
    UserSetAccount,
    UserTracking,
    UserUnits,
    UserWithPaginator,
    UserDataResponse
} from '../models/User.type';
import { NotificationSettingsPatch, NotificationsSettings } from '../models/Notification.type';
import { UserDataPost } from 'components/User/ChangePasswordForm/ChangePasswordForm.type';

export default class User extends PortalApi<UserModel> {
    route = 'user';
    showError404 = false;

    getNotification = async (userId: number): Promise<NotificationsSettings[]> => {
        return await this.get({ extendUrl: `${userId}/notification`, apiProject: undefined, version: 'v2' });
    };

    patchAccount = async (data: UserSetAccount): Promise<UserData> => {
        return await this.patch<UserData, UserAccount>({
            extendUrl: `${data.userId.toString()}`,

            apiProject: undefined,
            data: data.updateData
        });
    };

    patchNotification = async (
        userId: number,
        updateData: { notifications: NotificationSettingsPatch[] }
    ): Promise<NotificationsSettings[]> => {
        return await this.patch<NotificationsSettings[], { notifications: NotificationSettingsPatch[] }>({
            extendUrl: `${userId}/notification`,
            version: 'v2',
            apiProject: undefined,
            data: updateData
        });
    };

    patchSetting = async (
        userId: number,
        updateData: UserAlerts | UserTracking | UserUnits | UserGeneralSettings | UserAccount
    ): Promise<UserAlertsData> => {
        return await this.patch<
            UserAlertsData,
            UserAlerts | UserTracking | UserUnits | UserGeneralSettings | UserAccount
        >({ extendUrl: `${userId}/setting`, apiProject: undefined, data: updateData });
    };

    getAvatar = async (userId: number): Promise<string> => {
        return await this.getFile({ extendUrl: `${userId}/avatar`, apiProject: undefined });
    };

    patchAvatar = async (data: UserAvatar): Promise<AvatarModel['avatar']> => {
        return await this.patch<AvatarModel['avatar'], { avatar: string | ArrayBuffer | undefined }>({
            extendUrl: `${data.userId}/avatar`,

            apiProject: undefined,
            data: { avatar: data.image }
        });
    };

    changePassword = async (userId, ChangePasswordFormData): Promise<UserDataPost> => {
        return await this.post({
            extendUrl: `${userId}/change-password`,

            apiProject: undefined,
            data: ChangePasswordFormData
        });
    };

    getUserTable = async (
        props,
        additionalProps: { customerRegionId?: number; customer?: number }
    ): Promise<UserWithPaginator> => {
        const propsParams = getParams(props);

        if (propsParams?.enabled) {
            propsParams.type = propsParams.enabled;
            delete propsParams.enabled;
        } else {
            propsParams.type = 'all';
        }

        if (additionalProps?.customer) {
            propsParams.customer = additionalProps.customer;
        }

        return await this.getTable({ version: 'v2', apiProject: undefined, criteria: propsParams });
    };

    getUserByUserName = async (userName: string): Promise<AccountModelResponse> => {
        return await this.get({ extendUrl: `username/${userName}`, apiProject: undefined });
    };

    removeUser = async (id: number): Promise<void> => {
        await this.delete({ apiProject: undefined, id: id });
    };

    addUser = async (data: UserAdd): Promise<UserDataPost> => {
        return await this.post({ apiProject: undefined, data: data });
    };

    getUserById = async (userId: number): Promise<AccountModelResponse> => {
        return await this.get({ extendUrl: `${userId}`, apiProject: undefined });
    };

    getExport = async (props, additionalProps: { customerRegionId?: number; customer?: number }): Promise<string> => {
        const propsParams = getParams(props);
        if (propsParams?.enabled) {
            propsParams.type = propsParams.enabled;
            delete propsParams.enabled;
        } else {
            propsParams.type = 'all';
        }

        if (additionalProps?.customer) {
            propsParams.customer = additionalProps.customer;
        }
        return await this.getByCriteria({ extendUrl: 'export', apiProject: undefined, criteria: propsParams });
    };

    getCustomerAccess = async (
        props,
        additionalProps: { customerRegionId?: number; customer?: number }
    ): Promise<UserWithPaginator> => {
        const propsParams = getParams(props);

        if (propsParams?.enabled) {
            propsParams.type = propsParams.enabled;
            delete propsParams.enabled;
        } else {
            propsParams.type = 'all';
        }

        if (additionalProps?.customer) {
            propsParams.customer = additionalProps.customer;
        }
        return await this.getByCriteria({ extendUrl: 'customer-access', apiProject: undefined, criteria: propsParams });
    };

    getUserByEmail = async (userEmail: string): Promise<AccountModelResponse> => {
        return await this.get({ extendUrl: `email/${userEmail}`, apiProject: undefined });
    };

    usernameRecovery = async (email: string): Promise<UserDataResponse> => {
        return await this.post({ extendUrl: 'email', apiProject: 'public', data: { email: email } });
    };
}

import React, { useState } from 'react';
import * as Yup from 'yup';
import { Form, Formik, FormikProps } from 'formik';
import { AddHubContainer, CustomTextField, GridContainer, GridInput, RefButton } from './AddHub.style';
import { useTranslation } from 'react-i18next';
import DropDown from '../../Ui/Components/UiDropDown';
import UiLoadingPage from 'components/Ui/Components/UiLoadingPage/UiLoadingPage';
import { AddHubViewProps, InitialValues } from './AddHub.type';
import HubHelper from 'assets/images/hub_label.png';
import { Box } from '@mui/material';

export const AddHubContent: React.FC<AddHubViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const [validate, setValidate] = useState<boolean>(true);

    const deviceMatch = (id: number, macAddress: string) => {
        setValidate(true);

        if (props.deviceValueRaw.device.find((element) => element.id == id && element.uuid == macAddress)) {
            setValidate(false);
            return true;
        } else return false;
    };

    const formSchema = Yup.object().shape({
        id: Yup.number().required(translate('p.this_field_is_required')),
        macAddress: Yup.string()
            .trim()
            .required(translate('p.this_field_is_required'))
            .test('test-validate-data', 'MAC address does not match the device with serial number', (value, object) => {
                return deviceMatch(object.parent?.id, object.parent?.macAddress);
            })
    });

    return (
        <AddHubContainer data-testid='AddHubContent'>
            <Formik
                initialValues={props.initValues}
                validationSchema={formSchema}
                enableReinitialize={true}
                onSubmit={(values: InitialValues) => {
                    props.handleSubmit(values);
                }}
            >
                {(propsFormik: FormikProps<InitialValues>) => {
                    const { errors, setFieldValue, handleChange, values } = propsFormik;

                    return (
                        <Form id='hub-administration-form'>
                            <GridContainer container>
                                <GridInput item xs={12} sm={12}>
                                    {props.devicesLoading ? (
                                        <UiLoadingPage size='30px' />
                                    ) : (
                                        <DropDown
                                            options={props.devices}
                                            inputName='id'
                                            label={translate('t.serial_number')}
                                            value={values?.id ? values.id : ' '}
                                            error={errors?.id}
                                            disabled={false}
                                            getValue={(e) => {
                                                setFieldValue('id', e ?? null);
                                            }}
                                            defaultValue={values.id ?? null}
                                        />
                                    )}
                                </GridInput>

                                <GridInput item xs={12} sm={12}>
                                    <CustomTextField
                                        data-testid='MacAddress-TextField'
                                        id='macAddress'
                                        name='macAddress'
                                        error={!!errors?.macAddress}
                                        label={`${translate('t.mac_address')}/WiFi ${translate('t.mac_address')}`}
                                        value={values.macAddress}
                                        variant='outlined'
                                        size='small'
                                        fullWidth
                                        type='text'
                                        helperText={errors.macAddress}
                                        onChange={handleChange}
                                        inputProps={{
                                            form: {
                                                autocomplete: 'off'
                                            }
                                        }}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                    />
                                </GridInput>
                            </GridContainer>
                            <Box textAlign={'center'}>
                                <img
                                    alt='mac-address-helper'
                                    src={HubHelper}
                                    data-testid='macAddressHelper'
                                    id='mac-address-helper'
                                />
                            </Box>
                            <RefButton type='submit' ref={props.refAddHub} disabled={validate} />
                        </Form>
                    );
                }}
            </Formik>
        </AddHubContainer>
    );
};

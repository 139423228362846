import React, { useEffect, useState } from 'react';
import { AccountSettingsFormContent } from './AccountSettingsForm.view';
import { AccountSettingsFormProps, InicialvaluesForm, UserData } from './AccountSettingsForm.type';
import { useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { User as UserState } from 'states/global/User';
import Account from 'api/Account';
import User from 'api/User';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Errors, Success } from 'components/Popup/Popup';
import UiLoadingPage from 'components/Ui/Components/UiLoadingPage/UiLoadingPage';
import { Wrapper } from 'helpers/wrapper';
import { AccountModelResponse } from 'models/User.type';
import { cacheTimeToMilliseconds } from 'helpers/cache';
import { UserQueryKeys } from 'models/User.type';
import { AccountQueryKeys } from 'models/Account.type';

const AccountApi = new Account();
const UserApi = new User();

const AccountSettingsForm: React.FC<AccountSettingsFormProps> = ({
    userData,
    isCurrentUser,
    userId,
    setReloadPage
}): JSX.Element => {
    const { t: translate } = useTranslation();
    const [viewLoading, setViewLoading] = useState<boolean>(true);
    const setUserState = useSetRecoilState(UserState);
    const [selectedFile, setSelectedFile] = React.useState<string | ArrayBuffer | null>(null);
    const [previewImg, setPreviewImg] = React.useState<string>('');
    const [sendform, setSendform] = React.useState<boolean>(true);
    const [inicialvaluesForm, setInicialvaluesForm] = useState<InicialvaluesForm>({
        username: '',
        email: '',
        role: '',
        phoneNumber: '',
        firstname: '',
        lastname: '',
        avatar: ''
    });
    const queryClient = useQueryClient();

    const { isLoading: avatarLoading, data: avatarData } = useQuery<string, Error, string>(
        [!userId ? AccountQueryKeys.getAvatar : UserQueryKeys.getAvatar, [userId]],
        () => (!userId ? AccountApi.getAvatar() : UserApi.getAvatar(userId)),
        {
            refetchOnWindowFocus: false,
            staleTime: cacheTimeToMilliseconds(5, 'minutes'),
            cacheTime: cacheTimeToMilliseconds(5, 'minutes')
        }
    );

    const getUserByParam = async (
        queryFunction: (param: string) => Promise<AccountModelResponse>,
        param: string,
        currentParam?: string | null
    ): Promise<boolean> => {
        if (param && currentParam?.toLowerCase() !== param?.toLowerCase()) {
            const usersDataXHR: AccountModelResponse = await queryFunction(param);

            return !usersDataXHR?.user;
        }
        return true;
    };

    const userNameAvailable = async (userName: string, currentUserName?: string | null): Promise<boolean> =>
        await getUserByParam(UserApi.getUserByUserName, userName, currentUserName);

    const userEmailAvailable = async (userEmail: string, currentUserEmail?: string | null): Promise<boolean> =>
        await getUserByParam(UserApi.getUserByEmail, userEmail, currentUserEmail);

    const handleCapture = (e: React.ChangeEvent): void => {
        const reader = new FileReader();
        const target = e.target as HTMLInputElement;
        reader.onloadend = () => setSelectedFile(reader.result);
        target.files && reader.readAsDataURL(target.files[0]);
        target.files && setPreviewImg(URL.createObjectURL(target.files[0]));
        if (target.files) {
            const fileSizeKB: number = parseInt((target.files[0].size / 1024).toFixed(1));
            if (fileSizeKB > 2048) {
                setSendform(false);
                Errors({ text: translate('t.the_maximum_image_size_is_2mb') });
            } else {
                setSendform(true);
            }
        }
    };

    const handleSubmit = async (
        valuesForm: InicialvaluesForm,
        setSubmitting: (isSubmitting: boolean) => void
    ): Promise<void> => {
        try {
            if (!valuesForm.avatar) {
                delete valuesForm.avatar;
            }

            const GeneralPatch = isCurrentUser
                ? AccountApi.patchAccount(valuesForm)
                : UserApi.patchAccount({ userId: userId as number, updateData: valuesForm });

            const userData: UserData = await GeneralPatch;

            if (userData.user) {
                const GeneralPatchAvatar = isCurrentUser
                    ? AccountApi.patAvatar(valuesForm.avatar)
                    : UserApi.patchAvatar({ userId: userId as number, image: valuesForm.avatar });

                const avatar = await GeneralPatchAvatar;
                if (!avatar) {
                    throw avatar;
                }

                if (isCurrentUser) {
                    !selectedFile && setUserState(userData.user);
                }

                Success({ text: translate('t.account_settings_successful_modified') });
                queryClient.removeQueries([AccountQueryKeys.getAvatar]);
                queryClient.removeQueries([UserQueryKeys.getAvatar]);

                setReloadPage(true);
            } else {
                Errors({ text: translate('t.error_modifying_account_settings') });
            }

            setSubmitting(false);
        } catch (error) {
            setInicialvaluesForm({
                ...inicialvaluesForm,
                avatar: `data:image/jpeg;charset=utf-8;base64,${avatarData}`
            });
            setSubmitting(false);
        }
    };

    useEffect(() => {
        if (userData && !avatarLoading) {
            setInicialvaluesForm({
                username: userData.username,
                email: userData.email || '',
                role: userData.role.id,
                phoneNumber: userData.phoneNumber || '',
                firstname: userData.firstname,
                lastname: userData.lastname,
                avatar: selectedFile ?? `data:image/jpeg;charset=utf-8;base64,${avatarData}`
            });
            setViewLoading(false);
        }
    }, [userData, avatarLoading]);

    return !viewLoading ? (
        <AccountSettingsFormContent
            data-testid={'AccountSettingsForm-testid'}
            inicialvaluesFormik={inicialvaluesForm}
            userNameAvailable={userNameAvailable}
            userEmailAvailable={userEmailAvailable}
            handleSubmit={handleSubmit}
            handleCapture={handleCapture}
            selectedFile={selectedFile}
            previewImg={previewImg}
            setPreviewImg={setPreviewImg}
            userData={userData ?? null}
            isCurrentUser={isCurrentUser}
            sendform={sendform}
        />
    ) : (
        <UiLoadingPage size='30px' />
    );
};

export default Wrapper(AccountSettingsForm);

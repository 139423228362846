import React from 'react';
import { Form, Formik, FormikProps } from 'formik';
import { object as YupObject, string as YupString, number, string } from 'yup';
import Grid from '@mui/material/Grid';
import { TeamShiftTimeFormProps, InitialValues } from './TeamShiftTimeActions.type';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import UiTimePicker from 'components/Ui/Components/UiTimePicker/UiTimePicker';
import { toCamelCase_H } from 'helpers';
import { Autocomplete } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { TeamShiftTimeActionsState } from './TeamShiftTimeActions.atom';
import { CRUD } from 'variables';
import UiLoadingPage from 'components/Ui/Components/UiLoadingPage/UiLoadingPage';
import { debounce } from 'lodash';
import { TeamShiftNameModel } from 'models/TeamShiftName.type';

export const TeamShiftTimeForm: React.FC<TeamShiftTimeFormProps> = ({
    initialValues,
    handleSubmit,
    teamList
}): JSX.Element => {
    const { t: translate } = useTranslation();
    const teamShiftTimeActionsState = useRecoilValue(TeamShiftTimeActionsState);

    const schemaValidateForm = YupObject({
        timeFrom: YupString()
            .trim()
            .required(translate('p.this_field_is_required'))
            .test('is-luxon-time-from', '', (value) => DateTime.fromISO(value, { zone: 'utc' }).isValid),
        timeTo: YupString()
            .trim()
            .required(translate('p.this_field_is_required'))
            .test('is-luxon-time-to', '', (value) => DateTime.fromISO(value, { zone: 'utc' }).isValid),
        teamShiftName: YupObject()
            .shape({
                id: number().required('p.this_field_is_required'),
                name: string().required('p.this_field_is_required')
            })
            .required('p.this_field_is_required')
    });

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values: InitialValues) => {
                handleSubmit(values);
            }}
            enableReinitialize
            validationSchema={schemaValidateForm}
        >
            {(propsFormik: FormikProps<InitialValues>) => {
                const { errors, values, setFieldValue } = propsFormik;

                const setActionTimeFrom = debounce((newDate: DateTime | null) => {
                    setFieldValue('timeFrom', newDate);
                }, 500);

                const setActionTimeTo = debounce((newDate: DateTime | null) => {
                    setFieldValue('timeTo', newDate);
                }, 500);

                return (
                    <Form id='teamShiftTime-form'>
                        <Grid container spacing={2} justifyContent='flex-start' sx={{ mt: 1 }}>
                            <Grid item lg={6} md={6} sm={8} xs={12}>
                                <UiTimePicker
                                    label={toCamelCase_H(translate('t.time_from'))}
                                    value={values.timeFrom}
                                    onDateChange={setActionTimeFrom}
                                    testId='TeamShiftTime-uiTimePicker-timeFrom'
                                    displaySeconds
                                />
                            </Grid>
                            <Grid item lg={6} md={6} sm={8} xs={12}>
                                <UiTimePicker
                                    label={toCamelCase_H(translate('t.time_to'))}
                                    value={values.timeTo}
                                    onDateChange={setActionTimeTo}
                                    testId='TeamShiftTime-uiTimePicker-timeTo'
                                    displaySeconds
                                />
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                {teamShiftTimeActionsState.action === CRUD.EDIT && !values.teamShiftName ? (
                                    <UiLoadingPage
                                        size='25px'
                                        testid='TeamShiftTime-formAutocomplete-teamShiftName-loading'
                                    />
                                ) : (
                                    <Autocomplete
                                        options={teamList}
                                        getOptionLabel={(team: TeamShiftNameModel) => team.name}
                                        onChange={(e, team) => {
                                            setFieldValue('teamShiftName', team ? team : null);
                                        }}
                                        defaultValue={
                                            teamList.length
                                                ? teamList.find(
                                                      (team: TeamShiftNameModel) =>
                                                          team.id === values.teamShiftName?.id || ''
                                                  )
                                                : undefined
                                        }
                                        size='small'
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={translate('t.team')}
                                                variant='outlined'
                                                name='teamShiftName'
                                                value={values.teamShiftName}
                                                size='small'
                                                type='text'
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                helperText={
                                                    errors.teamShiftName && translate('p.this_field_is_required')
                                                }
                                                error={!!errors.teamShiftName}
                                                data-testid='teamShift-Autocomplete'
                                            />
                                        )}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </Form>
                );
            }}
        </Formik>
    );
};
